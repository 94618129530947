import React from 'react';
import SCSS from './Keys.module.scss';

type Props = {
	openFloorplan: (
		room: string,
		plan: string,
		building: string,
		level: string,
		location: string
	) => void;
	highlight: (r: string, p: string, b: string) => string;
};

const Level5 = (props: Props) => {
	const {openFloorplan, highlight} = props;
	return (
		<div className={SCSS.Keyplate}>
			<svg viewBox='0 0 650 400'>
				<g id='POOL' className={SCSS.Lineworks}>
					<g>
						<linearGradient
							id='SVGID_1_'
							gradientUnits='userSpaceOnUse'
							x1='328.6905'
							y1='359.7091'
							x2='328.6905'
							y2='255.4724'>
							<stop offset='0' style={{stopColor: '#FFFFFF'}} />
							<stop offset='1' style={{stopColor: '#999999'}} />
						</linearGradient>
						<path
							className={SCSS.Pool}
							d='M408.06,273.28h-27.78v-17.8H234.6v104.24c45.02,0.02,86.12-7.06,122.3-17.35v-86.88h23.39v79.59
			c15.25-5.19,29.47-10.86,42.5-16.67L408.06,273.28z'
						/>
						<polygon
							points='226.74,255.47 226.74,247.62 196.58,247.62 196.58,397.62 234.6,397.62 234.6,359.71 234.6,255.47
			234.6,255.47 		'
						/>
						<rect x='356.89' y='255.47' width='23.39' height='100.84' />
						<line x1='234.6' y1='255.47' x2='356.89' y2='255.47' />
						<path d='M356.89,342.36c-36.17,10.29-77.28,17.37-122.3,17.35' />
						<path d='M380.28,273.28h27.78l14.72,45.12c-13.03,5.82-27.25,11.48-42.5,16.67' />
						<rect x='202.23' y='372.22' width='26.72' height='20.84' />
						<rect x='207.48' y='353.13' width='16.22' height='11.93' />
						<rect x='361.91' y='260.88' width='13.36' height='11.77' />
					</g>
				</g>

				<g id='LINEWORKS' className={SCSS.Lineworks}>
					<g>
						<line x1='242.57' y1='140.86' x2='259.26' y2='140.86' />
						<line x1='242.57' y1='142.75' x2='259.26' y2='142.75' />
						<line x1='242.57' y1='144.64' x2='259.26' y2='144.64' />
						<line x1='242.57' y1='146.53' x2='259.26' y2='146.53' />
						<line x1='242.57' y1='148.42' x2='259.26' y2='148.42' />
						<line x1='242.57' y1='150.31' x2='259.26' y2='150.31' />
						<line x1='242.57' y1='152.2' x2='259.26' y2='152.2' />
						<line x1='242.57' y1='154.09' x2='259.26' y2='154.09' />
						<line x1='242.57' y1='155.98' x2='259.26' y2='155.98' />
						<line x1='242.57' y1='157.87' x2='259.26' y2='157.87' />
						<line x1='250.92' y1='140.86' x2='250.92' y2='157.87' />
						<polyline points='534.89,86.9 551.27,86.9 551.27,106.58 		' />
						<line x1='551.21' y1='121.39' x2='534.83' y2='121.39' />
						<line x1='534.89' y1='96.3' x2='551.27' y2='96.3' />
						<line x1='534.89' y1='98.19' x2='551.27' y2='98.19' />
						<line x1='534.89' y1='100.08' x2='551.27' y2='100.08' />
						<line x1='534.89' y1='101.97' x2='551.27' y2='101.97' />
						<line x1='534.89' y1='103.86' x2='551.27' y2='103.86' />
						<line x1='534.89' y1='105.75' x2='551.27' y2='105.75' />
						<line x1='534.89' y1='107.64' x2='551.27' y2='107.64' />
						<line x1='534.89' y1='109.53' x2='551.27' y2='109.53' />
						<line x1='534.89' y1='111.42' x2='551.27' y2='111.42' />
						<line x1='534.89' y1='113.31' x2='551.27' y2='113.31' />
						<line x1='543.08' y1='96.3' x2='543.08' y2='113.31' />
						<polyline points='263.36,102.33 243.51,102.33 243.51,119.18 263.36,119.18 		' />
						<polyline points='243.51,119.18 238.79,119.18 238.79,102.33 243.51,102.33 		' />
						<line x1='242.57' y1='166.91' x2='242.57' y2='119.18' />
						<line x1='242.57' y1='132.73' x2='259.26' y2='132.73' />
						<polyline points='238.79,189.43 229.17,189.43 167.28,201.05 		' />
						<polyline points='167.28,185.4 168.77,185.4 220.47,155.55 238.79,106.71 		' />
						<polyline points='65.07,66.89 99.24,66.89 99.24,83.59 		' />
						<polyline points='98.3,66.89 98.3,56.66 129.64,56.66 129.64,76.34 		' />
						<line x1='112.79' y1='76.34' x2='112.79' y2='56.66' />
						<line x1='110.58' y1='247.07' x2='110.58' y2='264.08' />
						<polygon
							points='635.11,92.52 635.11,203.49 574.87,200.68 574.87,189.43 607.65,189.43 607.65,106.58 574.87,106.58
			574.87,95.33 		'
						/>
						<polygon points='167.28,145.64 183.03,145.64 183.03,177.16 168.77,185.4 167.28,185.4 		' />
						<polyline
							points='167.31,291.95 178.41,291.92 183.78,357.78 105.11,351.88 29.09,357.78 31.43,299.69 42.54,299.69
					'
						/>
						<rect x='26.79' y='128.95' width='15.75' height='68.99' />
						<polygon points='42.54,128.95 42.54,66.89 77.64,66.89 77.64,52.32 19.29,52.32 26.79,128.95 		' />
						<polygon points='188.66,56.66 183.03,145.64 167.28,145.64 167.28,76.34 129.64,76.34 129.64,56.66 		' />
						<polygon points='188.66,270.23 182.69,198.16 167.28,201.05 167.28,270.23 		' />
						<polygon points='42.55,197.93 42.54,276.2 19.29,276.2 26.79,197.93 		' />
						<line x1='167.28' y1='202.34' x2='167.28' y2='183.44' />
						<polygon points='301.47,42.56 356.37,45.19 356.37,64.69 301.47,64.69 		' />
						<polyline points='301.47,64.69 301.47,42.56 243.51,39.56 243.51,102.33 263.36,102.33 		' />
						<polygon points='240.48,214.55 304.62,208.93 304.62,189.43 240.48,189.43 		' />
						<polygon points='497.09,41.23 547.17,39.56 547.17,86.9 534.83,86.9 534.89,64.69 497.09,64.69 		' />
						<rect x='304.62' y='189.43' width='37.8' height='19.5' />
						<rect x='424.64' y='189.43' width='44.53' height='19.5' />
						<polygon points='469.17,189.43 551.45,189.43 551.45,214.55 469.17,208.93 		' />
						<rect x='342.42' y='189.43' width='44.47' height='19.5' />
						<rect x='386.84' y='189.43' width='37.8' height='19.5' />
						<polygon points='414.86,43.96 377.9,45.19 377.9,64.69 414.86,64.69 		' />
						<polygon points='452.67,42.71 414.86,43.96 414.86,64.69 452.67,64.69 		' />
						<polygon points='497.09,41.23 452.67,42.71 452.67,64.69 497.09,64.69 		' />
						<line x1='90.87' y1='66.89' x2='90.87' y2='83.59' />
						<line x1='88.98' y1='66.89' x2='88.98' y2='83.59' />
						<line x1='87.09' y1='66.89' x2='87.09' y2='83.59' />
						<line x1='85.2' y1='66.89' x2='85.2' y2='83.59' />
						<line x1='83.31' y1='66.89' x2='83.31' y2='83.59' />
						<line x1='81.42' y1='66.89' x2='81.42' y2='83.59' />
						<line x1='79.53' y1='66.89' x2='79.53' y2='83.59' />
						<line x1='77.64' y1='66.89' x2='77.64' y2='83.59' />
						<line x1='75.75' y1='66.89' x2='75.75' y2='83.59' />
						<line x1='73.86' y1='66.89' x2='73.86' y2='83.59' />
						<line x1='90.87' y1='75.24' x2='73.86' y2='75.24' />
						<line x1='137.25' y1='247.07' x2='137.25' y2='264.08' />
						<line x1='135.36' y1='247.07' x2='135.36' y2='264.08' />
						<line x1='133.47' y1='247.07' x2='133.47' y2='264.08' />
						<line x1='131.58' y1='247.07' x2='131.58' y2='264.08' />
						<line x1='129.69' y1='247.07' x2='129.69' y2='264.08' />
						<line x1='127.8' y1='247.07' x2='127.8' y2='264.08' />
						<line x1='125.91' y1='247.07' x2='125.91' y2='264.08' />
						<line x1='124.02' y1='247.07' x2='124.02' y2='264.08' />
						<line x1='122.13' y1='247.07' x2='122.13' y2='264.08' />
						<line x1='120.24' y1='247.07' x2='120.24' y2='264.08' />
						<line x1='137.25' y1='255.58' x2='120.24' y2='255.58' />
						<rect x='238.79' y='172.58' width='3.78' height='16.85' />
						<line x1='42.54' y1='235.73' x2='23.17' y2='235.73' />
					</g>
				</g>
				<g id='UNITS'>
					<g id='LEVEL-5-LABEL'>
						<text
							transform='matrix(1 0 0 1 472.0444 329.068)'
							className={SCSS.LevelLabel}>
							LEVEL 5
						</text>
					</g>
					<g id='LEVEL-5-BUILDING-LABEL'>
						<text
							transform='matrix(1 0 0 1 80.4454 16.9003)'
							className={SCSS.BuildingLabel}>
							ZARA 1
						</text>
						<text
							transform='matrix(1 0 0 1 386.6978 16.9003)'
							className={SCSS.BuildingLabel}>
							ZARA 2
						</text>
					</g>
					<g id='LEVEL-5-ZARA-2'>
						<g
							className={highlight('501', 'A', 'ZARA 2')}
							onClick={() =>
								openFloorplan('501', 'A', 'ZARA 2', '5', 'location-2-e')
							}
							id='UINIT-501-A'>
							<g id='LWPOLYLINE_553_'>
								<rect
									x='263.36'
									y='64.69'
									className={SCSS.Hot}
									width='38.06'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 271.3051 92.2431)'
									className={SCSS.Number}>
									501
								</text>
								<text
									transform='matrix(1 0 0 1 276.3051 112.2431)'
									className={SCSS.PlanName}>
									A
								</text>
							</g>
						</g>
						<g
							className={highlight('502', 'B2', 'ZARA 2')}
							onClick={() =>
								openFloorplan('502', 'B2', 'ZARA 2', '5', 'location-2-e')
							}
							id='UINIT-502-B2'>
							<g id='LWPOLYLINE_554_'>
								<rect
									x='301.41'
									y='64.69'
									className={SCSS.Hot}
									width='68.99'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 321.9497 92.2431)'
									className={SCSS.Number}>
									502
								</text>
								<text
									transform='matrix(1 0 0 1 324.9497 112.2431)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							className={highlight('503', 'A1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('503', 'A1', 'ZARA 2', '5', 'location-2-e')
							}
							id='UINIT-503-A1'>
							<g id='LWPOLYLINE_536_'>
								<rect
									x='414.86'
									y='64.69'
									className={SCSS.Hot}
									width='37.81'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 421.2723 92.2431)'
									className={SCSS.Number}>
									503
								</text>
								<text
									transform='matrix(1 0 0 1 424.2723 112.2431)'
									className={SCSS.PlanName}>
									A1
								</text>
							</g>
						</g>
						<g
							className={highlight('504', 'A', 'ZARA 2')}
							onClick={() =>
								openFloorplan('504', 'A', 'ZARA 2', '5', 'location-2-e')
							}
							id='UINIT-504-A'>
							<g id='LWPOLYLINE_534_'>
								<rect
									x='497.09'
									y='64.69'
									className={SCSS.Hot}
									width='37.8'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 501.4927 92.2431)'
									className={SCSS.Number}>
									504
								</text>
								<text
									transform='matrix(1 0 0 1 506.4927 112.2431)'
									className={SCSS.PlanName}>
									A
								</text>
							</g>
						</g>
						<g
							className={highlight('505', 'B1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('505', 'B1', 'ZARA 2', '5', 'location-1')
							}
							id='UINIT-505-B1'>
							<g id='LWPOLYLINE_538_'>
								<rect
									x='551.27'
									y='106.58'
									className={SCSS.Hot}
									width='56.38'
									height='82.85'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 567.1437 146.2838)'
									className={SCSS.Number}>
									505
								</text>
								<text
									transform='matrix(1 0 0 1 570.1437 166.2838)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							className={highlight('506', 'A1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('506', 'A1', 'ZARA 2', '5', 'location-2-w')
							}
							id='UINIT-506-A1'>
							<g id='LWPOLYLINE_541_'>
								<rect
									x='386.84'
									y='132.73'
									className={SCSS.Hot}
									width='37.8'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 392.8749 159.0689)'
									className={SCSS.Number}>
									506
								</text>
								<text
									transform='matrix(1 0 0 1 395.8749 179.0689)'
									className={SCSS.PlanName}>
									A1
								</text>
							</g>
						</g>
						<g
							className={highlight('507', 'A1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('507', 'A1', 'ZARA 2', '5', 'location-2-w')
							}
							id='UINIT-507-A1'>
							<g id='LWPOLYLINE_543_'>
								<rect
									x='304.62'
									y='132.73'
									className={SCSS.Hot}
									width='37.8'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 312.136 159.0689)'
									className={SCSS.Number}>
									507
								</text>
								<text
									transform='matrix(1 0 0 1 315.136 179.0689)'
									className={SCSS.PlanName}>
									A1
								</text>
							</g>
						</g>
						<g
							className={highlight('508', 'A2', 'ZARA 2')}
							onClick={() =>
								openFloorplan('508', 'A2', 'ZARA 2', '5', 'location-2-w')
							}
							id='UINIT-508-A2'>
							<g id='LWPOLYLINE_552_'>
								<polygon
									className={SCSS.Hot}
									points='304.62,189.43 242.57,189.43 242.57,166.91 259.26,166.91 259.26,132.73 304.62,132.73 				'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 267.5585 159.0689)'
									className={SCSS.Number}>
									508
								</text>
								<text
									transform='matrix(1 0 0 1 270.5585 179.0689)'
									className={SCSS.PlanName}>
									A2
								</text>
							</g>
						</g>
						<g
							className={highlight('603', 'P1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('603', 'P1', 'ZARA 2', '5', 'location-2-e')
							}
							id='UINIT-603-P1-L1'>
							<g id='LWPOLYLINE_537_'>
								<rect
									x='370.4'
									y='64.69'
									className={SCSS.Hot}
									width='44.46'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 377.0074 80.922)'
									className={SCSS.Number}>
									603
								</text>
								<text
									transform='matrix(1 0 0 1 377.0074 99.922)'
									className={SCSS.PlanName}>
									- L1
								</text>
								<text
									transform='matrix(1 0 0 1 382.8302 118.0444)'
									className={SCSS.PlanName}>
									P1
								</text>
							</g>
						</g>
						<g
							className={highlight('604', 'P1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('604', 'P1', 'ZARA 2', '5', 'location-2-e')
							}
							id='UINIT-604-P1-L1'>
							<g id='LWPOLYLINE_535_'>
								<rect
									x='452.67'
									y='64.69'
									className={SCSS.Hot}
									width='44.41'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 459.2574 80.922)'
									className={SCSS.Number}>
									604
								</text>
								<text
									transform='matrix(1 0 0 1 459.2574 99.922)'
									className={SCSS.PlanName}>
									- L1
								</text>
								<text
									transform='matrix(1 0 0 1 465.0802 118.0444)'
									className={SCSS.PlanName}>
									P1
								</text>
							</g>
						</g>
						<g
							className={highlight('605', 'P2', 'ZARA 2')}
							onClick={() =>
								openFloorplan('605', 'P2', 'ZARA 2', '5', 'location-2-w')
							}
							id='UINIT-605-P2-L1'>
							<g id='LWPOLYLINE_539_'>
								<rect
									x='469.05'
									y='132.73'
									className={SCSS.Hot}
									width='82.22'
									height='56.7'
								/>
							</g>
							<text
								transform='matrix(1 0 0 1 498.7172 156.5204)'
								className={SCSS.Number}>
								605
							</text>
							<text
								transform='matrix(1 0 0 1 480.7172 176.5204)'
								className={SCSS.PlanName}>
								P2 - L1
							</text>
						</g>
						<g
							className={highlight('606', 'P1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('606', 'P1', 'ZARA 2', '5', 'location-2-w')
							}
							id='UINIT-606-P1-L1'>
							<g id='LWPOLYLINE_540_'>
								<rect
									x='424.64'
									y='132.73'
									className={SCSS.Hot}
									width='44.41'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 431.2225 146.069)'
									className={SCSS.Number}>
									606
								</text>
								<text
									transform='matrix(1 0 0 1 431.2225 166.069)'
									className={SCSS.PlanName}>
									- L1
								</text>
								<text
									transform='matrix(1 0 0 1 437.0453 185.1914)'
									className={SCSS.PlanName}>
									P1
								</text>
							</g>
						</g>
						<g
							className={highlight('607', 'P1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('607', 'P1', 'ZARA 2', '5', 'location-2-w')
							}
							id='UINIT-607-P1-L1'>
							<g id='LWPOLYLINE_542_'>
								<rect
									x='342.42'
									y='132.73'
									className={SCSS.Hot}
									width='44.41'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 349.037 146.069)'
									className={SCSS.Number}>
									607
								</text>
								<text
									transform='matrix(1 0 0 1 349.037 166.069)'
									className={SCSS.PlanName}>
									- L1
								</text>
								<text
									transform='matrix(1 0 0 1 354.8597 185.1914)'
									className={SCSS.PlanName}>
									P1
								</text>
							</g>
						</g>
					</g>
					<g id='LEVEL-5-ZARA-1'>
						<g
							className={highlight('501', 'B2', 'ZARA 1')}
							onClick={() =>
								openFloorplan('501', 'B2', 'ZARA 1', '5', 'location-3-e')
							}
							id='UINIT-501-B2'>
							<g id='LWPOLYLINE_551_'>
								<rect
									x='110.58'
									y='76.34'
									className={SCSS.Hot}
									width='56.7'
									height='69.3'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 127.9471 108.3364)'
									className={SCSS.Number}>
									501
								</text>
								<text
									transform='matrix(1 0 0 1 127.9471 128.3364)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							className={highlight('502', 'A1', 'ZARA 1')}
							onClick={() =>
								openFloorplan('502', 'A1', 'ZARA 1', '5', 'location-3-s')
							}
							id='UINIT-502-A1'>
							<g id='LWPOLYLINE_544_'>
								<rect
									x='110.58'
									y='145.64'
									className={SCSS.Hot}
									width='56.7'
									height='37.8'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 127.3065 161.7229)'
									className={SCSS.Number}>
									502
								</text>
								<text
									transform='matrix(1 0 0 1 129.3065 179.7229)'
									className={SCSS.PlanName}>
									A1
								</text>
							</g>
						</g>
						<g
							className={highlight('503', 'A2', 'ZARA 1')}
							onClick={() =>
								openFloorplan('503', 'A2', 'ZARA 1', '5', 'location-3-s')
							}
							id='UINIT-503-A2'>
							<g id='LWPOLYLINE_547_'>
								<polygon
									className={SCSS.Hot}
									points='110.58,202.34 110.58,247.07 145.39,247.07 145.39,264.08 167.28,264.08 167.28,202.34 				'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 127.5785 222.5819)'
									className={SCSS.Number}>
									503
								</text>
								<text
									transform='matrix(1 0 0 1 127.5785 242.5819)'
									className={SCSS.PlanName}>
									A2
								</text>
							</g>
						</g>
						<g
							className={highlight('504', 'C1', 'ZARA 1')}
							onClick={() =>
								openFloorplan('504', 'C1', 'ZARA 1', '5', 'location-4')
							}
							id='UINIT-504-C1'>
							<g id='LWPOLYLINE_548_'>
								<polygon
									className={SCSS.Hot}
									points='38.76,280.15 38.76,297.55 42.54,297.55 42.58,329.29 167.28,329.29 167.28,264.08 99.24,264.08
					99.24,280.15 				'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 90.802 303.1484)'
									className={SCSS.Number}>
									504
								</text>
								<text
									transform='matrix(1 0 0 1 92.802 323.1484)'
									className={SCSS.PlanName}>
									C1
								</text>
							</g>
						</g>
						<g
							className={highlight('505', 'A1', 'ZARA 1')}
							onClick={() =>
								openFloorplan('505', 'A1', 'ZARA 1', '5', 'location-3-n')
							}
							id='UINIT-505-A1'>
							<g id='LWPOLYLINE_545_'>
								<rect
									x='42.54'
									y='197.93'
									className={SCSS.Hot}
									width='56.7'
									height='37.8'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 59.2668 212.9891)'
									className={SCSS.Number}>
									505
								</text>
								<text
									transform='matrix(1 0 0 1 61.2668 230.9891)'
									className={SCSS.PlanName}>
									A1
								</text>
							</g>
						</g>
						<g
							className={highlight('506', 'B2', 'ZARA 1')}
							onClick={() =>
								openFloorplan('506', 'B2', 'ZARA 1', '5', 'location-3-n')
							}
							id='UINIT-506-B2'>
							<g id='LWPOLYLINE_550_'>
								<rect
									x='42.54'
									y='128.95'
									className={SCSS.Hot}
									width='56.7'
									height='68.98'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 57.9075 161.5977)'
									className={SCSS.Number}>
									506
								</text>
								<text
									transform='matrix(1 0 0 1 59.9075 181.5977)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							className={highlight('507', 'A2', 'ZARA 1')}
							onClick={() =>
								openFloorplan('507', 'A2', 'ZARA 1', '5', 'location-3-e')
							}
							id='UINIT-507-A2'>
							<g id='LWPOLYLINE_546_'>
								<polygon
									className={SCSS.Hot}
									points='99.24,128.95 99.24,83.59 65.07,83.59 65.07,66.89 42.54,66.89 42.54,128.95 				'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 57.5388 104.6339)'
									className={SCSS.Number}>
									507
								</text>
								<text
									transform='matrix(1 0 0 1 59.5388 124.6339)'
									className={SCSS.PlanName}>
									A2
								</text>
							</g>
						</g>
						<g
							className={highlight('605', 'P1', 'ZARA 1')}
							onClick={() =>
								openFloorplan('605', 'P1', 'ZARA 1', '5', 'location-3-n')
							}
							id='UINIT-605-P1-L1'>
							<g id='LWPOLYLINE_549_'>
								<rect
									x='42.54'
									y='235.73'
									className={SCSS.Hot}
									width='56.7'
									height='44.41'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 55.5071 248.6036)'
									className={SCSS.Number}>
									605
								</text>
								<text
									transform='matrix(1 0 0 1 61.3298 265.726)'
									className={SCSS.PlanName}>
									P1
								</text>
								<text
									transform='matrix(1 0 0 1 55.5071 277.6036)'
									className={SCSS.PlanName}>
									- L1
								</text>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</div>
	);
};

export default Level5;
