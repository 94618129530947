import React from 'react';
import SCSS from './Keys.module.scss';

const Pool = () => {
	return (
		<g id='POOL' className={SCSS.Lineworks}>
			<g>
				<linearGradient
					id='SVGID_1_'
					gradientUnits='userSpaceOnUse'
					x1='328.6905'
					y1='359.7091'
					x2='328.6905'
					y2='255.4724'>
					<stop offset='0' style={{stopColor: '#FFFFFF'}} />
					<stop offset='1' style={{stopColor: '#999999'}} />
				</linearGradient>

				<path
					className={SCSS.Pool}
					d='M451.06,273.28h-27.78v-17.8H277.6v104.24c45.02,0.02,86.12-7.06,122.3-17.35v-86.88h23.39v79.59
			c15.25-5.19,29.47-10.86,42.5-16.67L451.06,273.28z'
				/>
				<polygon
					points='269.74,255.47 269.74,247.62 239.58,247.62 239.58,397.62 277.6,397.62 277.6,359.71 277.6,255.47
			277.6,255.47 		'
				/>
				<rect x='399.89' y='255.47' width='23.39' height='100.84' />
				<line x1='277.6' y1='255.47' x2='399.89' y2='255.47' />
				<path d='M399.89,342.36c-36.17,10.29-77.28,17.37-122.3,17.35' />
				<path d='M423.28,273.28h27.78l14.72,45.12c-13.03,5.82-27.25,11.48-42.5,16.67' />
				<rect x='245.23' y='372.22' width='26.72' height='20.84' />
				<rect x='250.48' y='353.13' width='16.22' height='11.93' />
				<rect x='404.91' y='260.88' width='13.36' height='11.77' />
				<text
					className={SCSS.PoolText}
					transform='matrix(1 0 0 1 313.4656 311.2874)'>
					POOL
				</text>
			</g>
		</g>
	);
};

export default Pool;
