import React from 'react';
import ViewButton from './ViewButton';
import SCSS from './FloorplanHeader.module.scss';
import {IFloorplan} from '../../../../constants/floorplans';

type Props = {
	plan: IFloorplan;
	gotoView: () => void;
	gotoGallery: () => void;
};

const Header = (props: Props) => {
	const {plan, gotoView, gotoGallery} = props;

	return (
		<div className={SCSS.FloorplanHeader}>
			<div className={SCSS.Left}>
				<div className={SCSS.Title}>{`Plan ${plan.name}`}</div>
				<div className={SCSS.Subtitle}>
					{`${plan.bed} Bedroom`}
					{plan.den && ` + Den`}, {`${plan.bath} Bath`}
				</div>
			</div>
			<div className={SCSS.Middle}>
				<div className={SCSS.Description}>
					<div className={SCSS.Row}>
						<div>Interior</div>
						<div>{plan.interior}</div>
					</div>
					<div className={SCSS.Row}>
						<div>Exterior</div>
						<div>{plan.exterior}</div>
					</div>
					<div className={SCSS.Row}>
						<div className={SCSS.Total}>Total</div>
						<div className={SCSS.Total}>{plan.total}</div>
					</div>
				</div>
			</div>
			<div className={SCSS.Right}>
				<div className={SCSS.ButtonContainer}>
					{plan.gallery && (
						<div className={SCSS.Button} onClick={gotoGallery}>
							<i className={SCSS.IconGallery} />
							Gallery
						</div>
					)}
					{plan.virtual && (
						<div className={SCSS.Button}>
							<i className={SCSS.IconVirtual} />
							Virtual Tour
						</div>
					)}
					<ViewButton gotoView={gotoView} plan={plan} />
				</div>
			</div>
		</div>
	);
};

export default Header;
