import IFloor from './IFloor';

import Floor_2_Image_1 from '../../views/location-3-s/floor-2/images/view_01.jpg';
import Floor_2_Image_2 from '../../views/location-3-s/floor-2/images/view_02.jpg';

import Floor_3_Image_1 from '../../views/location-3-s/floor-3/images/view_01.jpg';
import Floor_3_Image_2 from '../../views/location-3-s/floor-3/images/view_02.jpg';

import Floor_4_Image_1 from '../../views/location-3-s/floor-4/images/view_01.jpg';
import Floor_4_Image_2 from '../../views/location-3-s/floor-4/images/view_02.jpg';

import Floor_5_Image_1 from '../../views/location-3-s/floor-5/images/view_01.jpg';
import Floor_5_Image_2 from '../../views/location-3-s/floor-5/images/view_02.jpg';

import Floor_6_Image_1 from '../../views/location-3-s/floor-6/images/view_01.jpg';
import Floor_6_Image_2 from '../../views/location-3-s/floor-6/images/view_02.jpg';

const floor2: IFloor = {
	location: 'location-3-s',
	width: 1330,
	loop: false,
	direction: 'south',
	view: [
		{
			title: '202',
			order: '02',
			plan: 'A4',
			src: Floor_2_Image_1,
		},
		{
			title: '203',
			order: '03',
			plan: 'A2',
			src: Floor_2_Image_2,
		},
	],
};

const floor3: IFloor = {
	location: 'location-3-s',
	width: 1330,
	loop: false,
	direction: 'south',
	view: [
		{
			title: '302',
			order: '02',
			plan: 'A4',
			src: Floor_3_Image_1,
		},
		{
			title: '303',
			order: '03',
			plan: 'A2',
			src: Floor_3_Image_2,
		},
	],
};

const floor4: IFloor = {
	location: 'location-3-s',
	width: 1330,
	loop: false,
	direction: 'south',
	view: [
		{
			title: '402',
			order: '02',
			plan: 'A4',
			src: Floor_4_Image_1,
		},
		{
			title: '403',
			order: '03',
			plan: 'A2',
			src: Floor_4_Image_2,
		},
	],
};

const floor5: IFloor = {
	location: 'location-3-s',
	width: 1330,
	loop: false,
	direction: 'south',
	view: [
		{
			title: '502',
			order: '02',
			plan: 'A1',
			src: Floor_5_Image_1,
		},
		{
			title: '503',
			order: '03',
			plan: 'A2',
			src: Floor_5_Image_2,
		},
	],
};

const floor6: IFloor = {
	location: 'location-3-s',
	width: 1330,
	loop: false,
	direction: 'south',
	view: [
		{
			title: '602',
			order: '02',
			plan: 'A4',
			src: Floor_6_Image_1,
		},
		{
			title: '603',
			order: '03',
			plan: 'A2',
			src: Floor_6_Image_2,
		},
	],
};

const location3_S = {
	floor2,
	floor3,
	floor4,
	floor5,
	floor6,
};

export default location3_S;
