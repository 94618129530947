import {motion} from 'framer-motion';
import SCSS from './Arrows.module.scss';

interface ArrowType {
	onClick?: (event: React.FormEvent) => void;
}

const ArrowPrev = ({onClick}: ArrowType) => {
	return (
		<motion.div
			whileTap={{scale: 0.9}}
			className={SCSS.PrevArrow}
			onClick={onClick}
		/>
	);
};

export default ArrowPrev;
