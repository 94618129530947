import React from 'react';
import SCSS from './Keys.module.scss';

type Props = {
	openFloorplan: (
		room: string,
		plan: string,
		building: string,
		level: string,
		location: string
	) => void;
	highlight: (r: string, p: string, b: string) => string;
};

const Level4 = (props: Props) => {
	const {openFloorplan, highlight} = props;
	return (
		<div className={SCSS.Keyplate}>
			<svg viewBox='0 0 650 400'>
				<g id='POOL' className={SCSS.Lineworks}>
					<g>
						<linearGradient
							id='SVGID_1_'
							gradientUnits='userSpaceOnUse'
							x1='328.6905'
							y1='359.7091'
							x2='328.6905'
							y2='255.4724'>
							<stop offset='0' style={{stopColor: '#FFFFFF'}} />
							<stop offset='1' style={{stopColor: '#999999'}} />
						</linearGradient>
						<path
							className={SCSS.Pool}
							d='M408.06,273.28h-27.78v-17.8H234.6v104.24c45.02,0.02,86.12-7.06,122.3-17.35v-86.88h23.39v79.59
			c15.25-5.19,29.47-10.86,42.5-16.67L408.06,273.28z'
						/>
						<polygon
							points='226.74,255.47 226.74,247.62 196.58,247.62 196.58,397.62 234.6,397.62 234.6,359.71 234.6,255.47
			234.6,255.47 		'
						/>
						<rect x='356.89' y='255.47' width='23.39' height='100.84' />
						<line x1='234.6' y1='255.47' x2='356.89' y2='255.47' />
						<path d='M356.89,342.36c-36.17,10.29-77.28,17.37-122.3,17.35' />
						<path d='M380.28,273.28h27.78l14.72,45.12c-13.03,5.82-27.25,11.48-42.5,16.67' />
						<rect x='202.23' y='372.22' width='26.72' height='20.84' />
						<rect x='207.48' y='353.13' width='16.22' height='11.93' />
						<rect x='361.91' y='260.88' width='13.36' height='11.77' />
					</g>
				</g>

				<g id='LINEWORKS' className={SCSS.Lineworks}>
					<g>
						<polyline points='64.98,66.8 99.16,66.8 99.16,83.49 		' />
						<polyline points='98.22,66.8 98.22,56.56 129.56,56.56 129.56,76.25 		' />
						<line x1='112.71' y1='76.25' x2='112.71' y2='56.56' />
						<line x1='110.5' y1='246.98' x2='110.5' y2='263.99' />
						<polyline
							points='167.23,306.83 178.33,306.8 177.96,354.36 105.03,351.79 31.97,354.72 31.35,299.59 42.46,299.57
			'
						/>
						<line x1='90.78' y1='66.8' x2='90.78' y2='83.49' />
						<line x1='88.89' y1='66.8' x2='88.89' y2='83.49' />
						<line x1='87' y1='66.8' x2='87' y2='83.49' />
						<line x1='85.11' y1='66.8' x2='85.11' y2='83.49' />
						<line x1='83.22' y1='66.8' x2='83.22' y2='83.49' />
						<line x1='81.33' y1='66.8' x2='81.33' y2='83.49' />
						<line x1='79.44' y1='66.8' x2='79.44' y2='83.49' />
						<line x1='77.55' y1='66.8' x2='77.55' y2='83.49' />
						<line x1='75.66' y1='66.8' x2='75.66' y2='83.49' />
						<line x1='73.77' y1='66.8' x2='73.77' y2='83.49' />
						<line x1='90.78' y1='75.14' x2='73.77' y2='75.14' />
						<line x1='137.17' y1='246.98' x2='137.17' y2='263.99' />
						<line x1='135.28' y1='246.98' x2='135.28' y2='263.99' />
						<line x1='133.39' y1='246.98' x2='133.39' y2='263.99' />
						<line x1='131.5' y1='246.98' x2='131.5' y2='263.99' />
						<line x1='129.61' y1='246.98' x2='129.61' y2='263.99' />
						<line x1='127.72' y1='246.98' x2='127.72' y2='263.99' />
						<line x1='125.83' y1='246.98' x2='125.83' y2='263.99' />
						<line x1='123.94' y1='246.98' x2='123.94' y2='263.99' />
						<line x1='122.05' y1='246.98' x2='122.05' y2='263.99' />
						<line x1='120.16' y1='246.98' x2='120.16' y2='263.99' />
						<line x1='137.17' y1='255.48' x2='120.16' y2='255.48' />
						<rect x='26.71' y='128.85' width='15.75' height='68.99' />
						<polygon points='42.46,128.85 42.46,66.8 77.55,66.8 77.55,52.22 22.96,52.22 26.71,128.85 		' />
						<polygon points='184.83,56.56 182.95,145.55 167.2,145.55 167.2,76.25 129.56,76.25 129.56,56.56 		' />
						<polygon points='184.83,270.13 182.95,202.25 167.2,202.25 167.2,270.13 		' />
						<polygon points='42.47,197.84 42.46,276.1 22.96,276.1 26.71,197.84 		' />
						<rect x='167.2' y='145.55' width='15.75' height='56.7' />
					</g>
					<g>
						<polyline points='534.75,86.8 551.13,86.8 551.13,106.49 		' />
						<line x1='551.13' y1='121.29' x2='534.75' y2='121.29' />
						<rect x='304.45' y='189.33' width='82.24' height='19.5' />
						<rect x='386.7' y='189.33' width='82.24' height='19.5' />
						<rect x='468.91' y='189.33' width='82.24' height='19.5' />
						<rect x='377.82' y='48.84' width='74.74' height='15.75' />
						<polygon points='452.56,48.84 547.09,48.84 547.09,86.8 534.75,86.8 534.75,64.59 452.56,64.59 		' />
						<rect x='301.39' y='48.84' width='54.9' height='15.75' />
						<polyline points='263.28,102.23 243.43,102.23 243.43,119.09 263.28,119.09 		' />
						<polyline points='243.43,119.09 238.71,119.09 238.71,102.23 243.43,102.23 		' />
						<line x1='242.49' y1='166.81' x2='242.49' y2='119.09' />
						<line x1='242.49' y1='132.63' x2='259.18' y2='132.63' />
						<rect x='238.71' y='189.33' width='65.84' height='19.5' />
						<polyline points='301.39,64.59 301.39,48.84 243.43,48.84 243.43,102.23 263.28,102.23 		' />
						<line x1='242.49' y1='140.77' x2='259.18' y2='140.77' />
						<line x1='242.49' y1='142.66' x2='259.18' y2='142.66' />
						<line x1='242.49' y1='144.55' x2='259.18' y2='144.55' />
						<line x1='242.49' y1='146.44' x2='259.18' y2='146.44' />
						<line x1='242.49' y1='148.33' x2='259.18' y2='148.33' />
						<line x1='242.49' y1='150.22' x2='259.18' y2='150.22' />
						<line x1='242.49' y1='152.11' x2='259.18' y2='152.11' />
						<line x1='242.49' y1='154' x2='259.18' y2='154' />
						<line x1='242.49' y1='155.89' x2='259.18' y2='155.89' />
						<line x1='242.49' y1='157.78' x2='259.18' y2='157.78' />
						<line x1='250.83' y1='140.77' x2='250.83' y2='157.78' />
						<line x1='534.81' y1='96.2' x2='551.19' y2='96.2' />
						<line x1='534.81' y1='98.09' x2='551.19' y2='98.09' />
						<line x1='534.81' y1='99.98' x2='551.19' y2='99.98' />
						<line x1='534.81' y1='101.87' x2='551.19' y2='101.87' />
						<line x1='534.81' y1='103.76' x2='551.19' y2='103.76' />
						<line x1='534.81' y1='105.65' x2='551.19' y2='105.65' />
						<line x1='534.81' y1='107.54' x2='551.19' y2='107.54' />
						<line x1='534.81' y1='109.43' x2='551.19' y2='109.43' />
						<line x1='534.81' y1='111.32' x2='551.19' y2='111.32' />
						<line x1='534.81' y1='113.21' x2='551.19' y2='113.21' />
						<line x1='543' y1='96.2' x2='543' y2='113.21' />
						<polygon
							points='634.97,95.24 634.97,200.58 574.72,200.58 574.72,189.33 607.51,189.33 607.51,106.49 574.72,106.49
			574.72,95.24 		'
						/>
						<rect x='238.71' y='172.48' width='3.78' height='16.85' />
					</g>
				</g>
				<g id='UNITS'>
					<g id='LEVEL-4-LABEL'>
						<text
							transform='matrix(1 0 0 1 471.9629 329.0678)'
							className={SCSS.LevelLabel}>
							LEVEL 4
						</text>
					</g>
					<g id='LEVEL-4-BUILDING-LABEL'>
						<text
							transform='matrix(1 0 0 1 80.4454 16.9003)'
							className={SCSS.BuildingLabel}>
							ZARA 1
						</text>
						<text
							transform='matrix(1 0 0 1 386.6978 16.9003)'
							className={SCSS.BuildingLabel}>
							ZARA 2
						</text>
					</g>
					<g id='LEVEL-4-ZARA-2'>
						<g
							className={highlight('401', 'A', 'ZARA 2')}
							onClick={() =>
								openFloorplan('401', 'A', 'ZARA 2', '4', 'location-2-w-e')
							}
							id='UINIT-401-A'>
							<g id='LWPOLYLINE_588_'>
								<rect
									x='263.28'
									y='64.59'
									className={SCSS.Hot}
									width='38.06'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 272.3051 93.2431)'
									className={SCSS.Number}>
									401
								</text>
								<text
									transform='matrix(1 0 0 1 276.3051 113.2431)'
									className={SCSS.PlanName}>
									A
								</text>
							</g>
						</g>
						<g
							className={highlight('402', 'B2', 'ZARA 2')}
							onClick={() =>
								openFloorplan('402', 'B2', 'ZARA 2', '4', 'location-2-w-e')
							}
							id='UINIT-402-B2'>
							<g id='LWPOLYLINE_589_'>
								<rect
									x='301.33'
									y='64.59'
									className={SCSS.Hot}
									width='68.99'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 320.9497 93.2431)'
									className={SCSS.Number}>
									402
								</text>
								<text
									transform='matrix(1 0 0 1 324.9497 113.2431)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							className={highlight('403', 'B1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('403', 'B1', 'ZARA 2', '4', 'location-2-w-e')
							}
							id='UINIT-403-B1'>
							<g id='LWPOLYLINE_590_'>
								<rect
									x='370.32'
									y='64.59'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 399.2483 93.2431)'
									className={SCSS.Number}>
									403
								</text>
								<text
									transform='matrix(1 0 0 1 402.2483 113.2431)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							className={highlight('404', 'B1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('404', 'B1', 'ZARA 2', '4', 'location-2-w-e')
							}
							id='UINIT-404-B1'>
							<g id='LWPOLYLINE_591_'>
								<rect
									x='452.53'
									y='64.59'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 481.4636 93.2431)'
									className={SCSS.Number}>
									404
								</text>
								<text
									transform='matrix(1 0 0 1 484.4636 113.2431)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							className={highlight('405', 'B1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('405', 'B1', 'ZARA 2', '4', 'location-1')
							}
							id='UINIT-405-B1'>
							<g id='LWPOLYLINE_592_'>
								<rect
									x='551.13'
									y='106.49'
									className={SCSS.Hot}
									width='56.38'
									height='82.85'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 567.1438 146.2838)'
									className={SCSS.Number}>
									405
								</text>
								<text
									transform='matrix(1 0 0 1 570.1438 166.2838)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							className={highlight('406', 'B1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('406', 'B1', 'ZARA 2', '4', 'location-2-w')
							}
							id='UINIT-406-B1'>
							<g id='LWPOLYLINE_593_'>
								<rect
									x='468.91'
									y='132.63'
									className={SCSS.Hot}
									width='82.22'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 496.8583 159.0689)'
									className={SCSS.Number}>
									406
								</text>
								<text
									transform='matrix(1 0 0 1 500.8583 179.0689)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							className={highlight('407', 'B1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('407', 'B1', 'ZARA 2', '4', 'location-2-w')
							}
							id='UINIT-407-B1'>
							<g id='LWPOLYLINE_594_'>
								<rect
									x='386.7'
									y='132.63'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 415.6431 159.0689)'
									className={SCSS.Number}>
									407
								</text>
								<text
									transform='matrix(1 0 0 1 418.6431 179.0689)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							className={highlight('408', 'B1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('408', 'B1', 'ZARA 2', '4', 'location-2-w')
							}
							id='UINIT-408-B1'>
							<g id='LWPOLYLINE_595_'>
								<rect
									x='304.48'
									y='132.63'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 334.9558 159.0689)'
									className={SCSS.Number}>
									408
								</text>
								<text
									transform='matrix(1 0 0 1 337.9558 179.0689)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							className={highlight('409', 'A2', 'ZARA 2')}
							onClick={() =>
								openFloorplan('409', 'A2', 'ZARA 2', '4', 'location-2-w')
							}
							id='UINIT-409-A2'>
							<g id='LWPOLYLINE_587_'>
								<polygon
									className={SCSS.Hot}
									points='304.54,189.33 242.49,189.33 242.49,166.81 259.18,166.81 259.18,132.63 304.48,132.63 				'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 267.5586 159.0689)'
									className={SCSS.Number}>
									409
								</text>
								<text
									transform='matrix(1 0 0 1 270.5586 179.0689)'
									className={SCSS.PlanName}>
									A2
								</text>
							</g>
						</g>
					</g>
					<g id='LEVEL-4-ZARA-1'>
						<g
							className={highlight('401', 'B2', 'ZARA 1')}
							onClick={() =>
								openFloorplan('401', 'B2', 'ZARA 1', '4', 'location-3-e')
							}
							id='UINIT-401-B2'>
							<g id='LWPOLYLINE_601_'>
								<rect
									x='110.5'
									y='76.25'
									className={SCSS.Hot}
									width='56.7'
									height='69.3'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 126.9471 108.3364)'
									className={SCSS.Number}>
									401
								</text>
								<text
									transform='matrix(1 0 0 1 127.9471 128.3364)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							className={highlight('402', 'A4', 'ZARA 1')}
							onClick={() =>
								openFloorplan('402', 'A4', 'ZARA 1', '4', 'location-3-s')
							}
							id='UINIT-402-A4'>
							<g id='LWPOLYLINE_602_'>
								<rect
									x='110.5'
									y='145.55'
									className={SCSS.Hot}
									width='56.7'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 125.3265 171.3157)'
									className={SCSS.Number}>
									402
								</text>
								<text
									transform='matrix(1 0 0 1 127.3265 191.3157)'
									className={SCSS.PlanName}>
									A4
								</text>
							</g>
						</g>
						<g
							className={highlight('403', 'A2', 'ZARA 1')}
							onClick={() =>
								openFloorplan('403', 'A2', 'ZARA 1', '4', 'location-3-s')
							}
							id='UINIT-403-A2'>
							<g id='LWPOLYLINE_597_'>
								<polygon
									className={SCSS.Hot}
									points='110.5,202.25 110.5,246.98 145.31,246.98 145.31,263.99 167.2,263.99 167.2,202.25 				'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 125.5785 222.5819)'
									className={SCSS.Number}>
									403
								</text>
								<text
									transform='matrix(1 0 0 1 127.5785 242.5819)'
									className={SCSS.PlanName}>
									A2
								</text>
							</g>
						</g>
						<g
							className={highlight('404', 'C1', 'ZARA 1')}
							onClick={() =>
								openFloorplan('404', 'C1', 'ZARA 1', '4', 'location-4')
							}
							id='UINIT-404-C1'>
							<g id='LWPOLYLINE_598_'>
								<polygon
									className={SCSS.Hot}
									points='38.68,280.05 38.68,297.46 42.46,297.46 42.5,329.19 167.2,329.19 167.2,263.99 99.16,263.99
					99.16,280.05 				'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 90.802 303.1484)'
									className={SCSS.Number}>
									404
								</text>
								<text
									transform='matrix(1 0 0 1 92.802 323.1484)'
									className={SCSS.PlanName}>
									C1
								</text>
							</g>
						</g>
						<g
							className={highlight('405', 'B1', 'ZARA 1')}
							onClick={() =>
								openFloorplan('405', 'B1', 'ZARA 1', '4', 'location-3-n')
							}
							id='UINIT-405-B1_1_'>
							<g id='LWPOLYLINE_599_'>
								<rect
									x='42.46'
									y='197.84'
									className={SCSS.Hot}
									width='56.7'
									height='82.21'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 58.6355 237.9214)'
									className={SCSS.Number}>
									405
								</text>
								<text
									transform='matrix(1 0 0 1 61.6355 257.9214)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							className={highlight('406', 'B2', 'ZARA 1')}
							onClick={() =>
								openFloorplan('406', 'B2', 'ZARA 1', '4', 'location-3-n')
							}
							id='UINIT-406-B2'>
							<g id='LWPOLYLINE_600_'>
								<rect
									x='42.46'
									y='128.85'
									className={SCSS.Hot}
									width='56.7'
									height='68.98'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 56.9075 161.5977)'
									className={SCSS.Number}>
									406
								</text>
								<text
									transform='matrix(1 0 0 1 59.9075 181.5977)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							className={highlight('407', 'A2', 'ZARA 1')}
							onClick={() =>
								openFloorplan('407', 'A2', 'ZARA 1', '4', 'location-3-e')
							}
							id='UINIT-407-A2'>
							<g id='LWPOLYLINE_596_'>
								<polygon
									className={SCSS.Hot}
									points='99.16,128.85 99.16,83.49 64.98,83.49 64.98,66.8 42.46,66.8 42.46,128.85 				'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 57.5388 104.6339)'
									className={SCSS.Number}>
									407
								</text>
								<text
									transform='matrix(1 0 0 1 59.5388 124.6339)'
									className={SCSS.PlanName}>
									A2
								</text>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</div>
	);
};

export default Level4;
