import React from 'react';
import Pool from './Pool';
import BuildingLabel from './BuildingLabel';
import LakeLabel from './LakeLabel';
import SCSS from './Keys.module.scss';

type Props = {
	highlight: (plan: string) => string;
	openFloorplan: (
		room: string,
		plan: string,
		building: string,
		level: string,
		viewLocation: string
	) => void;
};

const Level1 = (props: Props) => {
	const {highlight, openFloorplan} = props;
	return (
		<div className={SCSS.Keyplate}>
			<svg viewBox='0 0 750 400'>
				<Pool />
				<g id='LINEWORKS' className={SCSS.Lineworks}>
					<g>
						<polygon
							points='78.85,277.2 85.3,277.2 85.3,329.19 221.07,329.19 221.07,263.97 214.93,263.97 214.92,246.96
			226.27,246.96 226.27,64.58 176.97,64.58 176.97,56.54 145.94,56.54 145.94,66.78 78.85,66.78 		'
						/>
						<line x1='108.85' y1='83.48' x2='108.85' y2='66.78' />
						<line x1='108.85' y1='83.48' x2='146.88' y2='83.48' />
						<line x1='146.88' y1='66.78' x2='146.88' y2='236.57' />
						<line x1='146.88' y1='236.57' x2='78.85' y2='236.57' />
						<line x1='158.22' y1='263.97' x2='158.22' y2='111.44' />
						<line x1='158.22' y1='263.97' x2='214.92' y2='263.97' />
						<line x1='214.92' y1='246.96' x2='214.92' y2='263.97' />
						<line x1='214.92' y1='246.96' x2='158.22' y2='246.96' />
						<line x1='179.6' y1='199.12' x2='158.22' y2='199.12' />
						<line x1='160.43' y1='77.18' x2='176.97' y2='77.18' />
						<line x1='160.43' y1='77.18' x2='160.43' y2='56.54' />
						<line x1='145.94' y1='66.78' x2='146.88' y2='66.78' />
						<line x1='129.56' y1='121.2' x2='146.88' y2='121.2' />
						<line x1='129.56' y1='121.2' x2='129.56' y2='142.14' />
						<line x1='129.56' y1='142.14' x2='146.88' y2='142.14' />
						<line x1='158.22' y1='246.96' x2='78.85' y2='246.96' />
						<line x1='176.97' y1='77.18' x2='176.97' y2='56.54' />
						<line x1='226.27' y1='111.44' x2='146.88' y2='111.44' />
						<line x1='167.21' y1='189.32' x2='167.21' y2='111.44' />
						<line x1='179.6' y1='199.12' x2='179.6' y2='219.82' />
						<line x1='179.6' y1='219.82' x2='158.22' y2='219.82' />
						<line x1='190' y1='199.12' x2='190' y2='246.96' />
						<line x1='190' y1='199.12' x2='226.27' y2='199.12' />
						<line x1='190' y1='219.53' x2='226.27' y2='219.53' />
						<line x1='167.21' y1='189.32' x2='226.27' y2='189.2' />
						<line x1='210.22' y1='72.61' x2='226.27' y2='72.61' />
						<line x1='210.22' y1='72.61' x2='210.22' y2='86.29' />
						<line x1='210.22' y1='86.29' x2='226.27' y2='86.29' />
						<line x1='179.6' y1='207.36' x2='190' y2='207.36' />
						<line x1='138.57' y1='66.78' x2='138.57' y2='83.48' />
						<line x1='136.68' y1='66.78' x2='136.68' y2='83.48' />
						<line x1='134.79' y1='66.78' x2='134.79' y2='83.48' />
						<line x1='132.9' y1='66.78' x2='132.9' y2='83.48' />
						<line x1='131.01' y1='66.78' x2='131.01' y2='83.48' />
						<line x1='129.12' y1='66.78' x2='129.12' y2='83.48' />
						<line x1='127.23' y1='66.78' x2='127.23' y2='83.48' />
						<line x1='125.34' y1='66.78' x2='125.34' y2='83.48' />
						<line x1='123.45' y1='66.78' x2='123.45' y2='83.48' />
						<line x1='121.56' y1='66.78' x2='121.56' y2='83.48' />
						<line x1='138.57' y1='75.13' x2='121.56' y2='75.13' />
						<line x1='184.95' y1='246.96' x2='184.95' y2='263.97' />
						<line x1='183.06' y1='246.96' x2='183.06' y2='263.97' />
						<line x1='181.17' y1='246.96' x2='181.17' y2='263.97' />
						<line x1='179.28' y1='246.96' x2='179.28' y2='263.97' />
						<line x1='177.39' y1='246.96' x2='177.39' y2='263.97' />
						<line x1='175.5' y1='246.96' x2='175.5' y2='263.97' />
						<line x1='173.61' y1='246.96' x2='173.61' y2='263.97' />
						<line x1='171.72' y1='246.96' x2='171.72' y2='263.97' />
						<line x1='169.83' y1='246.96' x2='169.83' y2='263.97' />
						<line x1='167.94' y1='246.96' x2='167.94' y2='263.97' />
						<line x1='184.95' y1='255.47' x2='167.94' y2='255.47' />
					</g>
					<g>
						<polyline
							points='306.9,179.87 290.21,179.87 290.21,132.62 290.21,119.07 286.43,119.07 286.43,102.22
			290.52,102.22 290.52,64.58 349.11,64.58 		'
						/>
						<polyline points='582.53,64.58 598.91,64.58 598.91,106.47 		' />
						<line x1='598.91' y1='121.28' x2='582.53' y2='121.28' />
						<line x1='306.9' y1='132.62' x2='290.21' y2='132.62' />
						<rect x='290.21' y='102.22' width='20.79' height='16.85' />
						<rect x='323.62' y='102.01' width='25.5' height='16.95' />
						<rect x='290.52' y='71.42' width='16.44' height='16.89' />
						<rect x='352.23' y='189.32' width='82.24' height='19.5' />
						<rect x='434.48' y='189.32' width='82.24' height='19.5' />
						<rect x='516.69' y='189.32' width='82.21' height='19.5' />
						<rect x='418.1' y='45.08' width='82.24' height='19.5' />
						<rect x='500.34' y='45.08' width='82.24' height='19.5' />
						<rect x='306.85' y='189.32' width='45.39' height='19.5' />
						<rect x='349.11' y='45.08' width='68.99' height='19.5' />
						<line x1='290.27' y1='140.75' x2='306.96' y2='140.75' />
						<line x1='290.27' y1='142.64' x2='306.96' y2='142.64' />
						<line x1='290.27' y1='144.53' x2='306.96' y2='144.53' />
						<line x1='290.27' y1='146.42' x2='306.96' y2='146.42' />
						<line x1='290.27' y1='148.31' x2='306.96' y2='148.31' />
						<line x1='290.27' y1='150.2' x2='306.96' y2='150.2' />
						<line x1='290.27' y1='152.09' x2='306.96' y2='152.09' />
						<line x1='290.27' y1='153.98' x2='306.96' y2='153.98' />
						<line x1='290.27' y1='155.87' x2='306.96' y2='155.87' />
						<line x1='290.27' y1='157.76' x2='306.96' y2='157.76' />
						<line x1='298.62' y1='140.75' x2='298.62' y2='157.76' />
						<line x1='582.53' y1='96.19' x2='598.91' y2='96.19' />
						<line x1='582.53' y1='98.08' x2='598.91' y2='98.08' />
						<line x1='582.53' y1='99.97' x2='598.91' y2='99.97' />
						<line x1='582.53' y1='101.86' x2='598.91' y2='101.86' />
						<line x1='582.53' y1='103.75' x2='598.91' y2='103.75' />
						<line x1='582.53' y1='105.64' x2='598.91' y2='105.64' />
						<line x1='582.53' y1='107.53' x2='598.91' y2='107.53' />
						<line x1='582.53' y1='109.42' x2='598.91' y2='109.42' />
						<line x1='582.53' y1='111.31' x2='598.91' y2='111.31' />
						<line x1='582.53' y1='113.2' x2='598.91' y2='113.2' />
						<line x1='590.72' y1='96.19' x2='590.72' y2='113.2' />
						<polygon
							points='682.75,94.47 598.91,94.47 598.91,106.47 655.29,106.47 655.29,189.32 598.91,189.32 598.91,208.82
			682.75,208.82 		'
						/>
					</g>
				</g>
				<g id='UNITS'>
					<g id='LEVEL-1-LABEL'>
						<text
							transform='matrix(1 0 0 1 85.4619 393.0516)'
							className={SCSS.LevelLabel}>
							LEVEL 1
						</text>
					</g>
					<BuildingLabel />
					<g id='LEVEL-1-ZARA-2_2_'>
						<g
							id='UINIT-101-B2_2_'
							onClick={() =>
								openFloorplan('101', 'B2', 'ZARA 2', '1', 'location-2-e')
							}
							className={highlight('B2')}>
							<g id='LWPOLYLINE_39_'>
								<rect
									x='349.11'
									y='64.58'
									className={SCSS.Hot}
									width='68.99'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 372.9873 88.7745)'
									className={SCSS.Number}>
									101
								</text>
								<text
									transform='matrix(1 0 0 1 371.7949 107.4177)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							id='UINIT-102-B1_2_'
							onClick={() =>
								openFloorplan('102', 'B1', 'ZARA 2', '1', 'location-2-e')
							}
							className={highlight('B1')}>
							<g id='LWPOLYLINE_38_'>
								<rect
									x='418.1'
									y='64.58'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 447.6572 88.7745)'
									className={SCSS.Number}>
									102
								</text>
								<text
									transform='matrix(1 0 0 1 449.7119 107.4177)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							id='UINIT-103-B1_2_'
							onClick={() =>
								openFloorplan('103', 'B1', 'ZARA 2', '1', 'location-2-e')
							}
							className={highlight('B1')}>
							<g id='LWPOLYLINE_37_'>
								<rect
									x='500.31'
									y='64.58'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 529.8302 88.7745)'
									className={SCSS.Number}>
									103
								</text>
								<text
									transform='matrix(1 0 0 1 531.9269 107.4177)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							id='UINIT-104-B1_2_'
							onClick={() =>
								openFloorplan('104', 'B1', 'ZARA 2', '1', 'location-1')
							}
							className={highlight('B1')}>
							<g id='LWPOLYLINE_36_'>
								<rect
									x='598.91'
									y='106.47'
									className={SCSS.Hot}
									width='56.38'
									height='82.85'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 615.5312 143.6411)'
									className={SCSS.Number}>
									104
								</text>
								<text
									transform='matrix(1 0 0 1 617.6069 162.2842)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							id='UINIT-105-B1_2_'
							onClick={() =>
								openFloorplan('105', 'B1', 'ZARA 2', '1', 'location-2-w')
							}
							className={highlight('B1')}>
							<g id='LWPOLYLINE_35_'>
								<rect
									x='516.69'
									y='132.62'
									className={SCSS.Hot}
									width='82.22'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 546.1471 156.8711)'
									className={SCSS.Number}>
									105
								</text>
								<text
									transform='matrix(1 0 0 1 548.3068 175.5143)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							id='UINIT-106-B1_2_'
							onClick={() =>
								openFloorplan('106', 'B1', 'ZARA 2', '1', 'location-2-w')
							}
							className={highlight('B1')}>
							<g id='LWPOLYLINE_34_'>
								<rect
									x='434.48'
									y='132.62'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 463.87 156.8711)'
									className={SCSS.Number}>
									106
								</text>
								<text
									transform='matrix(1 0 0 1 466.1068 175.5143)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							id='UINIT-107-B1_2_'
							onClick={() =>
								openFloorplan('107', 'B1', 'ZARA 2', '1', 'location-2-w')
							}
							className={highlight('B1')}>
							<g id='LWPOLYLINE_33_'>
								<rect
									x='352.26'
									y='132.62'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 382.1163 156.8711)'
									className={SCSS.Number}>
									107
								</text>
								<text
									transform='matrix(1 0 0 1 383.8771 175.5143)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							id='UINIT-108-A3_2_'
							onClick={() =>
								openFloorplan('108', 'A3', 'ZARA 2', '1', 'location-2-w')
							}
							className={highlight('A3')}>
							<g id='LWPOLYLINE_32_'>
								<rect
									x='306.9'
									y='132.62'
									className={SCSS.Hot}
									width='45.36'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 318.0182 156.8711)'
									className={SCSS.Number}>
									108
								</text>
								<text
									transform='matrix(1 0 0 1 317.8043 175.5143)'
									className={SCSS.PlanName}>
									A3
								</text>
							</g>
						</g>
					</g>
				</g>
				<LakeLabel />
			</svg>
		</div>
	);
};

export default Level1;
