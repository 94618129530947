import React from 'react';
import {useSelector} from 'react-redux';
import Level1 from './Level1';
import Level2 from './Level2';
import Level3 from './Level3';
import Level4 from './Level4';
import Level5 from './Level5';
import Level6 from './Level6';
import SCSS from './Keys.module.scss';
import {selectCurrent} from '../../../../redux/moduleSlice';

const classnames = require('classNames');

type Props = {
	openFloorplan: (
		room: string,
		plan: string,
		building: string,
		level: string,
		viewLocation: string
	) => void;
};

const Keys = (props: Props) => {
	const {openFloorplan} = props;
	const {room, plan, building} = useSelector(selectCurrent);

	const highlight = (r: string, p: string, b: string) => {
		const unitClasses = [
			SCSS.Unit,
			{
				[SCSS.Highlight]: p === plan,
				[SCSS.Selected]: r === room && b === building,
			},
		];
		return classnames(unitClasses);
	};

	return (
		<div className={SCSS.Keys}>
			<div className={SCSS.Grid}>
				<div className={SCSS.Label}>Select a home to see plan and view</div>
				<Level6 openFloorplan={openFloorplan} highlight={highlight} />
				<Level5 openFloorplan={openFloorplan} highlight={highlight} />
				<Level4 openFloorplan={openFloorplan} highlight={highlight} />
				<Level3 openFloorplan={openFloorplan} highlight={highlight} />
				<Level2 openFloorplan={openFloorplan} highlight={highlight} />
				<Level1 openFloorplan={openFloorplan} highlight={highlight} />
				<div className={SCSS.Compass} />
			</div>
		</div>
	);
};

export default Keys;
