import React from 'react';
import SCSS from './GalleryHeader.module.scss';
import {IFloorplan} from '../../../../constants/floorplans';

type Props = {
	gotoView: () => void;
	plan: IFloorplan;
};

const classnames = require('classnames');
const _ = require('lodash');

const ViewButton = (props: Props) => {
	const {gotoView, plan} = props;

	const viewButtonClasses = [SCSS.Button];

	const clickViewButton = () => {
		gotoView();
	};

	const zara1Levels = plan.levels.zara1;
	const zara2Levels = plan.levels.zara2;

	if (_.head(zara1Levels) < _.last(zara1Levels)) {
		_.reverse(zara1Levels);
	}

	if (_.head(zara2Levels) < _.last(zara2Levels)) {
		_.reverse(zara2Levels);
	}

	return (
		<div className={classnames(viewButtonClasses)}>
			<i className={SCSS.IconView} />
			<div onClick={clickViewButton}>The View</div>
		</div>
	);
};

export default ViewButton;
