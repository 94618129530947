import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';
import IUnit from '../constants/units/IUnit';
import IFloor from '../constants/views/IFloor';
import UNITS from '../constants/units';
const _ = require('lodash');
export interface IinitialState {
	current: {
		room?: string;
		abbr?: string;
		plan?: string;
		level?: string;
		building?: string;
		viewLocation?: string;
		view?: IFloor;
		viewType: string;
	};
}

const initialState: IinitialState = {
	current: {
		room: '101',
		abbr: '01',
		plan: 'A',
		level: '1',
		building: 'ZARA 1',
		viewLocation: 'location-1',
		view: {
			location: '',
			width: 0,
			loop: false,
			direction: '',
			view: [],
		},
		viewType: 'normal',
	},
};

export const moduleSlice = createSlice({
	name: 'module',
	initialState,
	reducers: {
		updateCurrentRoom: {
			reducer: (state, action: PayloadAction<{room: string}>) => {
				const {room} = action.payload;
				const abbr = room.substring(1);
				state.current.room = room;
				state.current.abbr = abbr;
			},
			prepare: ({room}: {room: string}) => ({
				payload: {room},
			}),
		},
		updateViewType: {
			reducer: (state, action: PayloadAction<{viewType: string}>) => {
				state.current.viewType = action.payload.viewType;
			},
			prepare: ({viewType}: {viewType: string}) => ({
				payload: {viewType},
			}),
		},
		updateCurrentPlan: {
			reducer: (state, action: PayloadAction<{plan: string}>) => {
				state.current.plan = action.payload.plan;
			},
			prepare: ({plan}: {plan: string}) => ({
				payload: {plan},
			}),
		},
		updateCurrentViewLocation: {
			reducer: (state, action: PayloadAction<{viewLocation: string}>) => {
				state.current.viewLocation = action.payload.viewLocation;
			},
			prepare: ({viewLocation}: {viewLocation: string}) => ({
				payload: {viewLocation},
			}),
		},
		updateCurrentBuildingLevelPlan: {
			reducer: (
				state,
				action: PayloadAction<{building: string; level: string}>
			) => {
				let {room, viewLocation, view} = state.current;
				const {building, level} = action.payload;

				if (
					building === state.current.building &&
					level !== state.current.level
				) {
					room = level + state.current.abbr;
				}

				if (
					building !== state.current.building ||
					level !== state.current.level
				) {
					const roomHasSamePlan: IUnit | undefined = _.find(UNITS, {
						plan: state.current.plan,
						building,
						level,
					});
					if (typeof roomHasSamePlan !== 'undefined') {
						room = roomHasSamePlan ? roomHasSamePlan.unit : room;
						view = roomHasSamePlan ? roomHasSamePlan.view : view;
						viewLocation =
							typeof roomHasSamePlan.view !== 'undefined'
								? roomHasSamePlan.view.location
								: viewLocation;
					}
				}

				const prevCurrent = state.current;
				state.current = {
					...prevCurrent,
					room,
					building,
					level,
					viewLocation,
					view,
				};
			},
			prepare: ({building, level}: {building: string; level: string}) => ({
				payload: {building, level},
			}),
		},
		updateCurrentBuildingLevel: {
			reducer: (
				state,
				action: PayloadAction<{building: string; level: string}>
			) => {
				let {room, viewLocation, view} = state.current;
				const {building, level} = action.payload;

				if (
					building === state.current.building &&
					level !== state.current.level
				) {
					room = level + state.current.abbr;
				}

				if (
					building !== state.current.building ||
					level !== state.current.level
				) {
					const ViewArray: IUnit = _.find(UNITS, {
						building,
						level,
					});
					room = ViewArray ? ViewArray.unit : room;
					view = ViewArray ? ViewArray.view : view;
					viewLocation =
						typeof ViewArray.view !== 'undefined'
							? ViewArray.view.location
							: viewLocation;
				}

				const prevCurrent = state.current;
				state.current = {
					...prevCurrent,
					room,
					building,
					level,
					viewLocation,
					view,
					// view360,
				};
			},
			prepare: ({building, level}: {building: string; level: string}) => ({
				payload: {building, level},
			}),
		},
		updateCurrent: {
			reducer: (
				state,
				action: PayloadAction<{
					room: string;
					plan: string;
					building: string;
					level: string;
					viewLocation: string;
					viewType: string;
				}>
			) => {
				const {room, plan, building, level, viewLocation, viewType} =
					action.payload;
				const abbr = room.substring(1);
				const selectedUnit: IUnit = _.find(UNITS, {
					unit: room,
					plan,
					level,
					building,
				});
				state.current = {
					room,
					abbr,
					plan,
					building,
					level,
					viewLocation,
					view: selectedUnit.view,
					// view360: selectedUnit.view360,
					viewType,
				};
			},
			prepare: ({
				room,
				plan,
				building,
				level,
				viewLocation,
				viewType,
			}: {
				room: string;
				plan: string;
				building: string;
				level: string;
				viewLocation: string;
				viewType: string;
			}) => ({
				payload: {
					room,
					plan,
					building,
					level,
					viewLocation,
					viewType,
				},
			}),
		},
	},
});

export const {
	updateCurrentRoom,
	updateViewType,
	updateCurrentPlan,
	updateCurrentViewLocation,
	updateCurrentBuildingLevelPlan,
	updateCurrentBuildingLevel,
	updateCurrent,
} = moduleSlice.actions;

export const selectCurrent = (state: RootState) => state.module.current;
export const selectCurrentPlan = (state: RootState) =>
	state.module.current.plan;
export const selectCurrentRoom = (state: RootState) =>
	state.module.current.room;
export const selectCurrentOrder = (state: RootState) =>
	state.module.current.abbr;
export const selectCurrentLevel = (state: RootState) =>
	state.module.current.level;
export const selectCurrentBuilding = (state: RootState) =>
	state.module.current.building;
export const selectCurrentViewLocation = (state: RootState) =>
	state.module.current.viewLocation;
export default moduleSlice.reducer;
