import SCSS from './Splash.module.scss';

type Props = {
	backToKeyplate: () => void;
};

const Splash = (props: Props) => {
	const {backToKeyplate} = props;
	return (
		<div className={SCSS.Splash}>
			<div className={SCSS.Logo}>
				<h1>COMING THIS SUMMER</h1>
				<h2>
					1, 2 &amp; 3 BED CONDOMINIUM RESIDENCES
					<br />
					WITH 5 STAR AMENITIES
				</h2>
			</div>
			<div className={SCSS.Tagline}>
				This interactive view study is designed for desktop display. Minimum
				1920x1080 resolution recommended for optimal viewing.
			</div>

			<div className={SCSS.Button} onClick={backToKeyplate}>
				Start
			</div>
		</div>
	);
};

export default Splash;
