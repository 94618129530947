/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, {useEffect} from 'react';
import anime from 'animejs';
import {useSelector} from 'react-redux';
import {useKeenSlider} from 'keen-slider/react';
import SCSS from './Slideshow.module.scss';
import {selectCurrent} from '../../../../redux/moduleSlice';
import IFloor, {IView} from '../../../../constants/views/IFloor';

const _ = require('lodash');

const Slideshow = () => {
	const current = useSelector(selectCurrent);
	// const ViewArray: IFloor = current.view;
	// const {width, view, loop} =  ViewArray;
	const width = typeof current.view !== 'undefined' ? current.view.width : 0;
	const view = typeof current.view !== 'undefined' ? current.view.view : [];
	const loop = typeof current.view !== 'undefined' ? current.view.loop : false;

	console.log('view =', view);

	const planIndex = _.findIndex(view, (o: IView) => o.title === current.room);

	const slidesPerpage = 1860 / width;

	const [sliderRef] = useKeenSlider<HTMLElement>({
		slidesPerView: slidesPerpage,
		centered: true,
		mode: 'free-snap',
		spacing: 0,
		loop,
		dragSpeed: 0.3333,
		initial: planIndex,
	});

	useEffect(() => {
		anime({
			targets: sliderRef.current,
			opacity: [0, 1],
			duration: 500,
			easing: 'linear',
		});
	}, [current.level, current.building]);

	return (
		view && (
			<div ref={sliderRef} className={`keen-slider ${SCSS.KeenSlider}`}>
				{view.map((img: IView, index: number) => (
					<div key={index} className={`keen-slider__slide ${SCSS.Slide}`}>
						<img src={img.src} />
					</div>
				))}
			</div>
		)
	);
};

export default Slideshow;
