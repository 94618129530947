import IFloor from './IFloor';
import Floor_1_Image_1 from '../../views/location-2-e/floor-1/images/view_01.jpg';
import Floor_1_Image_2 from '../../views/location-2-e/floor-1/images/view_02.jpg';
import Floor_1_Image_3 from '../../views/location-2-e/floor-1/images/view_03.jpg';

import Floor_2_Image_1 from '../../views/location-2-e/floor-2/images/view_01.jpg';
import Floor_2_Image_2 from '../../views/location-2-e/floor-2/images/view_02.jpg';
import Floor_2_Image_3 from '../../views/location-2-e/floor-2/images/view_03.jpg';
import Floor_2_Image_4 from '../../views/location-2-e/floor-2/images/view_04.jpg';

import Floor_3_Image_1 from '../../views/location-2-e/floor-3/images/view_01.jpg';
import Floor_3_Image_2 from '../../views/location-2-e/floor-3/images/view_02.jpg';
import Floor_3_Image_3 from '../../views/location-2-e/floor-3/images/view_03.jpg';
import Floor_3_Image_4 from '../../views/location-2-e/floor-3/images/view_04.jpg';

import Floor_4_Image_1 from '../../views/location-2-e/floor-4/images/view_01.jpg';
import Floor_4_Image_2 from '../../views/location-2-e/floor-4/images/view_02.jpg';
import Floor_4_Image_3 from '../../views/location-2-e/floor-4/images/view_03.jpg';
import Floor_4_Image_4 from '../../views/location-2-e/floor-4/images/view_04.jpg';

import Floor_5_Image_1 from '../../views/location-2-e/floor-5/images/view_01.jpg';
import Floor_5_Image_2 from '../../views/location-2-e/floor-5/images/view_02.jpg';
import Floor_5_Image_3 from '../../views/location-2-e/floor-5/images/view_03.jpg';
import Floor_5_Image_4 from '../../views/location-2-e/floor-5/images/view_04.jpg';
import Floor_5_Image_5 from '../../views/location-2-e/floor-5/images/view_05.jpg';
import Floor_5_Image_6 from '../../views/location-2-e/floor-5/images/view_06.jpg';

import Floor_6_Image_1 from '../../views/location-2-e/floor-6/images/view_01.jpg';
import Floor_6_Image_2 from '../../views/location-2-e/floor-6/images/view_02.jpg';
import Floor_6_Image_3 from '../../views/location-2-e/floor-6/images/view_03.jpg';
import Floor_6_Image_4 from '../../views/location-2-e/floor-6/images/view_04.jpg';

const floor1: IFloor = {
	location: 'location-2-e',
	width: 1240,
	loop: false,
	direction: 'east',
	view: [
		{
			title: '101',
			order: '01',
			plan: 'B2',
			src: Floor_1_Image_1,
		},
		{
			title: '102',
			order: '02',
			plan: 'B1',
			src: Floor_1_Image_2,
		},
		{
			title: '103',
			order: '03',
			plan: 'B1',
			src: Floor_1_Image_3,
		},
	],
};

const floor2: IFloor = {
	location: 'location-2-e',
	width: 1160,
	loop: false,
	direction: 'east',
	view: [
		{
			title: '201',
			order: '01',
			plan: 'A',
			src: Floor_2_Image_1,
		},
		{
			title: '202',
			order: '02',
			plan: 'B2',
			src: Floor_2_Image_2,
		},
		{
			title: '203',
			order: '03',
			plan: 'B1',
			src: Floor_2_Image_3,
		},
		{
			title: '204',
			order: '04',
			plan: 'B1',
			src: Floor_2_Image_4,
		},
	],
};

const floor3: IFloor = {
	location: 'location-2-e',
	width: 1160,
	loop: false,
	direction: 'east',
	view: [
		{
			title: '301',
			order: '01',
			plan: 'A',
			src: Floor_3_Image_1,
		},
		{
			title: '302',
			order: '02',
			plan: 'B2',
			src: Floor_3_Image_2,
		},
		{
			title: '303',
			order: '03',
			plan: 'B1',
			src: Floor_3_Image_3,
		},
		{
			title: '304',
			order: '04',
			plan: 'B1',
			src: Floor_3_Image_4,
		},
	],
};

const floor4: IFloor = {
	location: 'location-2-e',
	width: 1160,
	loop: false,
	direction: 'east',
	view: [
		{
			title: '401',
			order: '01',
			plan: 'A',
			src: Floor_4_Image_1,
		},
		{
			title: '402',
			order: '02',
			plan: 'B2',
			src: Floor_4_Image_2,
		},
		{
			title: '403',
			order: '03',
			plan: 'B1',
			src: Floor_4_Image_3,
		},
		{
			title: '404',
			order: '04',
			plan: 'B1',
			src: Floor_4_Image_4,
		},
	],
};

const floor5: IFloor = {
	location: 'location-2-e',
	width: 780,
	loop: false,
	direction: 'east',
	view: [
		{
			title: '501',
			order: '01',
			plan: 'A',
			src: Floor_5_Image_1,
		},
		{
			title: '502',
			order: '02',
			plan: 'B2',
			src: Floor_5_Image_2,
		},
		{
			title: '603',
			order: '03',
			plan: 'P1',
			src: Floor_5_Image_3,
		},
		{
			title: '503',
			order: '03',
			plan: 'A1',
			src: Floor_5_Image_4,
		},
		{
			title: '604',
			order: '04',
			plan: 'P1',
			src: Floor_5_Image_5,
		},
		{
			title: '504',
			order: '04',
			plan: 'A',
			src: Floor_5_Image_6,
		},
	],
};

const floor6: IFloor = {
	location: 'location-2-e',
	width: 1160,
	loop: false,
	direction: 'east',
	view: [
		{
			title: '601',
			order: '01',
			plan: 'A',
			src: Floor_6_Image_1,
		},
		{
			title: '602',
			order: '02',
			plan: 'B2',
			src: Floor_6_Image_2,
		},
		{
			title: '603',
			order: '03',
			plan: 'P1',
			src: Floor_6_Image_3,
		},
		{
			title: '604',
			order: '04',
			plan: 'P1',
			src: Floor_6_Image_4,
		},
	],
};

const location2_E = {
	floor1,
	floor2,
	floor3,
	floor4,
	floor5,
	floor6,
};

export default location2_E;
