import React, {useState, useRef, useEffect} from 'react';
import Slider from 'react-slick';
import ArrowNext from '../../../Arrows/ArrowNext';
import ArrowPrev from '../../../Arrows/ArrowPrev';
import SCSS from './Slideshow.module.scss';
import {IFloorplan} from '../../../../constants/floorplans';

type Props = {
	plan: IFloorplan;
};

const Slideshow = (props: Props) => {
	const {plan} = props;
	const images: Array<{
		title: string;
		src: string;
	}> = plan.gallery ? plan.gallery : [];

	const slider1 = useRef(null);
	const slider2 = useRef(null);
	const [nav1, setNav1] = useState(slider1.current);
	const [nav2, setNav2] = useState(slider2.current);

	useEffect(() => {
		setNav1(slider1.current);
	}, [slider1]);

	useEffect(() => {
		setNav2(slider2.current);
	}, [slider2]);

	return images ? (
		<div className={SCSS.SliderShowContainer}>
			<Slider
				asNavFor={nav2}
				ref={slider1}
				dots={false}
				arrows
				infinite
				nextArrow={<ArrowNext />}
				prevArrow={<ArrowPrev />}
				className={SCSS.GSlidershow}>
				{images.map((img, index) => (
					<div key={index} className={SCSS.GSlide}>
						<div className={SCSS.Img}>
							<img src={img.src} alt={img.title} />
							<div className={SCSS.Caption}>{img.title}</div>
						</div>
					</div>
				))}
			</Slider>

			<Slider
				asNavFor={nav1}
				ref={slider2}
				dots={false}
				arrows={false}
				infinite
				className={SCSS.GalleryThumbnails}
				slidesToShow={images.length}
				focusOnSelect>
				{images.map((img, index) => (
					<div key={index} className={SCSS.GThumb}>
						<div className={SCSS.Img}>
							<img src={img.src} alt={img.title} />
						</div>
					</div>
				))}
			</Slider>
		</div>
	) : null;
};

export default Slideshow;
