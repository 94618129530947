import IFloor from './IFloor';

import Floor_2_Image_1 from '../../views/location-3-n/floor-2/images/view_01.jpg';
import Floor_2_Image_2 from '../../views/location-3-n/floor-2/images/view_02.jpg';

import Floor_3_Image_1 from '../../views/location-3-n/floor-3/images/view_01.jpg';
import Floor_3_Image_2 from '../../views/location-3-n/floor-3/images/view_02.jpg';

import Floor_4_Image_1 from '../../views/location-3-n/floor-4/images/view_01.jpg';
import Floor_4_Image_2 from '../../views/location-3-n/floor-4/images/view_02.jpg';

import Floor_5_Image_1 from '../../views/location-3-n/floor-5/images/view_01.jpg';
import Floor_5_Image_2 from '../../views/location-3-n/floor-5/images/view_02.jpg';
import Floor_5_Image_3 from '../../views/location-3-n/floor-5/images/view_03.jpg';

import Floor_6_Image_1 from '../../views/location-3-n/floor-6/images/view_01.jpg';
import Floor_6_Image_2 from '../../views/location-3-n/floor-6/images/view_02.jpg';

const floor2: IFloor = {
	location: 'location-3-n',
	width: 1257,
	loop: false,
	direction: 'north',
	view: [
		{
			title: '205',
			order: '05',
			plan: 'B1',
			src: Floor_2_Image_1,
		},
		{
			title: '206',
			order: '06',
			plan: 'B2',
			src: Floor_2_Image_2,
		},
	],
};

const floor3: IFloor = {
	location: 'location-3-n',
	width: 1257,
	loop: false,
	direction: 'north',
	view: [
		{
			title: '305',
			order: '05',
			plan: 'B1',
			src: Floor_3_Image_1,
		},
		{
			title: '306',
			order: '06',
			plan: 'B2',
			src: Floor_3_Image_2,
		},
	],
};

const floor4: IFloor = {
	location: 'location-3-n',
	width: 1257,
	loop: false,
	direction: 'north',
	view: [
		{
			title: '405',
			order: '05',
			plan: 'B1',
			src: Floor_4_Image_1,
		},
		{
			title: '406',
			order: '06',
			plan: 'B2',
			src: Floor_4_Image_2,
		},
	],
};

const floor5: IFloor = {
	location: 'location-3-n',
	width: 838,
	loop: false,
	direction: 'north',
	view: [
		{
			title: '605',
			order: '05',
			plan: 'P1',
			src: Floor_5_Image_1,
		},
		{
			title: '505',
			order: '05',
			plan: 'A1',
			src: Floor_5_Image_2,
		},
		{
			title: '506',
			order: '06',
			plan: 'B2',
			src: Floor_5_Image_3,
		},
	],
};

const floor6: IFloor = {
	location: 'location-3-n',
	width: 1257,
	loop: false,
	direction: 'north',
	view: [
		{
			title: '605',
			order: '05',
			plan: 'P1',
			src: Floor_6_Image_1,
		},
		{
			title: '606',
			order: '06',
			plan: 'B2',
			src: Floor_6_Image_2,
		},
	],
};

const location3_N = {
	floor2,
	floor3,
	floor4,
	floor5,
	floor6,
};

export default location3_N;
