import React from 'react';
import SCSS from './Keys.module.scss';

type Props = {
	openFloorplan: (
		room: string,
		plan: string,
		building: string,
		level: string,
		location: string
	) => void;
	highlight: (r: string, p: string, b: string) => string;
};

const Level6 = (props: Props) => {
	const {openFloorplan, highlight} = props;
	return (
		<div className={SCSS.Keyplate}>
			<svg viewBox='0 0 650 400'>
				<g id='POOL' className={SCSS.Lineworks}>
					<g>
						<linearGradient
							id='SVGID_1_'
							gradientUnits='userSpaceOnUse'
							x1='328.6905'
							y1='359.7091'
							x2='328.6905'
							y2='255.4724'>
							<stop offset='0' style={{stopColor: '#FFFFFF'}} />
							<stop offset='1' style={{stopColor: '#999999'}} />
						</linearGradient>
						<path
							className={SCSS.Pool}
							d='M408.06,273.28h-27.78v-17.8H234.6v104.24c45.02,0.02,86.12-7.06,122.3-17.35v-86.88h23.39v79.59
			c15.25-5.19,29.47-10.86,42.5-16.67L408.06,273.28z'
						/>
						<polygon
							points='226.74,255.47 226.74,247.62 196.58,247.62 196.58,397.62 234.6,397.62 234.6,359.71 234.6,255.47
			234.6,255.47 		'
						/>
						<rect x='356.89' y='255.47' width='23.39' height='100.84' />
						<line x1='234.6' y1='255.47' x2='356.89' y2='255.47' />
						<path d='M356.89,342.36c-36.17,10.29-77.28,17.37-122.3,17.35' />
						<path d='M380.28,273.28h27.78l14.72,45.12c-13.03,5.82-27.25,11.48-42.5,16.67' />
						<rect x='202.23' y='372.22' width='26.72' height='20.84' />
						<rect x='207.48' y='353.13' width='16.22' height='11.93' />
						<rect x='361.91' y='260.88' width='13.36' height='11.77' />
					</g>
				</g>

				<g id='LINEWORKS' className={SCSS.Lineworks}>
					<g>
						<polyline points='64.79,67.04 98.96,67.04 98.96,83.74 		' />
						<polyline points='98.02,67.04 98.02,56.8 129.36,56.8 129.36,76.49 		' />
						<line x1='112.51' y1='76.49' x2='112.51' y2='56.8' />
						<line x1='110.3' y1='247.22' x2='110.3' y2='264.23' />
						<line x1='90.59' y1='67.04' x2='90.59' y2='83.74' />
						<line x1='88.7' y1='67.04' x2='88.7' y2='83.74' />
						<line x1='86.81' y1='67.04' x2='86.81' y2='83.74' />
						<line x1='84.92' y1='67.04' x2='84.92' y2='83.74' />
						<line x1='83.03' y1='67.04' x2='83.03' y2='83.74' />
						<line x1='81.14' y1='67.04' x2='81.14' y2='83.74' />
						<line x1='79.25' y1='67.04' x2='79.25' y2='83.74' />
						<line x1='77.36' y1='67.04' x2='77.36' y2='83.74' />
						<line x1='75.47' y1='67.04' x2='75.47' y2='83.74' />
						<line x1='73.58' y1='67.04' x2='73.58' y2='83.74' />
						<line x1='90.59' y1='75.39' x2='73.58' y2='75.39' />
						<line x1='136.97' y1='247.22' x2='136.97' y2='264.23' />
						<line x1='135.08' y1='247.22' x2='135.08' y2='264.23' />
						<line x1='133.19' y1='247.22' x2='133.19' y2='264.23' />
						<line x1='131.3' y1='247.22' x2='131.3' y2='264.23' />
						<line x1='129.41' y1='247.22' x2='129.41' y2='264.23' />
						<line x1='127.52' y1='247.22' x2='127.52' y2='264.23' />
						<line x1='125.63' y1='247.22' x2='125.63' y2='264.23' />
						<line x1='123.74' y1='247.22' x2='123.74' y2='264.23' />
						<line x1='121.85' y1='247.22' x2='121.85' y2='264.23' />
						<line x1='119.96' y1='247.22' x2='119.96' y2='264.23' />
						<line x1='136.97' y1='255.73' x2='119.96' y2='255.73' />
						<polyline
							points='167.03,292.1 178.13,292.07 183.51,357.93 104.83,352.03 28.81,357.93 31.15,299.84 42.26,299.84
			'
						/>
						<rect x='26.51' y='129.1' width='15.75' height='68.99' />
						<polygon points='42.26,129.1 42.26,67.04 77.36,67.04 77.36,52.47 19.01,52.47 26.51,129.1 		' />
						<polygon points='188.38,56.8 182.75,145.79 167,145.79 167,76.49 129.36,76.49 129.36,56.8 		' />
						<rect x='167' y='145.79' width='15.75' height='57.1' />
						<polygon points='188.38,270.38 182.75,202.49 167,202.49 167,270.38 		' />
						<polygon points='42.27,198.08 42.27,276.35 19.01,276.35 26.51,198.08 		' />
					</g>
					<g>
						<polygon points='301.19,42.71 356.1,45.34 356.1,64.84 301.19,64.84 		' />
						<polyline points='301.19,64.84 301.19,42.71 243.24,39.71 243.24,102.48 263.08,102.48 		' />
						<line x1='242.29' y1='141.01' x2='258.98' y2='141.01' />
						<line x1='242.29' y1='142.9' x2='258.98' y2='142.9' />
						<line x1='242.29' y1='144.79' x2='258.98' y2='144.79' />
						<line x1='242.29' y1='146.68' x2='258.98' y2='146.68' />
						<line x1='242.29' y1='148.57' x2='258.98' y2='148.57' />
						<line x1='242.29' y1='150.46' x2='258.98' y2='150.46' />
						<line x1='242.29' y1='152.35' x2='258.98' y2='152.35' />
						<line x1='242.29' y1='154.24' x2='258.98' y2='154.24' />
						<line x1='242.29' y1='156.13' x2='258.98' y2='156.13' />
						<line x1='242.29' y1='158.02' x2='258.98' y2='158.02' />
						<line x1='250.64' y1='141.01' x2='250.64' y2='158.02' />
						<line x1='534.55' y1='87.04' x2='550.99' y2='87.04' />
						<line x1='550.93' y1='121.54' x2='534.55' y2='121.54' />
						<line x1='534.61' y1='94.56' x2='550.99' y2='94.56' />
						<line x1='534.61' y1='96.45' x2='550.99' y2='96.45' />
						<line x1='534.61' y1='98.34' x2='550.99' y2='98.34' />
						<line x1='534.61' y1='100.23' x2='550.99' y2='100.23' />
						<line x1='534.61' y1='102.12' x2='550.99' y2='102.12' />
						<line x1='534.61' y1='104.01' x2='550.99' y2='104.01' />
						<line x1='534.61' y1='105.9' x2='550.99' y2='105.9' />
						<line x1='534.61' y1='107.79' x2='550.99' y2='107.79' />
						<line x1='534.55' y1='109.68' x2='550.99' y2='109.68' />
						<line x1='534.55' y1='111.57' x2='550.99' y2='111.57' />
						<line x1='542.77' y1='94.56' x2='542.77' y2='111.57' />
						<polyline points='263.08,102.48 242.29,102.48 242.29,119.33 263.08,119.33 		' />
						<line x1='242.29' y1='132.88' x2='258.98' y2='132.88' />
						<polygon points='240.21,214.7 304.34,209.08 304.34,189.58 240.21,189.58 		' />
						<line x1='550.99' y1='87.04' x2='550.99' y2='132.88' />
						<polygon points='377.62,45.34 452.39,42.85 452.39,64.84 377.62,64.84 		' />
						<polygon points='452.39,42.85 546.89,39.71 546.89,87.04 534.55,87.04 534.61,64.84 452.39,64.84 		' />
						<rect x='304.34' y='189.58' width='82.27' height='19.5' />
						<rect x='386.62' y='189.58' width='82.27' height='19.5' />
						<polygon points='468.89,189.58 551.17,189.58 551.17,214.7 468.89,209.08 		' />
						<line x1='242.29' y1='167.05' x2='242.29' y2='132.88' />
						<rect x='551.17' y='132.88' width='56.21' height='56.7' />
						<line x1='242.29' y1='132.88' x2='242.29' y2='119.33' />
					</g>
				</g>
				<g id='UNITS'>
					<g id='LEVEL-6-LABEL'>
						<text
							transform='matrix(1 0 0 1 471.7659 329.0676)'
							className={SCSS.LevelLabel}>
							LEVEL 6
						</text>
					</g>
					<g id='LEVEL-6-BUILDING-LABEL'>
						<text
							transform='matrix(1 0 0 1 80.4454 16.9003)'
							className={SCSS.BuildingLabel}>
							ZARA 1
						</text>
						<text
							transform='matrix(1 0 0 1 386.6978 16.9003)'
							className={SCSS.BuildingLabel}>
							ZARA 2
						</text>
					</g>
					<g id='LEVEL-6-ZARA-2'>
						<g
							className={highlight('601', 'A', 'ZARA 2')}
							onClick={() =>
								openFloorplan('601', 'A', 'ZARA 2', '6', 'location-2-e')
							}
							id='UINIT-601-A'>
							<g id='LWPOLYLINE_356_'>
								<rect
									x='263.08'
									y='64.84'
									className={SCSS.Hot}
									width='38.06'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 272.3051 92.2431)'
									className={SCSS.Number}>
									601
								</text>
								<text
									transform='matrix(1 0 0 1 276.3051 112.2431)'
									className={SCSS.PlanName}>
									A
								</text>
							</g>
						</g>
						<g
							className={highlight('602', 'B2', 'ZARA 2')}
							onClick={() =>
								openFloorplan('602', 'B2', 'ZARA 2', '6', 'location-2-e')
							}
							id='UINIT-602-B2'>
							<g id='LWPOLYLINE_357_'>
								<rect
									x='301.14'
									y='64.84'
									className={SCSS.Hot}
									width='68.99'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 321.9497 92.2431)'
									className={SCSS.Number}>
									602
								</text>
								<text
									transform='matrix(1 0 0 1 324.9497 112.2431)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							className={highlight('603', 'P1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('603', 'P1', 'ZARA 2', '6', 'location-2-e')
							}
							id='UINIT-603-P1-L2'>
							<g id='LWPOLYLINE_342_'>
								<rect
									x='370.12'
									y='64.84'
									className={SCSS.Hot}
									width='82.27'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 396.6212 92.2431)'
									className={SCSS.Number}>
									603
								</text>
								<text
									transform='matrix(1 0 0 1 381.6212 112.2431)'
									className={SCSS.PlanName}>
									P1 - L2
								</text>
							</g>
						</g>
						<g
							className={highlight('604', 'P1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('604', 'P1', 'ZARA 2', '6', 'location-2-e')
							}
							id='UINIT-604-P1-L2'>
							<g id='LWPOLYLINE_343_'>
								<rect
									x='452.39'
									y='64.84'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 480.322 92.2431)'
									className={SCSS.Number}>
									604
								</text>
								<text
									transform='matrix(1 0 0 1 464.322 112.2431)'
									className={SCSS.PlanName}>
									P1 - L2
								</text>
							</g>
						</g>
						<g
							className={highlight('605', 'P2', 'ZARA 2')}
							onClick={() =>
								openFloorplan('605', 'P2', 'ZARA 2', '6', 'location-1')
							}
							id='UINIT-605-P2-L2'>
							<g id='LWPOLYLINE_352_'>
								<rect
									x='468.77'
									y='132.88'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 494.6714 159.0689)'
									className={SCSS.Number}>
									605
								</text>
								<text
									transform='matrix(1 0 0 1 479.6714 179.0689)'
									className={SCSS.PlanName}>
									P2 - L2
								</text>
							</g>
						</g>
						<g
							className={highlight('606', 'P1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('606', 'P1', 'ZARA 2', '6', 'location-2-w')
							}
							id='UINIT-606-P1-L2'>
							<g id='LWPOLYLINE_353_'>
								<rect
									x='386.56'
									y='132.88'
									className={SCSS.Hot}
									width='82.22'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 415.0314 159.0689)'
									className={SCSS.Number}>
									606
								</text>
								<text
									transform='matrix(1 0 0 1 398.0314 179.0689)'
									className={SCSS.PlanName}>
									P1 - L2
								</text>
							</g>
						</g>
						<g
							className={highlight('607', 'P1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('607', 'P1', 'ZARA 2', '6', 'location-2-w')
							}
							id='UINIT-607-P1-L2'>
							<g id='LWPOLYLINE_354_'>
								<rect
									x='304.34'
									y='132.88'
									className={SCSS.Hot}
									width='82.22'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 336.8453 159.0689)'
									className={SCSS.Number}>
									607
								</text>
								<text
									transform='matrix(1 0 0 1 315.8453 179.0689)'
									className={SCSS.PlanName}>
									P1 - L2
								</text>
							</g>
						</g>
						<g
							className={highlight('608', 'A2', 'ZARA 2')}
							onClick={() =>
								openFloorplan('608', 'A2', 'ZARA 2', '6', 'location-2-w')
							}
							id='UINIT-608-A2'>
							<g id='LWPOLYLINE_355_'>
								<polygon
									className={SCSS.Hot}
									points='304.34,189.58 242.29,189.58 242.29,167.05 258.98,167.05 258.98,132.88 304.34,132.88 				'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 270.5586 159.0689)'
									className={SCSS.Number}>
									608
								</text>
								<text
									transform='matrix(1 0 0 1 270.5586 179.0689)'
									className={SCSS.PlanName}>
									A2
								</text>
							</g>
						</g>
					</g>
					<g id='LEVEL-6-ZARA-1'>
						<g
							className={highlight('601', 'B2', 'ZARA 1')}
							onClick={() =>
								openFloorplan('601', 'B2', 'ZARA 1', '6', 'location-3-e')
							}
							id='UINIT-601-B2'>
							<g id='LWPOLYLINE_532_'>
								<rect
									x='110.31'
									y='76.49'
									className={SCSS.Hot}
									width='56.7'
									height='69.3'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 126.9471 108.3364)'
									className={SCSS.Number}>
									601
								</text>
								<text
									transform='matrix(1 0 0 1 127.9471 128.3364)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							className={highlight('602', 'A4', 'ZARA 1')}
							onClick={() =>
								openFloorplan('602', 'A4', 'ZARA 1', '6', 'location-3-s')
							}
							id='UINIT-602-A4'>
							<g id='LWPOLYLINE_533_'>
								<rect
									x='110.3'
									y='145.79'
									className={SCSS.Hot}
									width='56.7'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 127.3065 170.9193)'
									className={SCSS.Number}>
									602
								</text>
								<text
									transform='matrix(1 0 0 1 129.3065 190.9193)'
									className={SCSS.PlanName}>
									A4
								</text>
							</g>
						</g>
						<g
							className={highlight('603', 'A2', 'ZARA 1')}
							onClick={() =>
								openFloorplan('603', 'A2', 'ZARA 1', '6', 'location-3-s')
							}
							id='UINIT-603-A2'>
							<g id='LWPOLYLINE_426_'>
								<polygon
									className={SCSS.Hot}
									points='110.3,202.49 110.3,247.22 145.11,247.22 145.11,264.23 167,264.23 167,202.49 				'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 125.5785 222.5819)'
									className={SCSS.Number}>
									603
								</text>
								<text
									transform='matrix(1 0 0 1 127.5785 242.5819)'
									className={SCSS.PlanName}>
									A2
								</text>
							</g>
						</g>
						<g
							className={highlight('604', 'C1', 'ZARA 1')}
							onClick={() =>
								openFloorplan('604', 'C1', 'ZARA 1', '6', 'location-4')
							}
							id='UINIT-604-C1'>
							<g id='LWPOLYLINE_530_'>
								<polygon
									className={SCSS.Hot}
									points='38.48,280.3 38.48,297.7 42.26,297.7 42.3,329.44 167,329.44 167,264.23 98.96,264.23
					98.96,280.3 				'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 90.802 303.1484)'
									className={SCSS.Number}>
									604
								</text>
								<text
									transform='matrix(1 0 0 1 92.802 323.1484)'
									className={SCSS.PlanName}>
									C1
								</text>
							</g>
						</g>
						<g
							className={highlight('605', 'P1', 'ZARA 1')}
							onClick={() =>
								openFloorplan('605', 'P1', 'ZARA 1', '6', 'location-3-n')
							}
							id='UINIT-605-P1-L2'>
							<g id='LWPOLYLINE_358_'>
								<rect
									x='42.27'
									y='198.08'
									className={SCSS.Hot}
									width='56.7'
									height='82.21'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 58.2668 225.0046)'
									className={SCSS.Number}>
									605
								</text>
								<text
									transform='matrix(1 0 0 1 61.2668 245.0046)'
									className={SCSS.PlanName}>
									P1
								</text>
								<text
									transform='matrix(1 0 0 1 55.2668 262.0046)'
									className={SCSS.PlanName}>
									- L2
								</text>
							</g>
						</g>
						<g
							className={highlight('606', 'B2', 'ZARA 1')}
							onClick={() =>
								openFloorplan('606', 'B2', 'ZARA 1', '6', 'location-3-n')
							}
							id='UINIT-606-B2'>
							<g id='LWPOLYLINE_531_'>
								<rect
									x='42.27'
									y='129.1'
									className={SCSS.Hot}
									width='56.7'
									height='68.98'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 57.9075 161.5977)'
									className={SCSS.Number}>
									606
								</text>
								<text
									transform='matrix(1 0 0 1 59.9075 181.5977)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							className={highlight('607', 'A2', 'ZARA 1')}
							onClick={() =>
								openFloorplan('607', 'A2', 'ZARA 1', '6', 'location-3-e')
							}
							id='UINIT-607-A2'>
							<g id='LWPOLYLINE_359_'>
								<polygon
									className={SCSS.Hot}
									points='98.96,129.1 98.96,83.74 64.79,83.74 64.79,67.04 42.26,67.04 42.26,129.1 				'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 57.5388 104.6339)'
									className={SCSS.Number}>
									607
								</text>
								<text
									transform='matrix(1 0 0 1 59.5388 124.6339)'
									className={SCSS.PlanName}>
									A2
								</text>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</div>
	);
};

export default Level6;
