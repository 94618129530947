import React from 'react';
import Pool from './Pool';
import BuildingLabel from './BuildingLabel';
import LakeLabel from './LakeLabel';
import SCSS from './Keys.module.scss';

type Props = {
	highlight: (plan: string) => string;
	openFloorplan: (
		room: string,
		plan: string,
		building: string,
		level: string,
		viewLocation: string
	) => void;
};

const Level2 = (props: Props) => {
	const {highlight, openFloorplan} = props;
	return (
		<div className={SCSS.Keyplate}>
			<svg viewBox='0 0 750 400'>
				<Pool />
				<g id='LINEWORKS' className={SCSS.Lineworks}>
					<g>
						<polyline points='108.3,66.73 142.48,66.73 142.48,83.43 		' />
						<polyline points='141.54,66.73 141.54,56.5 172.88,56.5 172.88,76.18 		' />
						<line x1='156.03' y1='76.18' x2='156.03' y2='56.5' />
						<line x1='153.82' y1='246.91' x2='153.82' y2='263.92' />
						<polyline
							points='210.54,306.77 221.65,306.74 221.27,354.3 148.35,351.72 75.29,354.66 74.67,299.53 85.78,299.51
			'
						/>
						<line x1='134.1' y1='66.73' x2='134.1' y2='83.43' />
						<line x1='132.21' y1='66.73' x2='132.21' y2='83.43' />
						<line x1='130.32' y1='66.73' x2='130.32' y2='83.43' />
						<line x1='128.43' y1='66.73' x2='128.43' y2='83.43' />
						<line x1='126.54' y1='66.73' x2='126.54' y2='83.43' />
						<line x1='124.65' y1='66.73' x2='124.65' y2='83.43' />
						<line x1='122.76' y1='66.73' x2='122.76' y2='83.43' />
						<line x1='120.87' y1='66.73' x2='120.87' y2='83.43' />
						<line x1='118.98' y1='66.73' x2='118.98' y2='83.43' />
						<line x1='117.09' y1='66.73' x2='117.09' y2='83.43' />
						<line x1='134.1' y1='75.08' x2='117.09' y2='75.08' />
						<line x1='180.49' y1='246.91' x2='180.49' y2='263.92' />
						<line x1='178.6' y1='246.91' x2='178.6' y2='263.92' />
						<line x1='176.71' y1='246.91' x2='176.71' y2='263.92' />
						<line x1='174.82' y1='246.91' x2='174.82' y2='263.92' />
						<line x1='172.93' y1='246.91' x2='172.93' y2='263.92' />
						<line x1='171.04' y1='246.91' x2='171.04' y2='263.92' />
						<line x1='169.15' y1='246.91' x2='169.15' y2='263.92' />
						<line x1='167.26' y1='246.91' x2='167.26' y2='263.92' />
						<line x1='165.37' y1='246.91' x2='165.37' y2='263.92' />
						<line x1='163.48' y1='246.91' x2='163.48' y2='263.92' />
						<line x1='180.49' y1='255.42' x2='163.48' y2='255.42' />
						<rect x='70.03' y='128.79' width='15.75' height='68.99' />
						<polygon points='85.78,128.79 85.78,66.73 120.87,66.73 120.87,52.16 66.28,52.16 70.03,128.79 		' />
						<polygon points='228.15,56.5 226.27,145.48 210.52,145.48 210.52,76.18 172.88,76.18 172.88,56.5 		' />
						<polygon points='228.14,270.07 226.27,202.18 210.52,202.18 210.52,270.07 		' />
						<polygon points='85.79,197.77 85.78,276.04 66.28,276.04 70.02,197.77 		' />
						<rect x='210.52' y='145.48' width='15.75' height='56.7' />
					</g>
					<g>
						<polyline points='578.07,86.74 594.45,86.74 594.45,106.42 		' />
						<line x1='594.45' y1='121.23' x2='578.07' y2='121.23' />
						<rect x='347.77' y='189.27' width='82.24' height='19.5' />
						<rect x='430.02' y='189.27' width='82.24' height='19.5' />
						<rect x='512.23' y='189.27' width='82.24' height='19.5' />
						<rect x='421.14' y='48.78' width='74.74' height='15.75' />
						<polygon points='495.88,48.78 590.41,48.78 590.41,86.74 578.07,86.74 578.07,64.53 495.88,64.53 		' />
						<rect x='344.71' y='48.78' width='54.9' height='15.75' />
						<polyline points='306.6,102.17 286.75,102.17 286.75,119.02 306.6,119.02 		' />
						<polyline points='286.75,119.02 282.03,119.02 282.03,102.17 286.75,102.17 		' />
						<line x1='285.81' y1='166.75' x2='285.81' y2='119.02' />
						<line x1='285.81' y1='132.57' x2='302.5' y2='132.57' />
						<rect x='282.03' y='189.27' width='65.84' height='19.5' />
						<polyline points='344.71,64.53 344.71,48.78 286.75,48.78 286.75,102.17 306.6,102.17 		' />
						<line x1='285.81' y1='140.7' x2='302.5' y2='140.7' />
						<line x1='285.81' y1='142.59' x2='302.5' y2='142.59' />
						<line x1='285.81' y1='144.48' x2='302.5' y2='144.48' />
						<line x1='285.81' y1='146.37' x2='302.5' y2='146.37' />
						<line x1='285.81' y1='148.26' x2='302.5' y2='148.26' />
						<line x1='285.81' y1='150.15' x2='302.5' y2='150.15' />
						<line x1='285.81' y1='152.04' x2='302.5' y2='152.04' />
						<line x1='285.81' y1='153.93' x2='302.5' y2='153.93' />
						<line x1='285.81' y1='155.82' x2='302.5' y2='155.82' />
						<line x1='285.81' y1='157.71' x2='302.5' y2='157.71' />
						<line x1='294.15' y1='140.7' x2='294.15' y2='157.71' />
						<line x1='578.13' y1='96.14' x2='594.51' y2='96.14' />
						<line x1='578.13' y1='98.03' x2='594.51' y2='98.03' />
						<line x1='578.13' y1='99.92' x2='594.51' y2='99.92' />
						<line x1='578.13' y1='101.81' x2='594.51' y2='101.81' />
						<line x1='578.13' y1='103.7' x2='594.51' y2='103.7' />
						<line x1='578.13' y1='105.59' x2='594.51' y2='105.59' />
						<line x1='578.13' y1='107.48' x2='594.51' y2='107.48' />
						<line x1='578.13' y1='109.37' x2='594.51' y2='109.37' />
						<line x1='578.13' y1='111.26' x2='594.51' y2='111.26' />
						<line x1='578.13' y1='113.15' x2='594.51' y2='113.15' />
						<line x1='586.32' y1='96.14' x2='586.32' y2='113.15' />
						<polygon
							points='678.29,95.17 678.29,200.52 618.04,200.52 618.04,189.27 650.83,189.27 650.83,106.42 618.04,106.42
			618.04,95.17 		'
						/>
						<rect x='282.03' y='172.42' width='3.78' height='16.85' />
					</g>
				</g>
				<g id='UNITS'>
					<g id='LEVEL-2-LABEL'>
						<text
							transform='matrix(1 0 0 1 85.4619 393.0516)'
							className={SCSS.LevelLabel}>
							LEVEL 2
						</text>
					</g>
					<BuildingLabel />
					<g id='LEVEL-2-ZARA-2_2_'>
						<g
							id='UINIT-201-A_2_'
							onClick={() =>
								openFloorplan('201', 'A', 'ZARA 2', '2', 'location-2-e')
							}
							className={highlight('A')}>
							<g id='LWPOLYLINE_55_'>
								<rect
									x='306.6'
									y='64.53'
									className={SCSS.Hot}
									width='38.06'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 314.0731 90.5999)'
									className={SCSS.Number}>
									201
								</text>
								<text
									transform='matrix(1 0 0 1 319.3051 109.2431)'
									className={SCSS.PlanName}>
									A
								</text>
							</g>
						</g>
						<g
							id='UINIT-202-B2_2_'
							onClick={() =>
								openFloorplan('202', 'B2', 'ZARA 2', '2', 'location-2-e')
							}
							className={highlight('B2')}>
							<g id='LWPOLYLINE_54_'>
								<rect
									x='344.65'
									y='64.53'
									className={SCSS.Hot}
									width='68.99'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 366.104 90.5999)'
									className={SCSS.Number}>
									202
								</text>
								<text
									transform='matrix(1 0 0 1 367.9497 109.2431)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							id='UINIT-203-B1_2_'
							onClick={() =>
								openFloorplan('203', 'B1', 'ZARA 2', '2', 'location-2-e')
							}
							className={highlight('B1')}>
							<g id='LWPOLYLINE_53_'>
								<rect
									x='413.64'
									y='64.53'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 441.6326 90.5999)'
									className={SCSS.Number}>
									203
								</text>
								<text
									transform='matrix(1 0 0 1 445.2483 109.2431)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							id='UINIT-204-B1_2_'
							onClick={() =>
								openFloorplan('204', 'B1', 'ZARA 2', '2', 'location-2-e')
							}
							className={highlight('B1')}>
							<g id='LWPOLYLINE_52_'>
								<rect
									x='495.85'
									y='64.53'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 523.8689 90.5999)'
									className={SCSS.Number}>
									204
								</text>
								<text
									transform='matrix(1 0 0 1 527.4636 109.2431)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							id='UINIT-205-B1_4_'
							onClick={() =>
								openFloorplan('205', 'B1', 'ZARA 2', '2', 'location-1')
							}
							className={highlight('B1')}>
							<g id='LWPOLYLINE_51_'>
								<rect
									x='594.45'
									y='106.42'
									className={SCSS.Hot}
									width='56.38'
									height='82.85'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 609.4651 143.6407)'
									className={SCSS.Number}>
									205
								</text>
								<text
									transform='matrix(1 0 0 1 613.1438 162.2838)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							id='UINIT-206-B1_2_'
							onClick={() =>
								openFloorplan('206', 'B1', 'ZARA 2', '2', 'location-2-w')
							}
							className={highlight('B1')}>
							<g id='LWPOLYLINE_50_'>
								<rect
									x='512.23'
									y='132.57'
									className={SCSS.Hot}
									width='82.22'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 540.1024 156.4257)'
									className={SCSS.Number}>
									206
								</text>
								<text
									transform='matrix(1 0 0 1 543.8583 175.0689)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							id='UINIT-207-B1_2_'
							onClick={() =>
								openFloorplan('207', 'B1', 'ZARA 2', '2', 'location-2-w')
							}
							className={highlight('B1')}>
							<g id='LWPOLYLINE_49_'>
								<rect
									x='430.02'
									y='132.57'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 458.3633 156.4257)'
									className={SCSS.Number}>
									207
								</text>
								<text
									transform='matrix(1 0 0 1 461.6431 175.0689)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							id='UINIT-208-B1_2_'
							onClick={() =>
								openFloorplan('208', 'B1', 'ZARA 2', '2', 'location-2-w')
							}
							className={highlight('B1')}>
							<g id='LWPOLYLINE_48_'>
								<rect
									x='347.8'
									y='132.57'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 377.4099 156.4257)'
									className={SCSS.Number}>
									208
								</text>
								<text
									transform='matrix(1 0 0 1 380.9558 175.0689)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							id='UINIT-209-A2_2_'
							onClick={() =>
								openFloorplan('209', 'A2', 'ZARA 2', '2', 'location-2-w')
							}
							className={highlight('A2')}>
							<g id='LWPOLYLINE_47_'>
								<polygon
									className={SCSS.Hot}
									points='347.86,189.27 285.81,189.27 285.81,166.75 302.5,166.75 302.5,132.57 347.8,132.57 				'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 311.8994 156.4257)'
									className={SCSS.Number}>
									209
								</text>
								<text
									transform='matrix(1 0 0 1 313.5586 175.0689)'
									className={SCSS.PlanName}>
									A2
								</text>
							</g>
						</g>
					</g>
					<g id='LEVEL-2-ZARA-1_2_'>
						<g
							id='UINIT-201-B2_2_'
							onClick={() =>
								openFloorplan('201', 'B2', 'ZARA 1', '2', 'location-3-e')
							}
							className={highlight('B2')}>
							<g id='LWPOLYLINE_46_'>
								<rect
									x='153.82'
									y='76.18'
									className={SCSS.Hot}
									width='56.7'
									height='69.3'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 170.6205 105.6932)'
									className={SCSS.Number}>
									201
								</text>
								<text
									transform='matrix(1 0 0 1 170.9471 124.3364)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							id='UINIT-202-A4_2_'
							onClick={() =>
								openFloorplan('202', 'A4', 'ZARA 1', '2', 'location-3-s')
							}
							className={highlight('A4')}>
							<g id='LWPOLYLINE_45_'>
								<rect
									x='153.82'
									y='145.48'
									className={SCSS.Hot}
									width='56.7'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 169.1014 168.6725)'
									className={SCSS.Number}>
									202
								</text>
								<text
									transform='matrix(1 0 0 1 170.3265 187.3157)'
									className={SCSS.PlanName}>
									A4
								</text>
							</g>
						</g>
						<g
							id='UINIT-203-A2_2_'
							onClick={() =>
								openFloorplan('203', 'A2', 'ZARA 1', '2', 'location-3-s')
							}
							className={highlight('A2')}>
							<g id='LWPOLYLINE_44_'>
								<polygon
									className={SCSS.Hot}
									points='153.82,202.18 153.82,246.91 188.63,246.91 188.63,263.92 210.52,263.92 210.52,202.18 				'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 169.0594 219.9387)'
									className={SCSS.Number}>
									203
								</text>
								<text
									transform='matrix(1 0 0 1 170.5785 238.5819)'
									className={SCSS.PlanName}>
									A2
								</text>
							</g>
						</g>
						<g
							id='UINIT-204-C1_2_'
							onClick={() =>
								openFloorplan('204', 'C1', 'ZARA 1', '2', 'location-4')
							}
							className={highlight('C1')}>
							<g id='LWPOLYLINE_43_'>
								<polygon
									className={SCSS.Hot}
									points='82,279.99 82,297.39 85.78,297.39 85.82,329.13 210.52,329.13 210.52,263.92 142.48,263.92
					142.48,279.99 				'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 133.1702 300.5052)'
									className={SCSS.Number}>
									204
								</text>
								<text
									transform='matrix(1 0 0 1 135.802 319.1484)'
									className={SCSS.PlanName}>
									C1
								</text>
							</g>
						</g>
						<g
							id='UINIT-205-B1_1_'
							onClick={() =>
								openFloorplan('205', 'B1', 'ZARA 1', '2', 'location-3-n')
							}
							className={highlight('B1')}>
							<g id='LWPOLYLINE_42_'>
								<rect
									x='85.78'
									y='197.77'
									className={SCSS.Hot}
									width='56.7'
									height='82.21'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 100.9568 235.2783)'
									className={SCSS.Number}>
									205
								</text>
								<text
									transform='matrix(1 0 0 1 104.6355 253.9214)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							id='UINIT-206-B2_2_'
							onClick={() =>
								openFloorplan('206', 'B2', 'ZARA 1', '2', 'location-3-n')
							}
							className={highlight('B2')}>
							<g id='LWPOLYLINE_41_'>
								<rect
									x='85.78'
									y='128.79'
									className={SCSS.Hot}
									width='56.7'
									height='68.98'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 100.8796 158.9545)'
									className={SCSS.Number}>
									206
								</text>
								<text
									transform='matrix(1 0 0 1 102.9075 177.5977)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							id='UINIT-207-A2_2_'
							onClick={() =>
								openFloorplan('207', 'A2', 'ZARA 1', '2', 'location-3-e')
							}
							className={highlight('A2')}>
							<g id='LWPOLYLINE_40_'>
								<polygon
									className={SCSS.Hot}
									points='142.48,128.79 142.48,83.43 108.3,83.43 108.3,66.73 85.78,66.73 85.78,128.79 				'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 101.3557 101.9907)'
									className={SCSS.Number}>
									207
								</text>
								<text
									transform='matrix(1 0 0 1 102.5388 120.6339)'
									className={SCSS.PlanName}>
									A2
								</text>
							</g>
						</g>
					</g>
				</g>
				<LakeLabel />
			</svg>
		</div>
	);
};

export default Level2;
