import IUnit from './IUnit';
import location3_E from '../views/location3_E';
import location3_N from '../views/location3_N';
import location3_S from '../views/location3_S';
// import location3_360 from '../views/location3_360';
import location4 from '../views/location4';

const Units: Array<IUnit> = [
	/**
	 * LEVEL: 2
	 */
	{
		unit: '201',
		plan: 'B2',
		building: 'ZARA 1',
		level: '2',
		view: location3_E.floor2,
		// view360: location3_360.floor2,
	},
	{
		unit: '202',
		plan: 'A4',
		building: 'ZARA 1',
		level: '2',
		view: location3_S.floor2,
		// view360: location3_360.floor2,
	},
	{
		unit: '203',
		plan: 'A2',
		building: 'ZARA 1',
		level: '2',
		view: location3_S.floor2,
		// view360: location3_360.floor2,
	},
	{
		unit: '204',
		plan: 'C1',
		building: 'ZARA 1',
		level: '2',
		view: location4.floor2,
		// view360: location3_360.floor2,
	},
	{
		unit: '205',
		plan: 'B1',
		building: 'ZARA 1',
		level: '2',
		view: location3_N.floor2,
		// view360: location3_360.floor2,
	},
	{
		unit: '206',
		plan: 'B2',
		building: 'ZARA 1',
		level: '2',
		view: location3_N.floor2,
		// view360: location3_360.floor2,
	},
	{
		unit: '207',
		plan: 'A2',
		building: 'ZARA 1',
		level: '2',
		view: location3_E.floor2,
		// view360: location3_360.floor2,
	},
	/**
	 * LEVEL: 3
	 */
	{
		unit: '301',
		plan: 'B2',
		building: 'ZARA 1',
		level: '3',
		view: location3_E.floor3,
		// view360: location3_360.floor3,
	},
	{
		unit: '302',
		plan: 'A4',
		building: 'ZARA 1',
		level: '3',
		view: location3_S.floor3,
		// view360: location3_360.floor3,
	},
	{
		unit: '303',
		plan: 'A2',
		building: 'ZARA 1',
		level: '3',
		view: location3_S.floor3,
		// view360: location3_360.floor3,
	},
	{
		unit: '304',
		plan: 'C1',
		building: 'ZARA 1',
		level: '3',
		view: location4.floor3,
		// view360: location3_360.floor3,
	},
	{
		unit: '305',
		plan: 'B1',
		building: 'ZARA 1',
		level: '3',
		view: location3_N.floor3,
		// view360: location3_360.floor3,
	},
	{
		unit: '306',
		plan: 'B2',
		building: 'ZARA 1',
		level: '3',
		view: location3_N.floor3,
		// view360: location3_360.floor3,
	},
	{
		unit: '307',
		plan: 'A2',
		building: 'ZARA 1',
		level: '3',
		view: location3_E.floor3,
		// view360: location3_360.floor3,
	},
	/**
	 * LEVEL: 4
	 */
	{
		unit: '401',
		plan: 'B2',
		building: 'ZARA 1',
		level: '4',
		view: location3_E.floor4,
		// view360: location3_360.floor4,
	},
	{
		unit: '402',
		plan: 'A4',
		building: 'ZARA 1',
		level: '4',
		view: location3_S.floor4,
		// view360: location3_360.floor4,
	},
	{
		unit: '403',
		plan: 'A2',
		building: 'ZARA 1',
		level: '4',
		view: location3_S.floor4,
		// view360: location3_360.floor4,
	},
	{
		unit: '404',
		plan: 'C1',
		building: 'ZARA 1',
		level: '4',
		view: location4.floor4,
		// view360: location3_360.floor4,
	},
	{
		unit: '405',
		plan: 'B1',
		building: 'ZARA 1',
		level: '4',
		view: location3_N.floor4,
		// view360: location3_360.floor4,
	},
	{
		unit: '406',
		plan: 'B2',
		building: 'ZARA 1',
		level: '4',
		view: location3_N.floor4,
		// view360: location3_360.floor4,
	},
	{
		unit: '407',
		plan: 'A2',
		building: 'ZARA 1',
		level: '4',
		view: location3_E.floor4,
		// view360: location3_360.floor4,
	},
	/**
	 * LEVEL: 5
	 */
	{
		unit: '501',
		plan: 'B2',
		building: 'ZARA 1',
		level: '5',
		view: location3_E.floor5,
		// view360: location3_360.floor5,
	},
	{
		unit: '502',
		plan: 'A1',
		building: 'ZARA 1',
		level: '5',
		view: location3_S.floor5,
		// view360: location3_360.floor5,
	},
	{
		unit: '503',
		plan: 'A2',
		building: 'ZARA 1',
		level: '5',
		view: location3_S.floor5,
		// view360: location3_360.floor5,
	},
	{
		unit: '504',
		plan: 'C1',
		building: 'ZARA 1',
		level: '5',
		view: location4.floor5,
		// view360: location3_360.floor5,
	},
	{
		unit: '605',
		plan: 'P1',
		building: 'ZARA 1',
		level: '5',
		view: location3_N.floor5,
		// view360: location3_360.floor5,
	},
	{
		unit: '505',
		plan: 'A1',
		building: 'ZARA 1',
		level: '5',
		view: location3_N.floor5,
		// view360: location3_360.floor5,
	},
	{
		unit: '506',
		plan: 'B2',
		building: 'ZARA 1',
		level: '5',
		view: location3_N.floor5,
		// view360: location3_360.floor5,
	},
	{
		unit: '507',
		plan: 'A2',
		building: 'ZARA 1',
		level: '5',
		view: location3_E.floor5,
		// view360: location3_360.floor5,
	},
	/**
	 * LEVEL: 6
	 */
	{
		unit: '601',
		plan: 'B2',
		building: 'ZARA 1',
		level: '6',
		view: location3_E.floor6,
		// view360: location3_360.floor6,
	},
	{
		unit: '602',
		plan: 'A4',
		building: 'ZARA 1',
		level: '6',
		view: location3_S.floor6,
		// view360: location3_360.floor6,
	},
	{
		unit: '603',
		plan: 'A2',
		building: 'ZARA 1',
		level: '6',
		view: location3_S.floor6,
		// view360: location3_360.floor6,
	},
	{
		unit: '604',
		plan: 'C1',
		building: 'ZARA 1',
		level: '6',
		view: location4.floor6,
		// view360: location3_360.floor6,
	},
	{
		unit: '605',
		plan: 'P1',
		building: 'ZARA 1',
		level: '6',
		view: location3_N.floor6,
		// view360: location3_360.floor6,
	},
	{
		unit: '606',
		plan: 'B2',
		building: 'ZARA 1',
		level: '6',
		view: location3_N.floor6,
		// view360: location3_360.floor6,
	},
	{
		unit: '607',
		plan: 'A2',
		building: 'ZARA 1',
		level: '6',
		view: location3_E.floor6,
		// view360: location3_360.floor6,
	},
];

export default Units;
