import React from 'react';
import {useSelector} from 'react-redux';
import Keys from './Keys';
import Header from './FloorplanHeader';
import SCSS from './Floorplans.module.scss';
import {selectCurrentPlan} from '../../../redux/moduleSlice';
import PLANS, {IFloorplan} from '../../../constants/floorplans';

const _ = require('lodash');

type Props = {
	backToKeyplate: () => void;
	gotoView: () => void;
	gotoGallery: () => void;
	openFloorplan: (
		room: string,
		plan: string,
		building: string,
		level: string,
		viewLocation: string
	) => void;
};

const Floorplans = (props: Props) => {
	const {backToKeyplate, gotoView, gotoGallery, openFloorplan} = props;
	const currentPlan = useSelector(selectCurrentPlan);
	const plan: IFloorplan = _.find(PLANS, ['name', currentPlan]);

	return (
		<div className={SCSS.Floorplans}>
			<div className={SCSS.Left}>
				{/* {plan.draft && <div className={SCSS.Draft}>Draft</div>} */}
				<div className={SCSS.Padding}>
					<Header plan={plan} gotoView={gotoView} gotoGallery={gotoGallery} />
					<div className={SCSS.Plan}>
						<img src={plan.src} alt={`Plan ${plan.name}`} />
					</div>
				</div>
				<div className={SCSS.BackButton} onClick={() => backToKeyplate()}>
					Back to
					<br />
					Plan Selector
				</div>
			</div>
			<div className={SCSS.Right}>
				<Keys openFloorplan={openFloorplan} />
			</div>
		</div>
	);
};

export default Floorplans;
