import React from 'react';
import {useSelector} from 'react-redux';
import Level1 from './Level1';
import Level2 from './Level2';
import Level3 from './Level3';
import Level4 from './Level4';
import Level5 from './Level5';
import Level6 from './Level6';
import SCSS from './Keys.module.scss';
import {selectCurrentPlan} from '../../../../redux/moduleSlice';

const classnames = require('classNames');

type Props = {
	openFloorplan: (
		room: string,
		plan: string,
		building: string,
		level: string,
		viewLocation: string
	) => void;
};

const Keys = (props: Props) => {
	const {openFloorplan} = props;
	const currentPlan = useSelector(selectCurrentPlan);

	const highlight = (plan: string) => {
		const unitClasses = [
			SCSS.Unit,
			{
				[SCSS.Highlight]: plan === currentPlan,
			},
		];
		return classnames(unitClasses);
	};

	return (
		<div className={SCSS.Keys}>
			<div className={SCSS.Grid}>
				<Level4 highlight={highlight} openFloorplan={openFloorplan} />
				<Level5 highlight={highlight} openFloorplan={openFloorplan} />
				<Level6 highlight={highlight} openFloorplan={openFloorplan} />
				<Level1 highlight={highlight} openFloorplan={openFloorplan} />
				<Level2 highlight={highlight} openFloorplan={openFloorplan} />
				<Level3 highlight={highlight} openFloorplan={openFloorplan} />
			</div>
			<div className={SCSS.Compass} />
		</div>
	);
};

export default Keys;
