import React from 'react';
import {useSelector} from 'react-redux';
import SCSS from './FloorplanHeader.module.scss';
import {selectCurrent} from '../../../../redux/moduleSlice';
import {IFloorplan} from '../../../../constants/floorplans';

type Props = {
	gotoView: () => void;
	plan: IFloorplan;
};

const _ = require('lodash');
const classnames = require('classnames');

const ViewButton = (props: Props) => {
	const {gotoView, plan} = props;

	const current = useSelector(selectCurrent);
	const {room, building, level} = current;

	const viewButtonClasses = [SCSS.Button];

	const clickViewButton = () => {
		gotoView();
	};

	const zara1Levels = plan.levels.zara1;
	const zara2Levels = plan.levels.zara2;

	if (_.head(zara1Levels) < _.last(zara1Levels)) {
		_.reverse(zara1Levels);
	}

	if (_.head(zara2Levels) < _.last(zara2Levels)) {
		_.reverse(zara2Levels);
	}

	const hasViewButton = (): boolean => {
		const z1 = ['202', '302', '402', '502', '602'];
		const z2 = ['205'];
		const r = room || '';
		if (level === '1') {
			return false;
		}
		if (building === 'ZARA 1' && z1.includes(r)) {
			return false;
		}
		if (building === 'ZARA 2' && z2.includes(r)) {
			return false;
		}
		return true;
	};

	return hasViewButton() ? (
		<div className={classnames(viewButtonClasses)}>
			<i className={SCSS.IconView} />
			<div
				onClick={clickViewButton}>{`The View | ${building} Home ${room}`}</div>
		</div>
	) : null;
};

export default ViewButton;
