import React from 'react';
import SCSS from './Keys.module.scss';

type Props = {
	openFloorplan: (
		room: string,
		plan: string,
		building: string,
		level: string,
		location: string
	) => void;
	highlight: (r: string, p: string, b: string) => string;
};

const Level3 = (props: Props) => {
	const {openFloorplan, highlight} = props;
	return (
		<div className={SCSS.Keyplate}>
			<svg viewBox='0 0 650 400'>
				<g id='POOL' className={SCSS.Lineworks}>
					<g>
						<linearGradient
							id='SVGID_1_'
							gradientUnits='userSpaceOnUse'
							x1='328.6905'
							y1='359.7091'
							x2='328.6905'
							y2='255.4724'>
							<stop offset='0' style={{stopColor: '#FFFFFF'}} />
							<stop offset='1' style={{stopColor: '#999999'}} />
						</linearGradient>
						<path
							className={SCSS.Pool}
							d='M408.06,273.28h-27.78v-17.8H234.6v104.24c45.02,0.02,86.12-7.06,122.3-17.35v-86.88h23.39v79.59
			c15.25-5.19,29.47-10.86,42.5-16.67L408.06,273.28z'
						/>
						<polygon
							points='226.74,255.47 226.74,247.62 196.58,247.62 196.58,397.62 234.6,397.62 234.6,359.71 234.6,255.47
			234.6,255.47 		'
						/>
						<rect x='356.89' y='255.47' width='23.39' height='100.84' />
						<line x1='234.6' y1='255.47' x2='356.89' y2='255.47' />
						<path d='M356.89,342.36c-36.17,10.29-77.28,17.37-122.3,17.35' />
						<path d='M380.28,273.28h27.78l14.72,45.12c-13.03,5.82-27.25,11.48-42.5,16.67' />
						<rect x='202.23' y='372.22' width='26.72' height='20.84' />
						<rect x='207.48' y='353.13' width='16.22' height='11.93' />
						<rect x='361.91' y='260.88' width='13.36' height='11.77' />
					</g>
				</g>

				<g id='LINEWORKS' className={SCSS.Lineworks}>
					<g>
						<polyline points='65.16,64.92 99.33,64.92 99.33,81.61 		' />
						<polyline points='98.39,64.92 98.39,54.68 129.73,54.68 129.73,74.37 		' />
						<line x1='112.88' y1='74.37' x2='112.88' y2='54.68' />
						<line x1='110.67' y1='245.1' x2='110.67' y2='262.11' />
						<polyline
							points='167.4,304.95 178.5,304.92 178.13,352.49 105.2,349.91 32.15,352.84 31.52,297.72 42.63,297.69
			'
						/>
						<line x1='90.95' y1='64.92' x2='90.95' y2='81.61' />
						<line x1='89.06' y1='64.92' x2='89.06' y2='81.61' />
						<line x1='87.17' y1='64.92' x2='87.17' y2='81.61' />
						<line x1='85.28' y1='64.92' x2='85.28' y2='81.61' />
						<line x1='83.39' y1='64.92' x2='83.39' y2='81.61' />
						<line x1='81.5' y1='64.92' x2='81.5' y2='81.61' />
						<line x1='79.61' y1='64.92' x2='79.61' y2='81.61' />
						<line x1='77.72' y1='64.92' x2='77.72' y2='81.61' />
						<line x1='75.83' y1='64.92' x2='75.83' y2='81.61' />
						<line x1='73.94' y1='64.92' x2='73.94' y2='81.61' />
						<line x1='90.95' y1='73.27' x2='73.94' y2='73.27' />
						<line x1='137.34' y1='245.1' x2='137.34' y2='262.11' />
						<line x1='135.45' y1='245.1' x2='135.45' y2='262.11' />
						<line x1='133.56' y1='245.1' x2='133.56' y2='262.11' />
						<line x1='131.67' y1='245.1' x2='131.67' y2='262.11' />
						<line x1='129.78' y1='245.1' x2='129.78' y2='262.11' />
						<line x1='127.89' y1='245.1' x2='127.89' y2='262.11' />
						<line x1='126' y1='245.1' x2='126' y2='262.11' />
						<line x1='124.11' y1='245.1' x2='124.11' y2='262.11' />
						<line x1='122.22' y1='245.1' x2='122.22' y2='262.11' />
						<line x1='120.33' y1='245.1' x2='120.33' y2='262.11' />
						<line x1='137.34' y1='253.6' x2='120.33' y2='253.6' />
						<rect x='26.88' y='126.97' width='15.75' height='68.99' />
						<polygon points='42.63,126.97 42.63,64.92 77.72,64.92 77.72,50.35 23.13,50.35 26.88,126.97 		' />
						<polygon points='185,54.68 183.12,143.67 167.37,143.67 167.37,74.37 129.73,74.37 129.73,54.68 		' />
						<polygon points='185,268.26 183.12,200.37 167.37,200.37 167.37,268.26 		' />
						<polygon points='42.64,195.96 42.63,274.23 23.13,274.23 26.88,195.96 		' />
						<rect x='167.37' y='143.67' width='15.75' height='56.7' />
					</g>
					<g>
						<polyline points='534.92,84.92 551.3,84.92 551.3,104.61 		' />
						<line x1='551.3' y1='119.41' x2='534.92' y2='119.41' />
						<rect x='304.62' y='187.45' width='82.24' height='19.5' />
						<rect x='386.87' y='187.45' width='82.24' height='19.5' />
						<rect x='469.08' y='187.45' width='82.24' height='19.5' />
						<rect x='377.99' y='46.96' width='74.74' height='15.75' />
						<polygon points='452.73,46.96 547.26,46.96 547.26,84.92 534.92,84.92 534.92,62.71 452.73,62.71 		' />
						<rect x='301.56' y='46.96' width='54.9' height='15.75' />
						<polyline points='263.45,100.36 243.6,100.36 243.6,117.21 263.45,117.21 		' />
						<polyline points='243.6,117.21 238.88,117.21 238.88,100.36 243.6,100.36 		' />
						<line x1='242.66' y1='164.93' x2='242.66' y2='117.21' />
						<line x1='242.66' y1='130.75' x2='259.35' y2='130.75' />
						<rect x='238.88' y='187.45' width='65.84' height='19.5' />
						<polyline points='301.56,62.71 301.56,46.96 243.6,46.96 243.6,100.36 263.45,100.36 		' />
						<line x1='242.66' y1='138.89' x2='259.35' y2='138.89' />
						<line x1='242.66' y1='140.78' x2='259.35' y2='140.78' />
						<line x1='242.66' y1='142.67' x2='259.35' y2='142.67' />
						<line x1='242.66' y1='144.56' x2='259.35' y2='144.56' />
						<line x1='242.66' y1='146.45' x2='259.35' y2='146.45' />
						<line x1='242.66' y1='148.34' x2='259.35' y2='148.34' />
						<line x1='242.66' y1='150.23' x2='259.35' y2='150.23' />
						<line x1='242.66' y1='152.12' x2='259.35' y2='152.12' />
						<line x1='242.66' y1='154.01' x2='259.35' y2='154.01' />
						<line x1='242.66' y1='155.9' x2='259.35' y2='155.9' />
						<line x1='251.01' y1='138.89' x2='251.01' y2='155.9' />
						<line x1='534.98' y1='94.33' x2='551.36' y2='94.33' />
						<line x1='534.98' y1='96.22' x2='551.36' y2='96.22' />
						<line x1='534.98' y1='98.11' x2='551.36' y2='98.11' />
						<line x1='534.98' y1='100' x2='551.36' y2='100' />
						<line x1='534.98' y1='101.89' x2='551.36' y2='101.89' />
						<line x1='534.98' y1='103.78' x2='551.36' y2='103.78' />
						<line x1='534.98' y1='105.67' x2='551.36' y2='105.67' />
						<line x1='534.98' y1='107.56' x2='551.36' y2='107.56' />
						<line x1='534.98' y1='109.45' x2='551.36' y2='109.45' />
						<line x1='534.98' y1='111.34' x2='551.36' y2='111.34' />
						<line x1='543.17' y1='94.33' x2='543.17' y2='111.34' />
						<polygon
							points='635.14,93.36 635.14,198.7 574.9,198.7 574.9,187.45 607.68,187.45 607.68,104.61 574.9,104.61
			574.9,93.36 		'
						/>
						<rect x='238.88' y='170.6' width='3.78' height='16.85' />
					</g>
				</g>
				<g id='UNITS'>
					<g id='LEVEL-3-LABEL'>
						<text
							transform='matrix(1 0 0 1 471.7656 326.9207)'
							className={SCSS.LevelLabel}>
							LEVEL 3
						</text>
					</g>
					<g id='LEVEL-3-BUILDING-LABEL'>
						<text
							transform='matrix(1 0 0 1 80.4453 14.791)'
							className={SCSS.BuildingLabel}>
							ZARA 1
						</text>
						<text
							transform='matrix(1 0 0 1 386.6978 14.791)'
							className={SCSS.BuildingLabel}>
							ZARA 2
						</text>
					</g>
					<g id='LEVEL-3-ZARA-2'>
						<g
							className={highlight('301', 'A', 'ZARA 2')}
							onClick={() =>
								openFloorplan('301', 'A', 'ZARA 2', '3', 'location-2-e')
							}
							id='UINIT-301-A'>
							<g id='LWPOLYLINE_572_'>
								<rect
									x='263.45'
									y='62.71'
									className={SCSS.Hot}
									width='38.06'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 273.3052 91.1339)'
									className={SCSS.Number}>
									301
								</text>
								<text
									transform='matrix(1 0 0 1 276.3052 111.1339)'
									className={SCSS.PlanName}>
									A
								</text>
							</g>
						</g>
						<g
							className={highlight('302', 'B2', 'ZARA 2')}
							onClick={() =>
								openFloorplan('302', 'B2', 'ZARA 2', '3', 'location-2-e')
							}
							id='UINIT-302-B2'>
							<g id='LWPOLYLINE_573_'>
								<rect
									x='301.5'
									y='62.71'
									className={SCSS.Hot}
									width='68.99'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 321.9497 91.1339)'
									className={SCSS.Number}>
									302
								</text>
								<text
									transform='matrix(1 0 0 1 324.9497 111.1339)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							className={highlight('303', 'B1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('303', 'B1', 'ZARA 2', '3', 'location-2-e')
							}
							id='UINIT-303-B1'>
							<g id='LWPOLYLINE_574_'>
								<rect
									x='370.49'
									y='62.71'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 399.2483 91.1339)'
									className={SCSS.Number}>
									303
								</text>
								<text
									transform='matrix(1 0 0 1 402.2483 111.1339)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							className={highlight('304', 'B1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('304', 'B1', 'ZARA 2', '3', 'location-2-e')
							}
							id='UINIT-304-B1'>
							<g id='LWPOLYLINE_575_'>
								<rect
									x='452.7'
									y='62.71'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 481.4636 91.1339)'
									className={SCSS.Number}>
									304
								</text>
								<text
									transform='matrix(1 0 0 1 484.4636 111.1339)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							className={highlight('305', 'B1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('305', 'B1', 'ZARA 2', '3', 'location-1')
							}
							id='UINIT-305-B1'>
							<g id='LWPOLYLINE_576_'>
								<rect
									x='551.3'
									y='104.61'
									className={SCSS.Hot}
									width='56.38'
									height='82.85'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 567.1438 144.1746)'
									className={SCSS.Number}>
									305
								</text>
								<text
									transform='matrix(1 0 0 1 570.1438 164.1746)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							className={highlight('306', 'B1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('306', 'B1', 'ZARA 2', '3', 'location-2-w')
							}
							id='UINIT-306-B1'>
							<g id='LWPOLYLINE_577_'>
								<rect
									x='469.08'
									y='130.75'
									className={SCSS.Hot}
									width='82.22'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 497.8584 156.9597)'
									className={SCSS.Number}>
									306
								</text>
								<text
									transform='matrix(1 0 0 1 500.8584 176.9597)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							className={highlight('307', 'B1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('307', 'B1', 'ZARA 2', '3', 'location-2-w')
							}
							id='UINIT-307-B1'>
							<g id='LWPOLYLINE_578_'>
								<rect
									x='386.87'
									y='130.75'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 415.6431 156.9597)'
									className={SCSS.Number}>
									307
								</text>
								<text
									transform='matrix(1 0 0 1 418.6431 176.9597)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							className={highlight('308', 'B1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('308', 'B1', 'ZARA 2', '3', 'location-2-w')
							}
							id='UINIT-308-B1'>
							<g id='LWPOLYLINE_579_'>
								<rect
									x='304.65'
									y='130.75'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 334.9558 156.9597)'
									className={SCSS.Number}>
									308
								</text>
								<text
									transform='matrix(1 0 0 1 337.9558 176.9597)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							className={highlight('309', 'A2', 'ZARA 2')}
							onClick={() =>
								openFloorplan('309', 'A2', 'ZARA 2', '3', 'location-2-w')
							}
							id='UINIT-309-A2'>
							<g id='LWPOLYLINE_571_'>
								<polygon
									className={SCSS.Hot}
									points='304.71,187.45 242.66,187.45 242.66,164.93 259.35,164.93 259.35,130.75 304.65,130.75 				'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 267.5586 156.9597)'
									className={SCSS.Number}>
									309
								</text>
								<text
									transform='matrix(1 0 0 1 270.5586 176.9597)'
									className={SCSS.PlanName}>
									A2
								</text>
							</g>
						</g>
					</g>
					<g id='LEVEL-3-ZARA-1'>
						<g
							className={highlight('301', 'B2', 'ZARA 1')}
							onClick={() =>
								openFloorplan('301', 'B2', 'ZARA 1', '3', 'location-3-e')
							}
							id='UINIT-301-B2'>
							<g id='LWPOLYLINE_585_'>
								<rect
									x='110.67'
									y='74.37'
									className={SCSS.Hot}
									width='56.7'
									height='69.3'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 126.9473 106.2271)'
									className={SCSS.Number}>
									301
								</text>
								<text
									transform='matrix(1 0 0 1 127.9473 126.2271)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							className={highlight('302', 'A4', 'ZARA 1')}
							onClick={() =>
								openFloorplan('302', 'A4', 'ZARA 1', '3', 'location-3-s')
							}
							id='UINIT-302-A4'>
							<g id='LWPOLYLINE_586_'>
								<rect
									x='110.67'
									y='143.67'
									className={SCSS.Hot}
									width='56.7'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 127.3267 169.2064)'
									className={SCSS.Number}>
									302
								</text>
								<text
									transform='matrix(1 0 0 1 127.3267 189.2064)'
									className={SCSS.PlanName}>
									A4
								</text>
							</g>
						</g>
						<g
							className={highlight('303', 'A2', 'ZARA 1')}
							onClick={() =>
								openFloorplan('303', 'A2', 'ZARA 1', '3', 'location-3-s')
							}
							id='UINIT-303-A2'>
							<g id='LWPOLYLINE_581_'>
								<polygon
									className={SCSS.Hot}
									points='110.67,200.37 110.67,245.1 145.48,245.1 145.48,262.11 167.37,262.11 167.37,200.37 				'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 127.5786 220.4726)'
									className={SCSS.Number}>
									303
								</text>
								<text
									transform='matrix(1 0 0 1 127.5786 240.4726)'
									className={SCSS.PlanName}>
									A2
								</text>
							</g>
						</g>
						<g
							className={highlight('304', 'C1', 'ZARA 1')}
							onClick={() =>
								openFloorplan('304', 'C1', 'ZARA 1', '3', 'location-4')
							}
							id='UINIT-304-C1'>
							<g id='LWPOLYLINE_582_'>
								<polygon
									className={SCSS.Hot}
									points='38.85,278.17 38.85,295.58 42.63,295.58 42.67,327.31 167.37,327.31 167.37,262.11 99.33,262.11
					99.33,278.17 				'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 92.802 301.0392)'
									className={SCSS.Number}>
									304
								</text>
								<text
									transform='matrix(1 0 0 1 92.802 321.0392)'
									className={SCSS.PlanName}>
									C1
								</text>
							</g>
						</g>
						<g
							className={highlight('305', 'B1', 'ZARA 1')}
							onClick={() =>
								openFloorplan('305', 'B1', 'ZARA 1', '3', 'location-3-n')
							}
							id='UINIT-305-B1_1_'>
							<g id='LWPOLYLINE_583_'>
								<rect
									x='42.63'
									y='195.96'
									className={SCSS.Hot}
									width='56.7'
									height='82.21'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 58.6355 235.8121)'
									className={SCSS.Number}>
									305
								</text>
								<text
									transform='matrix(1 0 0 1 61.6355 255.8121)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							className={highlight('306', 'B2', 'ZARA 1')}
							onClick={() =>
								openFloorplan('306', 'B2', 'ZARA 1', '3', 'location-3-n')
							}
							id='UINIT-306-B2'>
							<g id='LWPOLYLINE_584_'>
								<rect
									x='42.63'
									y='126.97'
									className={SCSS.Hot}
									width='56.7'
									height='68.98'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 57.9075 159.4884)'
									className={SCSS.Number}>
									306
								</text>
								<text
									transform='matrix(1 0 0 1 59.9075 179.4884)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							className={highlight('307', 'A2', 'ZARA 1')}
							onClick={() =>
								openFloorplan('307', 'A2', 'ZARA 1', '3', 'location-3-e')
							}
							id='UINIT-307-A2'>
							<g id='LWPOLYLINE_580_'>
								<polygon
									className={SCSS.Hot}
									points='99.33,126.97 99.33,81.61 65.16,81.61 65.16,64.92 42.63,64.92 42.63,126.97 				'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 57.5388 102.5246)'
									className={SCSS.Number}>
									307
								</text>
								<text
									transform='matrix(1 0 0 1 59.5388 122.5246)'
									className={SCSS.PlanName}>
									A2
								</text>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</div>
	);
};

export default Level3;
