import React from 'react';
import Pool from './Pool';
import BuildingLabel from './BuildingLabel';
import LakeLabel from './LakeLabel';
import SCSS from './Keys.module.scss';

type Props = {
	highlight: (plan: string) => string;
	openFloorplan: (
		room: string,
		plan: string,
		building: string,
		level: string,
		viewLocation: string
	) => void;
};

const Level6 = (props: Props) => {
	const {highlight, openFloorplan} = props;
	return (
		<div className={SCSS.Keyplate}>
			<svg viewBox='0 0 750 400'>
				<Pool />
				<g id='LINEWORKS' className={SCSS.Lineworks}>
					<g>
						<polyline points='107.79,67.04 141.96,67.04 141.96,83.74 		' />
						<polyline points='141.02,67.04 141.02,56.8 172.36,56.8 172.36,76.49 		' />
						<line x1='155.51' y1='76.49' x2='155.51' y2='56.8' />
						<line x1='153.3' y1='247.22' x2='153.3' y2='264.23' />
						<line x1='133.59' y1='67.04' x2='133.59' y2='83.74' />
						<line x1='131.7' y1='67.04' x2='131.7' y2='83.74' />
						<line x1='129.81' y1='67.04' x2='129.81' y2='83.74' />
						<line x1='127.92' y1='67.04' x2='127.92' y2='83.74' />
						<line x1='126.03' y1='67.04' x2='126.03' y2='83.74' />
						<line x1='124.14' y1='67.04' x2='124.14' y2='83.74' />
						<line x1='122.25' y1='67.04' x2='122.25' y2='83.74' />
						<line x1='120.36' y1='67.04' x2='120.36' y2='83.74' />
						<line x1='118.47' y1='67.04' x2='118.47' y2='83.74' />
						<line x1='116.58' y1='67.04' x2='116.58' y2='83.74' />
						<line x1='133.59' y1='75.39' x2='116.58' y2='75.39' />
						<line x1='179.97' y1='247.22' x2='179.97' y2='264.23' />
						<line x1='178.08' y1='247.22' x2='178.08' y2='264.23' />
						<line x1='176.19' y1='247.22' x2='176.19' y2='264.23' />
						<line x1='174.3' y1='247.22' x2='174.3' y2='264.23' />
						<line x1='172.41' y1='247.22' x2='172.41' y2='264.23' />
						<line x1='170.52' y1='247.22' x2='170.52' y2='264.23' />
						<line x1='168.63' y1='247.22' x2='168.63' y2='264.23' />
						<line x1='166.74' y1='247.22' x2='166.74' y2='264.23' />
						<line x1='164.85' y1='247.22' x2='164.85' y2='264.23' />
						<line x1='162.96' y1='247.22' x2='162.96' y2='264.23' />
						<line x1='179.97' y1='255.73' x2='162.96' y2='255.73' />
						<polyline
							points='210.03,292.1 221.13,292.07 226.51,357.93 147.83,352.03 71.81,357.93 74.15,299.84 85.26,299.84
			'
						/>
						<rect x='69.51' y='129.1' width='15.75' height='68.99' />
						<polygon points='85.26,129.1 85.26,67.04 120.36,67.04 120.36,52.47 62.01,52.47 69.51,129.1 		' />
						<polygon points='231.38,56.8 225.75,145.79 210,145.79 210,76.49 172.36,76.49 172.36,56.8 		' />
						<rect x='210' y='145.79' width='15.75' height='57.1' />
						<polygon points='231.38,270.38 225.75,202.49 210,202.49 210,270.38 		' />
						<polygon points='85.27,198.08 85.27,276.35 62.01,276.35 69.51,198.08 		' />
					</g>
					<g>
						<polygon points='344.19,42.71 399.1,45.34 399.1,64.84 344.19,64.84 		' />
						<polyline points='344.19,64.84 344.19,42.71 286.24,39.71 286.24,102.48 306.08,102.48 		' />
						<line x1='285.29' y1='141.01' x2='301.98' y2='141.01' />
						<line x1='285.29' y1='142.9' x2='301.98' y2='142.9' />
						<line x1='285.29' y1='144.79' x2='301.98' y2='144.79' />
						<line x1='285.29' y1='146.68' x2='301.98' y2='146.68' />
						<line x1='285.29' y1='148.57' x2='301.98' y2='148.57' />
						<line x1='285.29' y1='150.46' x2='301.98' y2='150.46' />
						<line x1='285.29' y1='152.35' x2='301.98' y2='152.35' />
						<line x1='285.29' y1='154.24' x2='301.98' y2='154.24' />
						<line x1='285.29' y1='156.13' x2='301.98' y2='156.13' />
						<line x1='285.29' y1='158.02' x2='301.98' y2='158.02' />
						<line x1='293.64' y1='141.01' x2='293.64' y2='158.02' />
						<line x1='577.55' y1='87.04' x2='593.99' y2='87.04' />
						<line x1='593.93' y1='121.54' x2='577.55' y2='121.54' />
						<line x1='577.61' y1='94.56' x2='593.99' y2='94.56' />
						<line x1='577.61' y1='96.45' x2='593.99' y2='96.45' />
						<line x1='577.61' y1='98.34' x2='593.99' y2='98.34' />
						<line x1='577.61' y1='100.23' x2='593.99' y2='100.23' />
						<line x1='577.61' y1='102.12' x2='593.99' y2='102.12' />
						<line x1='577.61' y1='104.01' x2='593.99' y2='104.01' />
						<line x1='577.61' y1='105.9' x2='593.99' y2='105.9' />
						<line x1='577.61' y1='107.79' x2='593.99' y2='107.79' />
						<line x1='577.55' y1='109.68' x2='593.99' y2='109.68' />
						<line x1='577.55' y1='111.57' x2='593.99' y2='111.57' />
						<line x1='585.77' y1='94.56' x2='585.77' y2='111.57' />
						<polyline points='306.08,102.48 285.29,102.48 285.29,119.33 306.08,119.33 		' />
						<line x1='285.29' y1='132.88' x2='301.98' y2='132.88' />
						<polygon points='283.21,214.7 347.34,209.08 347.34,189.58 283.21,189.58 		' />
						<line x1='593.99' y1='87.04' x2='593.99' y2='132.88' />
						<polygon points='420.62,45.34 495.39,42.85 495.39,64.84 420.62,64.84 		' />
						<polygon points='495.39,42.85 589.89,39.71 589.89,87.04 577.55,87.04 577.61,64.84 495.39,64.84 		' />
						<rect x='347.34' y='189.58' width='82.27' height='19.5' />
						<rect x='429.62' y='189.58' width='82.27' height='19.5' />
						<polygon points='511.89,189.58 594.17,189.58 594.17,214.7 511.89,209.08 		' />
						<line x1='285.29' y1='167.05' x2='285.29' y2='132.88' />
						<rect x='594.17' y='132.88' width='56.21' height='56.7' />
						<line x1='285.29' y1='132.88' x2='285.29' y2='119.33' />
					</g>
				</g>
				<g id='UNITS'>
					<g id='LEVEL-6-LABEL'>
						<text
							transform='matrix(1 0 0 1 85.4619 393.0516)'
							className={SCSS.LevelLabel}>
							LEVEL 6
						</text>
					</g>
					<BuildingLabel />

					<g id='LEVEL-6-ZARA-2_2_'>
						<g
							id='UINIT-601-A_2_'
							onClick={() =>
								openFloorplan('601', 'A', 'ZARA 2', '6', 'location-2-e')
							}
							className={highlight('A')}>
							<g id='LWPOLYLINE_31_'>
								<rect
									x='306.08'
									y='64.84'
									className={SCSS.Hot}
									width='38.06'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 313.891 89.5999)'
									className={SCSS.Number}>
									601
								</text>
								<text
									transform='matrix(1 0 0 1 319.3051 108.2431)'
									className={SCSS.PlanName}>
									A
								</text>
							</g>
						</g>
						<g
							id='UINIT-602-B2_2_'
							onClick={() =>
								openFloorplan('602', 'B2', 'ZARA 2', '6', 'location-2-e')
							}
							className={highlight('B2')}>
							<g id='LWPOLYLINE_30_'>
								<rect
									x='344.14'
									y='64.84'
									className={SCSS.Hot}
									width='68.99'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 365.9219 89.5999)'
									className={SCSS.Number}>
									602
								</text>
								<text
									transform='matrix(1 0 0 1 367.9497 108.2431)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							id='UINIT-603-P1-L2_2_'
							onClick={() =>
								openFloorplan('603', 'P1', 'ZARA 2', '6', 'location-2-e')
							}
							className={highlight('P1')}>
							<g id='LWPOLYLINE_29_'>
								<rect
									x='413.12'
									y='64.84'
									className={SCSS.Hot}
									width='82.27'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 440.9645 89.5999)'
									className={SCSS.Number}>
									603
								</text>
								<text
									transform='matrix(1 0 0 1 424.6212 108.2431)'
									className={SCSS.PlanName}>
									P1 - L2
								</text>
							</g>
						</g>
						<g
							id='UINIT-604-P1-L2_2_'
							onClick={() =>
								openFloorplan('604', 'P1', 'ZARA 2', '6', 'location-2-e')
							}
							className={highlight('P1')}>
							<g id='LWPOLYLINE_28_'>
								<rect
									x='495.39'
									y='64.84'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 523.6863 89.5999)'
									className={SCSS.Number}>
									604
								</text>
								<text
									transform='matrix(1 0 0 1 507.322 108.2431)'
									className={SCSS.PlanName}>
									P1 - L2
								</text>
							</g>
						</g>
						<g
							id='UINIT-605-P2-L2_2_'
							onClick={() =>
								openFloorplan('605', 'P2', 'ZARA 2', '6', 'location-1')
							}
							className={highlight('P2')}>
							<g id='LWPOLYLINE_27_'>
								<rect
									x='511.77'
									y='132.88'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 540.6797 156.4257)'
									className={SCSS.Number}>
									605
								</text>
								<text
									transform='matrix(1 0 0 1 522.6714 175.0689)'
									className={SCSS.PlanName}>
									P2 - L2
								</text>
							</g>
						</g>
						<g
							id='UINIT-606-P1-L2_2_'
							onClick={() =>
								openFloorplan('606', 'P1', 'ZARA 2', '6', 'location-2-w')
							}
							className={highlight('P1')}>
							<g id='LWPOLYLINE_26_'>
								<rect
									x='429.56'
									y='132.88'
									className={SCSS.Hot}
									width='82.22'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 457.235 156.4257)'
									className={SCSS.Number}>
									606
								</text>
								<text
									transform='matrix(1 0 0 1 441.0314 175.0689)'
									className={SCSS.PlanName}>
									P1 - L2
								</text>
							</g>
						</g>
						<g
							id='UINIT-607-P1-L2_2_'
							onClick={() =>
								openFloorplan('607', 'P1', 'ZARA 2', '6', 'location-2-w')
							}
							className={highlight('P1')}>
							<g id='LWPOLYLINE_25_'>
								<rect
									x='347.34'
									y='132.88'
									className={SCSS.Hot}
									width='82.22'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 375.5245 156.4257)'
									className={SCSS.Number}>
									607
								</text>
								<text
									transform='matrix(1 0 0 1 358.8453 175.0689)'
									className={SCSS.PlanName}>
									P1 - L2
								</text>
							</g>
						</g>
						<g
							id='UINIT-608-A2_2_'
							onClick={() =>
								openFloorplan('608', 'A2', 'ZARA 2', '6', 'location-2-w')
							}
							className={highlight('A2')}>
							<g id='LWPOLYLINE_24_'>
								<polygon
									className={SCSS.Hot}
									points='347.34,189.58 285.29,189.58 285.29,167.05 301.98,167.05 301.98,132.88 347.34,132.88 				'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 311.9277 156.4257)'
									className={SCSS.Number}>
									608
								</text>
								<text
									transform='matrix(1 0 0 1 313.5586 175.0689)'
									className={SCSS.PlanName}>
									A2
								</text>
							</g>
						</g>
					</g>
					<g id='LEVEL-6-ZARA-1_2_'>
						<g
							id='UINIT-601-B2_2_'
							onClick={() =>
								openFloorplan('601', 'B2', 'ZARA 1', '6', 'location-3-e')
							}
							className={highlight('B2')}>
							<g id='LWPOLYLINE_23_'>
								<rect
									x='153.31'
									y='76.49'
									className={SCSS.Hot}
									width='56.7'
									height='69.3'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 170.4384 105.6933)'
									className={SCSS.Number}>
									601
								</text>
								<text
									transform='matrix(1 0 0 1 170.9471 124.3364)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							id='UINIT-602-A4_2_'
							onClick={() =>
								openFloorplan('602', 'A4', 'ZARA 1', '6', 'location-3-s')
							}
							className={highlight('A4')}>
							<g id='LWPOLYLINE_22_'>
								<rect
									x='153.3'
									y='145.79'
									className={SCSS.Hot}
									width='56.7'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 168.9193 168.2761)'
									className={SCSS.Number}>
									602
								</text>
								<text
									transform='matrix(1 0 0 1 172.3065 186.9193)'
									className={SCSS.PlanName}>
									A4
								</text>
							</g>
						</g>
						<g
							id='UINIT-603-A2_2_'
							onClick={() =>
								openFloorplan('603', 'A2', 'ZARA 1', '6', 'location-3-s')
							}
							className={highlight('A2')}>
							<g id='LWPOLYLINE_21_'>
								<polygon
									className={SCSS.Hot}
									points='153.3,202.49 153.3,247.22 188.11,247.22 188.11,264.23 210,264.23 210,202.49 				'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 168.8773 219.9387)'
									className={SCSS.Number}>
									603
								</text>
								<text
									transform='matrix(1 0 0 1 170.5785 238.5819)'
									className={SCSS.PlanName}>
									A2
								</text>
							</g>
						</g>
						<g
							id='UINIT-604-C1_2_'
							onClick={() =>
								openFloorplan('604', 'C1', 'ZARA 1', '6', 'location-4')
							}
							className={highlight('C1')}>
							<g id='LWPOLYLINE_20_'>
								<polygon
									className={SCSS.Hot}
									points='81.48,280.3 81.48,297.7 85.26,297.7 85.3,329.44 210,329.44 210,264.23 141.96,264.23
					141.96,280.3 				'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 132.988 300.5052)'
									className={SCSS.Number}>
									604
								</text>
								<text
									transform='matrix(1 0 0 1 135.802 319.1484)'
									className={SCSS.PlanName}>
									C1
								</text>
							</g>
						</g>
						<g
							id='UINIT-605-P1_2_'
							onClick={() =>
								openFloorplan('605', 'P1', 'ZARA 1', '6', 'location-3-n')
							}
							className={highlight('P1')}>
							<g id='LWPOLYLINE_19_'>
								<rect
									x='85.27'
									y='198.08'
									className={SCSS.Hot}
									width='56.7'
									height='82.21'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 100.7747 228.3614)'
									className={SCSS.Number}>
									605
								</text>
								<text
									transform='matrix(1 0 0 1 104.2668 247.0046)'
									className={SCSS.PlanName}>
									P1
								</text>
								<text
									transform='matrix(1 0 0 1 90.2668 265.0046)'
									className={SCSS.PlanName}>
									- L2
								</text>
							</g>
						</g>
						<g
							id='UINIT-606-B2_2_'
							onClick={() =>
								openFloorplan('606', 'B2', 'ZARA 1', '6', 'location-3-n')
							}
							className={highlight('B2')}>
							<g id='LWPOLYLINE_18_'>
								<rect
									x='85.27'
									y='129.1'
									className={SCSS.Hot}
									width='56.7'
									height='68.98'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 100.698 158.9545)'
									className={SCSS.Number}>
									606
								</text>
								<text
									transform='matrix(1 0 0 1 102.9075 177.5977)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							id='UINIT-607-A2_2_'
							onClick={() =>
								openFloorplan('607', 'A2', 'ZARA 1', '6', 'location-3-e')
							}
							className={highlight('A2')}>
							<g id='LWPOLYLINE_17_'>
								<polygon
									className={SCSS.Hot}
									points='141.96,129.1 141.96,83.74 107.79,83.74 107.79,67.04 85.26,67.04 85.26,129.1 				'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 101.1736 101.9907)'
									className={SCSS.Number}>
									607
								</text>
								<text
									transform='matrix(1 0 0 1 102.5388 120.6339)'
									className={SCSS.PlanName}>
									A2
								</text>
							</g>
						</g>
					</g>
				</g>
				<LakeLabel />
			</svg>
		</div>
	);
};

export default Level6;
