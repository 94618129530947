import React from 'react';
import ViewButton from './ViewButton';
import SCSS from './GalleryHeader.module.scss';
import {IFloorplan} from '../../../../constants/floorplans';

type Props = {
	plan: IFloorplan;
	gotoView: () => void;
};

const Header = (props: Props) => {
	const {plan, gotoView} = props;

	return (
		<div className={SCSS.GalleryHeader}>
			<div className={SCSS.LeftContainer}>
				<div className={SCSS.Title}>{`Plan ${plan.name}`}</div>
				<div className={SCSS.Subtitle}>
					{`${plan.bed} Bedroom`}
					{plan.den && ` + Den`}, {`${plan.bath} Bath`}
				</div>
			</div>
			<div className={SCSS.RightContainer}>
				<div className={SCSS.ButtonContainer}>
					{plan.virtual && (
						<div className={SCSS.Button}>
							<i className={SCSS.IconVirtual} />
							Virtual Tour
						</div>
					)}
					<ViewButton gotoView={gotoView} plan={plan} />
				</div>
			</div>
		</div>
	);
};

export default Header;
