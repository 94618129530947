import React from 'react';
import SCSS from './Keys.module.scss';

type Props = {
	openFloorplan: (
		room: string,
		plan: string,
		building: string,
		level: string,
		location: string
	) => void;
	highlight: (r: string, p: string, b: string) => string;
};

const Level1 = (props: Props) => {
	const {openFloorplan, highlight} = props;
	return (
		<div className={SCSS.Keyplate}>
			<svg viewBox='0 0 650 400'>
				<g id='POOL' className={SCSS.Lineworks}>
					<g>
						<linearGradient
							id='SVGID_1_'
							gradientUnits='userSpaceOnUse'
							x1='328.6905'
							y1='359.7091'
							x2='328.6905'
							y2='255.4724'>
							<stop offset='0' style={{stopColor: '#FFFFFF'}} />
							<stop offset='1' style={{stopColor: '#999999'}} />
						</linearGradient>
						<path
							className={SCSS.Pool}
							d='M408.06,273.28h-27.78v-17.8H234.6v104.24c45.02,0.02,86.12-7.06,122.3-17.35v-86.88h23.39v79.59
			c15.25-5.19,29.47-10.86,42.5-16.67L408.06,273.28z'
						/>
						<polygon
							points='226.74,255.47 226.74,247.62 196.58,247.62 196.58,397.62 234.6,397.62 234.6,359.71 234.6,255.47
			234.6,255.47 		'
						/>
						<rect x='356.89' y='255.47' width='23.39' height='100.84' />
						<line x1='234.6' y1='255.47' x2='356.89' y2='255.47' />
						<path d='M356.89,342.36c-36.17,10.29-77.28,17.37-122.3,17.35' />
						<path d='M380.28,273.28h27.78l14.72,45.12c-13.03,5.82-27.25,11.48-42.5,16.67' />
						<rect x='202.23' y='372.22' width='26.72' height='20.84' />
						<rect x='207.48' y='353.13' width='16.22' height='11.93' />
						<rect x='361.91' y='260.88' width='13.36' height='11.77' />
					</g>
				</g>

				<g id='LINEWORKS' className={SCSS.Lineworks}>
					<g>
						<polygon
							points='35.85,277.2 42.3,277.2 42.3,329.19 178.07,329.19 178.07,263.97 171.93,263.97 171.92,246.96
			183.27,246.96 183.27,64.58 133.97,64.58 133.97,56.54 102.94,56.54 102.94,66.78 35.85,66.78 		'
						/>
						<line x1='65.85' y1='83.48' x2='65.85' y2='66.78' />
						<line x1='65.85' y1='83.48' x2='103.88' y2='83.48' />
						<line x1='103.88' y1='66.78' x2='103.88' y2='236.57' />
						<line x1='103.88' y1='236.57' x2='35.85' y2='236.57' />
						<line x1='115.22' y1='263.97' x2='115.22' y2='111.44' />
						<line x1='115.22' y1='263.97' x2='171.92' y2='263.97' />
						<line x1='171.92' y1='246.96' x2='171.92' y2='263.97' />
						<line x1='171.92' y1='246.96' x2='115.22' y2='246.96' />
						<line x1='136.6' y1='199.12' x2='115.22' y2='199.12' />
						<line x1='117.43' y1='77.18' x2='133.97' y2='77.18' />
						<line x1='117.43' y1='77.18' x2='117.43' y2='56.54' />
						<line x1='102.94' y1='66.78' x2='103.88' y2='66.78' />
						<line x1='86.56' y1='121.2' x2='103.88' y2='121.2' />
						<line x1='86.56' y1='121.2' x2='86.56' y2='142.14' />
						<line x1='86.56' y1='142.14' x2='103.88' y2='142.14' />
						<line x1='115.22' y1='246.96' x2='35.85' y2='246.96' />
						<line x1='133.97' y1='77.18' x2='133.97' y2='56.54' />
						<line x1='183.27' y1='111.44' x2='103.88' y2='111.44' />
						<line x1='124.21' y1='189.32' x2='124.21' y2='111.44' />
						<line x1='136.6' y1='199.12' x2='136.6' y2='219.82' />
						<line x1='136.6' y1='219.82' x2='115.22' y2='219.82' />
						<line x1='147' y1='199.12' x2='147' y2='246.96' />
						<line x1='147' y1='199.12' x2='183.27' y2='199.12' />
						<line x1='147' y1='219.53' x2='183.27' y2='219.53' />
						<line x1='124.21' y1='189.32' x2='183.27' y2='189.2' />
						<line x1='167.22' y1='72.61' x2='183.27' y2='72.61' />
						<line x1='167.22' y1='72.61' x2='167.22' y2='86.29' />
						<line x1='167.22' y1='86.29' x2='183.27' y2='86.29' />
						<line x1='136.6' y1='207.36' x2='147' y2='207.36' />
						<line x1='95.57' y1='66.78' x2='95.57' y2='83.48' />
						<line x1='93.68' y1='66.78' x2='93.68' y2='83.48' />
						<line x1='91.79' y1='66.78' x2='91.79' y2='83.48' />
						<line x1='89.9' y1='66.78' x2='89.9' y2='83.48' />
						<line x1='88.01' y1='66.78' x2='88.01' y2='83.48' />
						<line x1='86.12' y1='66.78' x2='86.12' y2='83.48' />
						<line x1='84.23' y1='66.78' x2='84.23' y2='83.48' />
						<line x1='82.34' y1='66.78' x2='82.34' y2='83.48' />
						<line x1='80.45' y1='66.78' x2='80.45' y2='83.48' />
						<line x1='78.56' y1='66.78' x2='78.56' y2='83.48' />
						<line x1='95.57' y1='75.13' x2='78.56' y2='75.13' />
						<line x1='141.95' y1='246.96' x2='141.95' y2='263.97' />
						<line x1='140.06' y1='246.96' x2='140.06' y2='263.97' />
						<line x1='138.17' y1='246.96' x2='138.17' y2='263.97' />
						<line x1='136.28' y1='246.96' x2='136.28' y2='263.97' />
						<line x1='134.39' y1='246.96' x2='134.39' y2='263.97' />
						<line x1='132.5' y1='246.96' x2='132.5' y2='263.97' />
						<line x1='130.61' y1='246.96' x2='130.61' y2='263.97' />
						<line x1='128.72' y1='246.96' x2='128.72' y2='263.97' />
						<line x1='126.83' y1='246.96' x2='126.83' y2='263.97' />
						<line x1='124.94' y1='246.96' x2='124.94' y2='263.97' />
						<line x1='141.95' y1='255.47' x2='124.94' y2='255.47' />
					</g>
					<g>
						<polyline
							points='263.9,179.87 247.21,179.87 247.21,132.62 247.21,119.07 243.43,119.07 243.43,102.22
			247.52,102.22 247.52,64.58 306.11,64.58 		'
						/>
						<polyline points='539.53,64.58 555.91,64.58 555.91,106.47 		' />
						<line x1='555.91' y1='121.28' x2='539.53' y2='121.28' />
						<line x1='263.9' y1='132.62' x2='247.21' y2='132.62' />
						<rect x='247.21' y='102.22' width='20.79' height='16.85' />
						<rect x='280.62' y='102.01' width='25.5' height='16.95' />
						<rect x='247.52' y='71.42' width='16.44' height='16.89' />
						<rect x='309.23' y='189.32' width='82.24' height='19.5' />
						<rect x='391.48' y='189.32' width='82.24' height='19.5' />
						<rect x='473.69' y='189.32' width='82.21' height='19.5' />
						<rect x='375.1' y='45.08' width='82.24' height='19.5' />
						<rect x='457.34' y='45.08' width='82.24' height='19.5' />
						<rect x='263.85' y='189.32' width='45.39' height='19.5' />
						<rect x='306.11' y='45.08' width='68.99' height='19.5' />
						<line x1='247.27' y1='140.75' x2='263.96' y2='140.75' />
						<line x1='247.27' y1='142.64' x2='263.96' y2='142.64' />
						<line x1='247.27' y1='144.53' x2='263.96' y2='144.53' />
						<line x1='247.27' y1='146.42' x2='263.96' y2='146.42' />
						<line x1='247.27' y1='148.31' x2='263.96' y2='148.31' />
						<line x1='247.27' y1='150.2' x2='263.96' y2='150.2' />
						<line x1='247.27' y1='152.09' x2='263.96' y2='152.09' />
						<line x1='247.27' y1='153.98' x2='263.96' y2='153.98' />
						<line x1='247.27' y1='155.87' x2='263.96' y2='155.87' />
						<line x1='247.27' y1='157.76' x2='263.96' y2='157.76' />
						<line x1='255.62' y1='140.75' x2='255.62' y2='157.76' />
						<line x1='539.53' y1='96.19' x2='555.91' y2='96.19' />
						<line x1='539.53' y1='98.08' x2='555.91' y2='98.08' />
						<line x1='539.53' y1='99.97' x2='555.91' y2='99.97' />
						<line x1='539.53' y1='101.86' x2='555.91' y2='101.86' />
						<line x1='539.53' y1='103.75' x2='555.91' y2='103.75' />
						<line x1='539.53' y1='105.64' x2='555.91' y2='105.64' />
						<line x1='539.53' y1='107.53' x2='555.91' y2='107.53' />
						<line x1='539.53' y1='109.42' x2='555.91' y2='109.42' />
						<line x1='539.53' y1='111.31' x2='555.91' y2='111.31' />
						<line x1='539.53' y1='113.2' x2='555.91' y2='113.2' />
						<line x1='547.72' y1='96.19' x2='547.72' y2='113.2' />
						<polygon
							points='639.75,94.47 555.91,94.47 555.91,106.47 612.29,106.47 612.29,189.32 555.91,189.32 555.91,208.82
			639.75,208.82 		'
						/>
					</g>
				</g>
				<g id='UNITS'>
					<g id='LEVEL-1-LABEL'>
						<text
							transform='matrix(1 0 0 1 471.9631 329.0297)'
							className={SCSS.LevelLabel}>
							LEVEL 1
						</text>
					</g>
					<g id='LEVEL-1-BUILDING-LABEL'>
						<text
							transform='matrix(1 0 0 1 80.4454 16.9003)'
							className={SCSS.BuildingLabel}>
							ZARA 1
						</text>
						<text
							transform='matrix(1 0 0 1 386.6978 16.9003)'
							className={SCSS.BuildingLabel}>
							ZARA 2
						</text>
					</g>

					<g id='LEVEL-1-ZARA-2'>
						<g
							className={highlight('101', 'B2', 'ZARA 2')}
							onClick={() =>
								openFloorplan('101', 'B2', 'ZARA 2', '1', 'location-2-e')
							}
							id='UINIT-101-B2'>
							<g id='LWPOLYLINE_603_'>
								<rect
									x='306.11'
									y='64.58'
									className={SCSS.Hot}
									width='68.99'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 328.7949 91.4177)'
									className={SCSS.Number}>
									101
								</text>
								<text
									transform='matrix(1 0 0 1 328.7949 111.4177)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							className={highlight('102', 'B1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('102', 'B1', 'ZARA 2', '1', 'location-2-e')
							}
							id='UINIT-102-B1'>
							<g id='LWPOLYLINE_604_'>
								<rect
									x='375.1'
									y='64.58'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 403.7119 91.4177)'
									className={SCSS.Number}>
									102
								</text>
								<text
									transform='matrix(1 0 0 1 406.7119 111.4177)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							className={highlight('103', 'B1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('103', 'B1', 'ZARA 2', '1', 'location-2-e')
							}
							id='UINIT-103-B1'>
							<g id='LWPOLYLINE_605_'>
								<rect
									x='457.31'
									y='64.58'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 486.4269 91.4177)'
									className={SCSS.Number}>
									103
								</text>
								<text
									transform='matrix(1 0 0 1 488.9269 111.4177)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							className={highlight('104', 'B1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('104', 'B1', 'ZARA 2', '1', 'location-1')
							}
							id='UINIT-104-B1'>
							<g id='LWPOLYLINE_606_'>
								<rect
									x='555.91'
									y='106.47'
									className={SCSS.Hot}
									width='56.38'
									height='82.85'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 572.6069 146.2843)'
									className={SCSS.Number}>
									104
								</text>
								<text
									transform='matrix(1 0 0 1 574.6069 166.2843)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							className={highlight('105', 'B1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('105', 'B1', 'ZARA 2', '1', 'location-2-w')
							}
							id='UINIT-105-B1'>
							<g id='LWPOLYLINE_607_'>
								<rect
									x='473.69'
									y='132.62'
									className={SCSS.Hot}
									width='82.22'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 502.3068 159.5143)'
									className={SCSS.Number}>
									105
								</text>
								<text
									transform='matrix(1 0 0 1 505.3068 179.5143)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							className={highlight('106', 'B1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('106', 'B1', 'ZARA 2', '1', 'location-2-w')
							}
							id='UINIT-106-B1'>
							<g id='LWPOLYLINE_608_'>
								<rect
									x='391.48'
									y='132.62'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 420.1068 159.5143)'
									className={SCSS.Number}>
									106
								</text>
								<text
									transform='matrix(1 0 0 1 423.1068 179.5143)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							className={highlight('107', 'B1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('107', 'B1', 'ZARA 2', '1', 'location-2-w')
							}
							id='UINIT-107-B1'>
							<g id='LWPOLYLINE_609_'>
								<rect
									x='309.26'
									y='132.62'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 337.8771 159.5143)'
									className={SCSS.Number}>
									107
								</text>
								<text
									transform='matrix(1 0 0 1 340.8771 179.5143)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							className={highlight('108', 'A3', 'ZARA 2')}
							onClick={() =>
								openFloorplan('108', 'A3', 'ZARA 2', '1', 'location-2-w')
							}
							id='UINIT-108-A3'>
							<g id='LWPOLYLINE_610_'>
								<rect
									x='263.9'
									y='132.62'
									className={SCSS.Hot}
									width='45.36'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 273.8043 159.5143)'
									className={SCSS.Number}>
									108
								</text>
								<text
									transform='matrix(1 0 0 1 274.8043 179.5143)'
									className={SCSS.PlanName}>
									A3
								</text>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</div>
	);
};

export default Level1;
