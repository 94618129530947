import React from 'react';
import Pool from './Pool';
import BuildingLabel from './BuildingLabel';
import LakeLabel from './LakeLabel';
import SCSS from './Keys.module.scss';

type Props = {
	highlight: (plan: string) => string;
	openFloorplan: (
		room: string,
		plan: string,
		building: string,
		level: string,
		viewLocation: string
	) => void;
};

const Level5 = (props: Props) => {
	const {highlight, openFloorplan} = props;
	return (
		<div className={SCSS.Keyplate}>
			<svg viewBox='0 0 750 400'>
				<Pool />
				<g id='LINEWORKS' className={SCSS.Lineworks}>
					<g>
						<line x1='285.57' y1='140.86' x2='302.26' y2='140.86' />
						<line x1='285.57' y1='142.75' x2='302.26' y2='142.75' />
						<line x1='285.57' y1='144.64' x2='302.26' y2='144.64' />
						<line x1='285.57' y1='146.53' x2='302.26' y2='146.53' />
						<line x1='285.57' y1='148.42' x2='302.26' y2='148.42' />
						<line x1='285.57' y1='150.31' x2='302.26' y2='150.31' />
						<line x1='285.57' y1='152.2' x2='302.26' y2='152.2' />
						<line x1='285.57' y1='154.09' x2='302.26' y2='154.09' />
						<line x1='285.57' y1='155.98' x2='302.26' y2='155.98' />
						<line x1='285.57' y1='157.87' x2='302.26' y2='157.87' />
						<line x1='293.92' y1='140.86' x2='293.92' y2='157.87' />
						<polyline points='577.89,86.9 594.27,86.9 594.27,106.58 		' />
						<line x1='594.21' y1='121.39' x2='577.83' y2='121.39' />
						<line x1='577.89' y1='96.3' x2='594.27' y2='96.3' />
						<line x1='577.89' y1='98.19' x2='594.27' y2='98.19' />
						<line x1='577.89' y1='100.08' x2='594.27' y2='100.08' />
						<line x1='577.89' y1='101.97' x2='594.27' y2='101.97' />
						<line x1='577.89' y1='103.86' x2='594.27' y2='103.86' />
						<line x1='577.89' y1='105.75' x2='594.27' y2='105.75' />
						<line x1='577.89' y1='107.64' x2='594.27' y2='107.64' />
						<line x1='577.89' y1='109.53' x2='594.27' y2='109.53' />
						<line x1='577.89' y1='111.42' x2='594.27' y2='111.42' />
						<line x1='577.89' y1='113.31' x2='594.27' y2='113.31' />
						<line x1='586.08' y1='96.3' x2='586.08' y2='113.31' />
						<polyline points='306.36,102.33 286.51,102.33 286.51,119.18 306.36,119.18 		' />
						<polyline points='286.51,119.18 281.79,119.18 281.79,102.33 286.51,102.33 		' />
						<line x1='285.57' y1='166.91' x2='285.57' y2='119.18' />
						<line x1='285.57' y1='132.73' x2='302.26' y2='132.73' />
						<polyline points='281.79,189.43 272.17,189.43 210.28,201.05 		' />
						<polyline points='210.28,185.4 211.77,185.4 263.47,155.55 281.79,106.71 		' />
						<polyline points='108.07,66.89 142.24,66.89 142.24,83.59 		' />
						<polyline points='141.3,66.89 141.3,56.66 172.64,56.66 172.64,76.34 		' />
						<line x1='155.79' y1='76.34' x2='155.79' y2='56.66' />
						<line x1='153.58' y1='247.07' x2='153.58' y2='264.08' />
						<polygon
							points='678.11,92.52 678.11,203.49 617.87,200.68 617.87,189.43 650.65,189.43 650.65,106.58 617.87,106.58
			617.87,95.33 		'
						/>
						<polygon points='210.28,145.64 226.03,145.64 226.03,177.16 211.77,185.4 210.28,185.4 		' />
						<polyline
							points='210.31,291.95 221.41,291.92 226.78,357.78 148.11,351.88 72.09,357.78 74.43,299.69 85.54,299.69
					'
						/>
						<rect x='69.79' y='128.95' width='15.75' height='68.99' />
						<polygon points='85.54,128.95 85.54,66.89 120.64,66.89 120.64,52.32 62.29,52.32 69.79,128.95 		' />
						<polygon points='231.66,56.66 226.03,145.64 210.28,145.64 210.28,76.34 172.64,76.34 172.64,56.66 		' />
						<polygon points='231.66,270.23 225.69,198.16 210.28,201.05 210.28,270.23 		' />
						<polygon points='85.55,197.93 85.54,276.2 62.29,276.2 69.79,197.93 		' />
						<line x1='210.28' y1='202.34' x2='210.28' y2='183.44' />
						<polygon points='344.47,42.56 399.37,45.19 399.37,64.69 344.47,64.69 		' />
						<polyline points='344.47,64.69 344.47,42.56 286.51,39.56 286.51,102.33 306.36,102.33 		' />
						<polygon points='283.48,214.55 347.62,208.93 347.62,189.43 283.48,189.43 		' />
						<polygon points='540.09,41.23 590.17,39.56 590.17,86.9 577.83,86.9 577.89,64.69 540.09,64.69 		' />
						<rect x='347.62' y='189.43' width='37.8' height='19.5' />
						<rect x='467.64' y='189.43' width='44.53' height='19.5' />
						<polygon points='512.17,189.43 594.45,189.43 594.45,214.55 512.17,208.93 		' />
						<rect x='385.42' y='189.43' width='44.47' height='19.5' />
						<rect x='429.84' y='189.43' width='37.8' height='19.5' />
						<polygon points='457.86,43.96 420.9,45.19 420.9,64.69 457.86,64.69 		' />
						<polygon points='495.67,42.71 457.86,43.96 457.86,64.69 495.67,64.69 		' />
						<polygon points='540.09,41.23 495.67,42.71 495.67,64.69 540.09,64.69 		' />
						<line x1='133.87' y1='66.89' x2='133.87' y2='83.59' />
						<line x1='131.98' y1='66.89' x2='131.98' y2='83.59' />
						<line x1='130.09' y1='66.89' x2='130.09' y2='83.59' />
						<line x1='128.2' y1='66.89' x2='128.2' y2='83.59' />
						<line x1='126.31' y1='66.89' x2='126.31' y2='83.59' />
						<line x1='124.42' y1='66.89' x2='124.42' y2='83.59' />
						<line x1='122.53' y1='66.89' x2='122.53' y2='83.59' />
						<line x1='120.64' y1='66.89' x2='120.64' y2='83.59' />
						<line x1='118.75' y1='66.89' x2='118.75' y2='83.59' />
						<line x1='116.86' y1='66.89' x2='116.86' y2='83.59' />
						<line x1='133.87' y1='75.24' x2='116.86' y2='75.24' />
						<line x1='180.25' y1='247.07' x2='180.25' y2='264.08' />
						<line x1='178.36' y1='247.07' x2='178.36' y2='264.08' />
						<line x1='176.47' y1='247.07' x2='176.47' y2='264.08' />
						<line x1='174.58' y1='247.07' x2='174.58' y2='264.08' />
						<line x1='172.69' y1='247.07' x2='172.69' y2='264.08' />
						<line x1='170.8' y1='247.07' x2='170.8' y2='264.08' />
						<line x1='168.91' y1='247.07' x2='168.91' y2='264.08' />
						<line x1='167.02' y1='247.07' x2='167.02' y2='264.08' />
						<line x1='165.13' y1='247.07' x2='165.13' y2='264.08' />
						<line x1='163.24' y1='247.07' x2='163.24' y2='264.08' />
						<line x1='180.25' y1='255.58' x2='163.24' y2='255.58' />
						<rect x='281.79' y='172.58' width='3.78' height='16.85' />
						<line x1='85.54' y1='235.73' x2='66.17' y2='235.73' />
					</g>
				</g>
				<g id='UNITS'>
					<g id='LEVEL-5-LABEL'>
						<text
							transform='matrix(1 0 0 1 85.4619 393.0516)'
							className={SCSS.LevelLabel}>
							LEVEL 5
						</text>
					</g>
					<BuildingLabel />
					<g id='LEVEL-5-ZARA-2_2_'>
						<g
							id='UINIT-501-A_2_'
							onClick={() =>
								openFloorplan('501', 'A', 'ZARA 2', '5', 'location-2-e')
							}
							className={highlight('A')}>
							<g id='LWPOLYLINE_113_'>
								<rect
									x='306.36'
									y='64.69'
									className={SCSS.Hot}
									width='38.06'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 313.9681 89.5999)'
									className={SCSS.Number}>
									501
								</text>
								<text
									transform='matrix(1 0 0 1 319.3051 108.2431)'
									className={SCSS.PlanName}>
									A
								</text>
							</g>
						</g>
						<g
							id='UINIT-502-B2_2_'
							onClick={() =>
								openFloorplan('502', 'B2', 'ZARA 2', '5', 'location-2-e')
							}
							className={highlight('B2')}>
							<g id='LWPOLYLINE_112_'>
								<rect
									x='344.41'
									y='64.69'
									className={SCSS.Hot}
									width='68.99'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 365.999 89.5999)'
									className={SCSS.Number}>
									502
								</text>
								<text
									transform='matrix(1 0 0 1 367.9497 108.2431)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							id='UINIT-503-B1_2_'
							onClick={() =>
								openFloorplan('503', 'A1', 'ZARA 2', '5', 'location-2-e')
							}
							className={highlight('B1')}>
							<g id='LWPOLYLINE_111_'>
								<rect
									x='457.86'
									y='64.69'
									className={SCSS.Hot}
									width='37.81'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 463.5516 89.5999)'
									className={SCSS.Number}>
									503
								</text>
								<text
									transform='matrix(1 0 0 1 467.2723 108.2431)'
									className={SCSS.PlanName}>
									A1
								</text>
							</g>
						</g>
						<g
							id='UINIT-504-A_2_'
							onClick={() =>
								openFloorplan('504', 'A', 'ZARA 2', '5', 'location-2-e')
							}
							className={highlight('A')}>
							<g id='LWPOLYLINE_110_'>
								<rect
									x='540.09'
									y='64.69'
									className={SCSS.Hot}
									width='37.8'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 545.793 89.5999)'
									className={SCSS.Number}>
									504
								</text>
								<text
									transform='matrix(1 0 0 1 549.4927 108.2431)'
									className={SCSS.PlanName}>
									A
								</text>
							</g>
						</g>
						<g
							id='UINIT-505-B1_2_'
							onClick={() =>
								openFloorplan('505', 'B1', 'ZARA 2', '5', 'location-1')
							}
							className={highlight('B1')}>
							<g id='LWPOLYLINE_109_'>
								<rect
									x='594.27'
									y='106.58'
									className={SCSS.Hot}
									width='56.38'
									height='82.85'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 609.36 143.6406)'
									className={SCSS.Number}>
									505
								</text>
								<text
									transform='matrix(1 0 0 1 613.1437 162.2838)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							id='UINIT-506-A1_2_'
							onClick={() =>
								openFloorplan('506', 'A1', 'ZARA 2', '5', 'location-2-w')
							}
							className={highlight('A1')}>
							<g id='LWPOLYLINE_16_'>
								<rect
									x='429.84'
									y='132.73'
									className={SCSS.Hot}
									width='37.8'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 435.3827 156.4257)'
									className={SCSS.Number}>
									506
								</text>
								<text
									transform='matrix(1 0 0 1 438.8749 175.0689)'
									className={SCSS.PlanName}>
									A1
								</text>
							</g>
						</g>
						<g
							id='UINIT-507-A1_2_'
							onClick={() =>
								openFloorplan('507', 'A1', 'ZARA 2', '5', 'location-2-w')
							}
							className={highlight('A1')}>
							<g id='LWPOLYLINE_15_'>
								<rect
									x='347.62'
									y='132.73'
									className={SCSS.Hot}
									width='37.8'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 355.1199 156.4257)'
									className={SCSS.Number}>
									507
								</text>
								<text
									transform='matrix(1 0 0 1 358.136 175.0689)'
									className={SCSS.PlanName}>
									A1
								</text>
							</g>
						</g>
						<g
							id='UINIT-508-A2_2_'
							onClick={() =>
								openFloorplan('508', 'A2', 'ZARA 2', '5', 'location-2-w')
							}
							className={highlight('A2')}>
							<g id='LWPOLYLINE_14_'>
								<polygon
									className={SCSS.Hot}
									points='347.62,189.43 285.57,189.43 285.57,166.91 302.26,166.91 302.26,132.73 347.62,132.73 				'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 312.0043 156.4257)'
									className={SCSS.Number}>
									508
								</text>
								<text
									transform='matrix(1 0 0 1 313.5585 175.0689)'
									className={SCSS.PlanName}>
									A2
								</text>
							</g>
						</g>
						<g
							id='UINIT-603-P1-L1_2_'
							onClick={() =>
								openFloorplan('603', 'P1', 'ZARA 2', '5', 'location-2-e')
							}
							className={highlight('P1')}>
							<g id='LWPOLYLINE_13_'>
								<rect
									x='413.4'
									y='64.69'
									className={SCSS.Hot}
									width='44.46'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 420.0074 107.922)'
									className={SCSS.PlanName}>
									- L1
								</text>
								<text
									transform='matrix(1 0 0 1 425.8302 91.0444)'
									className={SCSS.PlanName}>
									P1
								</text>
							</g>
						</g>
						<g
							id='UINIT-604-P1-L1_2_'
							onClick={() =>
								openFloorplan('604', 'P1', 'ZARA 2', '5', 'location-2-e')
							}
							className={highlight('P1')}>
							<g id='LWPOLYLINE_12_'>
								<rect
									x='495.67'
									y='64.69'
									className={SCSS.Hot}
									width='44.41'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 502.2574 107.922)'
									className={SCSS.PlanName}>
									- L1
								</text>
								<text
									transform='matrix(1 0 0 1 508.0802 91.0444)'
									className={SCSS.PlanName}>
									P1
								</text>
							</g>
						</g>
						<g
							id='UINIT-605-P2-L1_2_'
							onClick={() =>
								openFloorplan('605', 'P2', 'ZARA 2', '5', 'location-2-w')
							}
							className={highlight('P2')}>
							<g id='LWPOLYLINE_11_'>
								<rect
									x='512.05'
									y='132.73'
									className={SCSS.Hot}
									width='82.22'
									height='56.7'
								/>
							</g>
							<text
								transform='matrix(1 0 0 1 523.7172 166.5204)'
								className={SCSS.PlanName}>
								P2 - L1
							</text>
						</g>
						<g
							id='UINIT-606-P1-L1_2_'
							onClick={() =>
								openFloorplan('606', 'P1', 'ZARA 2', '5', 'location-2-w')
							}
							className={highlight('P1')}>
							<g id='LWPOLYLINE_10_'>
								<rect
									x='467.64'
									y='132.73'
									className={SCSS.Hot}
									width='44.41'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 474.2225 175.069)'
									className={SCSS.PlanName}>
									- L1
								</text>
								<text
									transform='matrix(1 0 0 1 480.0453 158.1914)'
									className={SCSS.PlanName}>
									P1
								</text>
							</g>
						</g>
						<g
							id='UINIT-607-P1-L1_2_'
							onClick={() =>
								openFloorplan('607', 'P1', 'ZARA 2', '5', 'location-2-w')
							}
							className={highlight('P1')}>
							<g id='LWPOLYLINE_9_'>
								<rect
									x='385.42'
									y='132.73'
									className={SCSS.Hot}
									width='44.41'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 392.037 175.069)'
									className={SCSS.PlanName}>
									- L1
								</text>
								<text
									transform='matrix(1 0 0 1 397.8597 158.1914)'
									className={SCSS.PlanName}>
									P1
								</text>
							</g>
						</g>
					</g>
					<g id='LEVEL-5-ZARA-1_2_'>
						<g
							id='UINIT-501-B2_2_'
							onClick={() =>
								openFloorplan('501', 'B2', 'ZARA 1', '5', 'location-3-e')
							}
							className={highlight('B2')}>
							<g id='LWPOLYLINE_8_'>
								<rect
									x='153.58'
									y='76.34'
									className={SCSS.Hot}
									width='56.7'
									height='69.3'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 170.5155 105.6933)'
									className={SCSS.Number}>
									501
								</text>
								<text
									transform='matrix(1 0 0 1 170.9471 124.3364)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							id='UINIT-502-A1_2_'
							onClick={() =>
								openFloorplan('502', 'A1', 'ZARA 1', '5', 'location-3-s')
							}
							className={highlight('A1')}>
							<g id='LWPOLYLINE_7_'>
								<rect
									x='153.58'
									y='145.64'
									className={SCSS.Hot}
									width='56.7'
									height='37.8'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 168.9965 161.0797)'
									className={SCSS.Number}>
									502
								</text>
								<text
									transform='matrix(1 0 0 1 172.3065 179.7229)'
									className={SCSS.PlanName}>
									A1
								</text>
							</g>
						</g>
						<g
							id='UINIT-503-A2_2_'
							onClick={() =>
								openFloorplan('503', 'A2', 'ZARA 1', '5', 'location-3-s')
							}
							className={highlight('A2')}>
							<g id='LWPOLYLINE_6_'>
								<polygon
									className={SCSS.Hot}
									points='153.58,202.34 153.58,247.07 188.39,247.07 188.39,264.08 210.28,264.08 210.28,202.34 				'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 168.9545 219.9387)'
									className={SCSS.Number}>
									503
								</text>
								<text
									transform='matrix(1 0 0 1 170.5785 238.5819)'
									className={SCSS.PlanName}>
									A2
								</text>
							</g>
						</g>
						<g
							id='UINIT-504-C1_2_'
							onClick={() =>
								openFloorplan('504', 'C1', 'ZARA 1', '5', 'location-4')
							}
							className={highlight('C1')}>
							<g id='LWPOLYLINE_5_'>
								<polygon
									className={SCSS.Hot}
									points='81.76,280.15 81.76,297.55 85.54,297.55 85.58,329.29 210.28,329.29 210.28,264.08 142.24,264.08
					142.24,280.15 				'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 133.0652 300.5052)'
									className={SCSS.Number}>
									504
								</text>
								<text
									transform='matrix(1 0 0 1 135.802 319.1484)'
									className={SCSS.PlanName}>
									C1
								</text>
							</g>
						</g>
						<g
							id='UINIT-505-A1_2_'
							onClick={() =>
								openFloorplan('505', 'A1', 'ZARA 1', '5', 'location-3-n')
							}
							className={highlight('A1')}>
							<g id='LWPOLYLINE_4_'>
								<rect
									x='85.54'
									y='197.93'
									className={SCSS.Hot}
									width='56.7'
									height='37.8'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 100.8518 212.3459)'
									className={SCSS.Number}>
									505
								</text>
								<text
									transform='matrix(1 0 0 1 104.2668 230.9891)'
									className={SCSS.PlanName}>
									A1
								</text>
							</g>
						</g>
						<g
							id='UINIT-506-B2_2_'
							onClick={() =>
								openFloorplan('506', 'B2', 'ZARA 1', '5', 'location-3-n')
							}
							className={highlight('B2')}>
							<g id='LWPOLYLINE_3_'>
								<rect
									x='85.54'
									y='128.95'
									className={SCSS.Hot}
									width='56.7'
									height='68.98'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 100.7747 158.9545)'
									className={SCSS.Number}>
									506
								</text>
								<text
									transform='matrix(1 0 0 1 102.9075 177.5977)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							id='UINIT-507-A2_2_'
							onClick={() =>
								openFloorplan('507', 'A2', 'ZARA 1', '5', 'location-3-e')
							}
							className={highlight('A2')}>
							<g id='LWPOLYLINE_2_'>
								<polygon
									className={SCSS.Hot}
									points='142.24,128.95 142.24,83.59 108.07,83.59 108.07,66.89 85.54,66.89 85.54,128.95 				'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 101.2507 101.9907)'
									className={SCSS.Number}>
									507
								</text>
								<text
									transform='matrix(1 0 0 1 102.5388 120.6339)'
									className={SCSS.PlanName}>
									A2
								</text>
							</g>
						</g>
						<g
							id='UINIT-605-P1-L1_2_'
							onClick={() =>
								openFloorplan('605', 'P1', 'ZARA 1', '5', 'location-3')
							}
							className={highlight('P1')}>
							<g id='LWPOLYLINE_1_'>
								<rect
									x='85.54'
									y='235.73'
									className={SCSS.Hot}
									width='56.7'
									height='44.41'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 98.5071 273.6036)'
									className={SCSS.PlanName}>
									- L1
								</text>
								<text
									transform='matrix(1 0 0 1 104.3298 256.726)'
									className={SCSS.PlanName}>
									P1
								</text>
							</g>
						</g>
					</g>
				</g>
				<LakeLabel />
			</svg>
		</div>
	);
};

export default Level5;
