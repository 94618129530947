import IFloor from './IFloor';

import Floor_2_Image_1 from '../../views/location-3-e/floor-2/images/view_01.jpg';
import Floor_2_Image_2 from '../../views/location-3-e/floor-2/images/view_02.jpg';

import Floor_3_Image_1 from '../../views/location-3-e/floor-3/images/view_01.jpg';
import Floor_3_Image_2 from '../../views/location-3-e/floor-3/images/view_02.jpg';

import Floor_4_Image_1 from '../../views/location-3-e/floor-4/images/view_01.jpg';
import Floor_4_Image_2 from '../../views/location-3-e/floor-4/images/view_02.jpg';

import Floor_5_Image_1 from '../../views/location-3-e/floor-5/images/view_01.jpg';
import Floor_5_Image_2 from '../../views/location-3-e/floor-5/images/view_02.jpg';

import Floor_6_Image_1 from '../../views/location-3-e/floor-6/images/view_01.jpg';
import Floor_6_Image_2 from '../../views/location-3-e/floor-6/images/view_02.jpg';

const floor2: IFloor = {
	location: 'location-3-e',
	width: 1860,
	loop: false,
	direction: 'east',
	view: [
		{
			title: '207',
			order: '07',
			plan: 'A2',
			src: Floor_2_Image_1,
		},
		{
			title: '201',
			order: '01',
			plan: 'B2',
			src: Floor_2_Image_2,
		},
	],
};

const floor3: IFloor = {
	location: 'location-3-e',
	width: 1860,
	loop: false,
	direction: 'east',
	view: [
		{
			title: '307',
			order: '07',
			plan: 'A2',
			src: Floor_3_Image_1,
		},
		{
			title: '301',
			order: '01',
			plan: 'B2',
			src: Floor_3_Image_2,
		},
	],
};

const floor4: IFloor = {
	location: 'location-3-e',
	width: 1860,
	loop: false,
	direction: 'east',
	view: [
		{
			title: '407',
			order: '07',
			plan: 'A2',
			src: Floor_4_Image_1,
		},
		{
			title: '401',
			order: '01',
			plan: 'B2',
			src: Floor_4_Image_2,
		},
	],
};

const floor5: IFloor = {
	location: 'location-3-e',
	width: 1860,
	loop: false,
	direction: 'east',
	view: [
		{
			title: '507',
			order: '07',
			plan: 'A2',
			src: Floor_5_Image_1,
		},
		{
			title: '501',
			order: '01',
			plan: 'B2',
			src: Floor_5_Image_2,
		},
	],
};

const floor6: IFloor = {
	location: 'location-3-e',
	width: 1860,
	loop: false,
	direction: 'east',
	view: [
		{
			title: '607',
			order: '07',
			plan: 'A2',
			src: Floor_6_Image_1,
		},
		{
			title: '601',
			order: '01',
			plan: 'B2',
			src: Floor_6_Image_2,
		},
	],
};

const location3_E = {
	floor2,
	floor3,
	floor4,
	floor5,
	floor6,
};

export default location3_E;
