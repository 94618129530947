import React from 'react';
import {useSelector} from 'react-redux';
import Keys from '../Floorplans/Keys';
import Header from './GalleryHeader';
import Slidwshow from './Slideshow';
import SCSS from './Gallery.module.scss';
import {selectCurrentPlan} from '../../../redux/moduleSlice';
import PLANS, {IFloorplan} from '../../../constants/floorplans';

const _ = require('lodash');

type Props = {
	gotoFloorplan: () => void;
	gotoView: () => void;
	openFloorplan: (
		room: string,
		plan: string,
		building: string,
		level: string,
		viewLocation: string
	) => void;
};

const Gallery = (props: Props) => {
	const {gotoFloorplan, gotoView, openFloorplan} = props;
	const currentPlan = useSelector(selectCurrentPlan);
	const plan: IFloorplan = _.find(PLANS, ['name', currentPlan]);
	return (
		<div className={SCSS.Gallery}>
			<div className={SCSS.Left}>
				<div className={SCSS.Padding}>
					<Header plan={plan} gotoView={gotoView} />
					<Slidwshow plan={plan} />
				</div>
				<div className={SCSS.BackButton} onClick={gotoFloorplan}>
					Back to
					<br />
					Floorplan
				</div>
			</div>
			<div className={SCSS.Right}>
				<Keys openFloorplan={openFloorplan} />
			</div>
		</div>
	);
};

export default Gallery;
