/* eslint-disable no-nested-ternary */
import React, {useState} from 'react';
import {useAppDispatch} from '../../store';
import Splash from './Splash';
import PlanSelector from './PlanSelector';
import Floorplans from './Floorplans';
import Views from './Views';
import Gallery from './Gallery';
import SCSS from './PageFloorplans.module.scss';
import {updateCurrent} from '../../redux/moduleSlice';

const _ = require('lodash');

const PageFloorplans = () => {
	const dispatch = useAppDispatch();
	const [page, setPage] = useState('splash'); // splash | keyplate | floorplan | view | gallery | virtual

	const openFloorplan = (
		room: string,
		plan: string,
		building: string,
		level: string,
		viewLocation: string
	) => {
		dispatch(
			updateCurrent({
				room,
				plan,
				building,
				level,
				viewLocation,
				viewType: 'normal',
			})
		);
		setPage('floorplan');
	};

	const backToKeyplate = () => {
		setPage('keyplate');
	};

	const gotoView = () => {
		setPage('view');
	};

	const gotoGallery = () => {
		setPage('gallery');
	};

	const gotoFloorplan = () => {
		setPage('floorplan');
	};

	return (
		<div className={SCSS.PageFloorplans}>
			{page === 'splash' && <Splash backToKeyplate={backToKeyplate} />}
			{page === 'keyplate' && <PlanSelector openFloorplan={openFloorplan} />}
			{page === 'floorplan' && (
				<Floorplans
					backToKeyplate={backToKeyplate}
					gotoView={gotoView}
					gotoGallery={gotoGallery}
					openFloorplan={openFloorplan}
				/>
			)}
			{page === 'view' && <Views gotoFloorplan={gotoFloorplan} />}
			{page === 'gallery' && (
				<Gallery
					gotoFloorplan={gotoFloorplan}
					gotoView={gotoView}
					openFloorplan={openFloorplan}
				/>
			)}
		</div>
	);
};

export default PageFloorplans;
