import IUnit from './IUnit';
import location1 from '../views/location1';
import location2_E from '../views/location2_E';
import location2_W from '../views/location2_W';
// import location2_360 from '../views/location2_360';

// export interface IUnit {
//   unit: string;
//   plan?: string;
//   building?: string;
//   level?: string;
//   view?: IFloor;
//   // view360?: IFloor;
// }

const Units: Array<IUnit> = [
	/**
	 * LEVEL: 1
	 */
	{
		unit: '101',
		plan: 'B2',
		building: 'ZARA 2',
		level: '1',
		view: location2_E.floor1,
		// view360: location2_360.floor1,
	},
	{
		unit: '102',
		plan: 'B1',
		building: 'ZARA 2',
		level: '1',
		view: location2_E.floor1,
		// view360: location2_360.floor1,
	},
	{
		unit: '103',
		plan: 'B1',
		building: 'ZARA 2',
		level: '1',
		view: location2_E.floor1,
		// view360: location2_360.floor1,
	},
	{
		unit: '104',
		plan: 'B1',
		building: 'ZARA 2',
		level: '1',
		view: location1.floor1,
		// view360: location2_360.floor1,
	},
	{
		unit: '105',
		plan: 'B1',
		building: 'ZARA 2',
		level: '1',
		view: location2_W.floor1,
		// view360: location2_360.floor1,
	},
	{
		unit: '106',
		plan: 'B1',
		building: 'ZARA 2',
		level: '1',
		view: location2_W.floor1,
		// view360: location2_360.floor1,
	},
	{
		unit: '107',
		plan: 'B1',
		building: 'ZARA 2',
		level: '1',
		view: location2_W.floor1,
		// view360: location2_360.floor1,
	},
	{
		unit: '108',
		plan: 'A3',
		building: 'ZARA 2',
		level: '1',
		view: location2_W.floor1,
		// view360: location2_360.floor1,
	},
	/**
	 * LEVEL: 2
	 */
	{
		unit: '201',
		plan: 'A',
		building: 'ZARA 2',
		level: '2',
		view: location2_E.floor2,
		// view360: location2_360.floor2,
	},
	{
		unit: '202',
		plan: 'B2',
		building: 'ZARA 2',
		level: '2',
		view: location2_E.floor2,
		// view360: location2_360.floor2,
	},
	{
		unit: '203',
		plan: 'B1',
		building: 'ZARA 2',
		level: '2',
		view: location2_E.floor2,
		// view360: location2_360.floor2,
	},
	{
		unit: '204',
		plan: 'B1',
		building: 'ZARA 2',
		level: '2',
		view: location2_E.floor2,
		// view360: location2_360.floor2,
	},
	{
		unit: '205',
		plan: 'B1',
		building: 'ZARA 2',
		level: '2',
		view: location1.floor2,
		// view360: location2_360.floor2,
	},
	{
		unit: '206',
		plan: 'B1',
		building: 'ZARA 2',
		level: '2',
		view: location2_W.floor2,
		// view360: location2_360.floor2,
	},
	{
		unit: '207',
		plan: 'B1',
		building: 'ZARA 2',
		level: '2',
		view: location2_W.floor2,
		// view360: location2_360.floor2,
	},
	{
		unit: '208',
		plan: 'B1',
		building: 'ZARA 2',
		level: '2',
		view: location2_W.floor2,
		// view360: location2_360.floor2,
	},
	{
		unit: '209',
		plan: 'A2',
		building: 'ZARA 2',
		level: '2',
		view: location2_W.floor2,
		// view360: location2_360.floor2,
	},
	/**
	 * LEVEL: 3
	 */
	{
		unit: '301',
		plan: 'A',
		building: 'ZARA 2',
		level: '3',
		view: location2_E.floor3,
		// view360: location2_360.floor3,
	},
	{
		unit: '302',
		plan: 'B2',
		building: 'ZARA 2',
		level: '3',
		view: location2_E.floor3,
		// view360: location2_360.floor3,
	},
	{
		unit: '303',
		plan: 'B1',
		building: 'ZARA 2',
		level: '3',
		view: location2_E.floor3,
		// view360: location2_360.floor3,
	},
	{
		unit: '304',
		plan: 'B1',
		building: 'ZARA 2',
		level: '3',
		view: location2_E.floor3,
		// view360: location2_360.floor3,
	},
	{
		unit: '305',
		plan: 'B1',
		building: 'ZARA 2',
		level: '3',
		view: location1.floor3,
		// view360: location2_360.floor3,
	},
	{
		unit: '306',
		plan: 'B1',
		building: 'ZARA 2',
		level: '3',
		view: location2_W.floor3,
		// view360: location2_360.floor3,
	},
	{
		unit: '307',
		plan: 'B1',
		building: 'ZARA 2',
		level: '3',
		view: location2_W.floor3,
		// view360: location2_360.floor3,
	},
	{
		unit: '308',
		plan: 'B1',
		building: 'ZARA 2',
		level: '3',
		view: location2_W.floor3,
		// view360: location2_360.floor3,
	},
	{
		unit: '309',
		plan: 'A2',
		building: 'ZARA 2',
		level: '3',
		view: location2_W.floor3,
		// view360: location2_360.floor3,
	},
	/**
	 * LEVEL: 4
	 */
	{
		unit: '401',
		plan: 'A',
		building: 'ZARA 2',
		level: '4',
		view: location2_E.floor4,
		// view360: location2_360.floor4,
	},
	{
		unit: '402',
		plan: 'B2',
		building: 'ZARA 2',
		level: '4',
		view: location2_E.floor4,
		// view360: location2_360.floor4,
	},
	{
		unit: '403',
		plan: 'B1',
		building: 'ZARA 2',
		level: '4',
		view: location2_E.floor4,
		// view360: location2_360.floor4,
	},
	{
		unit: '404',
		plan: 'B1',
		building: 'ZARA 2',
		level: '4',
		view: location2_E.floor4,
		// view360: location2_360.floor4,
	},
	{
		unit: '405',
		plan: 'B1',
		building: 'ZARA 2',
		level: '4',
		view: location1.floor4,
		// view360: location2_360.floor4,
	},
	{
		unit: '406',
		plan: 'B1',
		building: 'ZARA 2',
		level: '4',
		view: location2_W.floor4,
		// view360: location2_360.floor4,
	},
	{
		unit: '407',
		plan: 'B1',
		building: 'ZARA 2',
		level: '4',
		view: location2_W.floor4,
		// view360: location2_360.floor4,
	},
	{
		unit: '408',
		plan: 'B1',
		building: 'ZARA 2',
		level: '4',
		view: location2_W.floor4,
		// view360: location2_360.floor4,
	},
	{
		unit: '409',
		plan: 'A2',
		building: 'ZARA 2',
		level: '4',
		view: location2_W.floor4,
		// view360: location2_360.floor4,
	},
	/**
	 * LEVEL: 5
	 */
	{
		unit: '501',
		plan: 'A',
		building: 'ZARA 2',
		level: '5',
		view: location2_E.floor5,
		// view360: location2_360.floor5,
	},
	{
		unit: '502',
		plan: 'B2',
		building: 'ZARA 2',
		level: '5',
		view: location2_E.floor5,
		// view360: location2_360.floor5,
	},
	{
		unit: '603',
		plan: 'P1',
		building: 'ZARA 2',
		level: '5',
		view: location2_E.floor5,
		// view360: location2_360.floor5,
	},
	{
		unit: '503',
		plan: 'A1',
		building: 'ZARA 2',
		level: '5',
		view: location2_E.floor5,
		// view360: location2_360.floor5,
	},
	{
		unit: '604',
		plan: 'P1',
		building: 'ZARA 2',
		level: '5',
		view: location2_E.floor5,
		// view360: location2_360.floor5,
	},
	{
		unit: '504',
		plan: 'A',
		building: 'ZARA 2',
		level: '5',
		view: location2_E.floor5,
		// view360: location2_360.floor5,
	},
	{
		unit: '505',
		plan: 'B1',
		building: 'ZARA 2',
		level: '5',
		view: location1.floor5,
		// view360: location2_360.floor5,
	},
	{
		unit: '605',
		plan: 'P2',
		building: 'ZARA 2',
		level: '5',
		view: location2_W.floor5,
		// view360: location2_360.floor5,
	},
	{
		unit: '606',
		plan: 'P1',
		building: 'ZARA 2',
		level: '5',
		view: location2_W.floor5,
		// view360: location2_360.floor5,
	},
	{
		unit: '506',
		plan: 'A1',
		building: 'ZARA 2',
		level: '5',
		view: location2_W.floor5,
		// view360: location2_360.floor5,
	},
	{
		unit: '607',
		plan: 'P1',
		building: 'ZARA 2',
		level: '5',
		view: location2_W.floor5,
		// view360: location2_360.floor5,
	},
	{
		unit: '507',
		plan: 'A1',
		building: 'ZARA 2',
		level: '5',
		view: location2_W.floor5,
		// view360: location2_360.floor5,
	},
	{
		unit: '508',
		plan: 'A2',
		building: 'ZARA 2',
		level: '5',
		view: location2_W.floor5,
		// view360: location2_360.floor5,
	},
	/**
	 * LEVEL: 6
	 */
	{
		unit: '601',
		plan: 'A',
		building: 'ZARA 2',
		level: '6',
		view: location2_E.floor6,
		// view360: location2_360.floor6,
	},
	{
		unit: '602',
		plan: 'B2',
		building: 'ZARA 2',
		level: '6',
		view: location2_E.floor6,
		// view360: location2_360.floor6,
	},
	{
		unit: '603',
		plan: 'P1',
		building: 'ZARA 2',
		level: '6',
		view: location2_E.floor6,
		// view360: location2_360.floor6,
	},
	{
		unit: '604',
		plan: 'P1',
		building: 'ZARA 2',
		level: '6',
		view: location2_E.floor6,
		// view360: location2_360.floor6,
	},
	{
		unit: '605',
		plan: 'P2',
		building: 'ZARA 2',
		level: '6',
		view: location1.floor6,
		// view360: location2_360.floor6,
	},
	{
		unit: '606',
		plan: 'P1',
		building: 'ZARA 2',
		level: '6',
		view: location2_W.floor6,
		// view360: location2_360.floor6,
	},
	{
		unit: '607',
		plan: 'P1',
		building: 'ZARA 2',
		level: '6',
		view: location2_W.floor6,
		// view360: location2_360.floor6,
	},
	{
		unit: '608',
		plan: 'A2',
		building: 'ZARA 2',
		level: '6',
		view: location2_W.floor6,
		// view360: location2_360.floor6,
	},
];

export default Units;
