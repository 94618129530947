import React from 'react';
import Pool from './Pool';
import BuildingLabel from './BuildingLabel';
import LakeLabel from './LakeLabel';
import SCSS from './Keys.module.scss';

type Props = {
	highlight: (plan: string) => string;
	openFloorplan: (
		room: string,
		plan: string,
		building: string,
		level: string,
		viewLocation: string
	) => void;
};

const Level4 = (props: Props) => {
	const {highlight, openFloorplan} = props;
	return (
		<div className={SCSS.Keyplate}>
			<svg viewBox='0 0 750 400'>
				<Pool />
				<g id='LINEWORKS' className={SCSS.Lineworks}>
					<g>
						<polyline points='107.98,66.8 142.16,66.8 142.16,83.49 		' />
						<polyline points='141.22,66.8 141.22,56.56 172.56,56.56 172.56,76.25 		' />
						<line x1='155.71' y1='76.25' x2='155.71' y2='56.56' />
						<line x1='153.5' y1='246.98' x2='153.5' y2='263.99' />
						<polyline
							points='210.23,306.83 221.33,306.8 220.96,354.36 148.03,351.79 74.97,354.72 74.35,299.59 85.46,299.57
			'
						/>
						<line x1='133.78' y1='66.8' x2='133.78' y2='83.49' />
						<line x1='131.89' y1='66.8' x2='131.89' y2='83.49' />
						<line x1='130' y1='66.8' x2='130' y2='83.49' />
						<line x1='128.11' y1='66.8' x2='128.11' y2='83.49' />
						<line x1='126.22' y1='66.8' x2='126.22' y2='83.49' />
						<line x1='124.33' y1='66.8' x2='124.33' y2='83.49' />
						<line x1='122.44' y1='66.8' x2='122.44' y2='83.49' />
						<line x1='120.55' y1='66.8' x2='120.55' y2='83.49' />
						<line x1='118.66' y1='66.8' x2='118.66' y2='83.49' />
						<line x1='116.77' y1='66.8' x2='116.77' y2='83.49' />
						<line x1='133.78' y1='75.14' x2='116.77' y2='75.14' />
						<line x1='180.17' y1='246.98' x2='180.17' y2='263.99' />
						<line x1='178.28' y1='246.98' x2='178.28' y2='263.99' />
						<line x1='176.39' y1='246.98' x2='176.39' y2='263.99' />
						<line x1='174.5' y1='246.98' x2='174.5' y2='263.99' />
						<line x1='172.61' y1='246.98' x2='172.61' y2='263.99' />
						<line x1='170.72' y1='246.98' x2='170.72' y2='263.99' />
						<line x1='168.83' y1='246.98' x2='168.83' y2='263.99' />
						<line x1='166.94' y1='246.98' x2='166.94' y2='263.99' />
						<line x1='165.05' y1='246.98' x2='165.05' y2='263.99' />
						<line x1='163.16' y1='246.98' x2='163.16' y2='263.99' />
						<line x1='180.17' y1='255.48' x2='163.16' y2='255.48' />
						<rect x='69.71' y='128.85' width='15.75' height='68.99' />
						<polygon points='85.46,128.85 85.46,66.8 120.55,66.8 120.55,52.22 65.96,52.22 69.71,128.85 		' />
						<polygon points='227.83,56.56 225.95,145.55 210.2,145.55 210.2,76.25 172.56,76.25 172.56,56.56 		' />
						<polygon points='227.83,270.13 225.95,202.25 210.2,202.25 210.2,270.13 		' />
						<polygon points='85.47,197.84 85.46,276.1 65.96,276.1 69.71,197.84 		' />
						<rect x='210.2' y='145.55' width='15.75' height='56.7' />
					</g>
					<g>
						<polyline points='577.75,86.8 594.13,86.8 594.13,106.49 		' />
						<line x1='594.13' y1='121.29' x2='577.75' y2='121.29' />
						<rect x='347.45' y='189.33' width='82.24' height='19.5' />
						<rect x='429.7' y='189.33' width='82.24' height='19.5' />
						<rect x='511.91' y='189.33' width='82.24' height='19.5' />
						<rect x='420.82' y='48.84' width='74.74' height='15.75' />
						<polygon points='495.56,48.84 590.09,48.84 590.09,86.8 577.75,86.8 577.75,64.59 495.56,64.59 		' />
						<rect x='344.39' y='48.84' width='54.9' height='15.75' />
						<polyline points='306.28,102.23 286.43,102.23 286.43,119.09 306.28,119.09 		' />
						<polyline points='286.43,119.09 281.71,119.09 281.71,102.23 286.43,102.23 		' />
						<line x1='285.49' y1='166.81' x2='285.49' y2='119.09' />
						<line x1='285.49' y1='132.63' x2='302.18' y2='132.63' />
						<rect x='281.71' y='189.33' width='65.84' height='19.5' />
						<polyline points='344.39,64.59 344.39,48.84 286.43,48.84 286.43,102.23 306.28,102.23 		' />
						<line x1='285.49' y1='140.77' x2='302.18' y2='140.77' />
						<line x1='285.49' y1='142.66' x2='302.18' y2='142.66' />
						<line x1='285.49' y1='144.55' x2='302.18' y2='144.55' />
						<line x1='285.49' y1='146.44' x2='302.18' y2='146.44' />
						<line x1='285.49' y1='148.33' x2='302.18' y2='148.33' />
						<line x1='285.49' y1='150.22' x2='302.18' y2='150.22' />
						<line x1='285.49' y1='152.11' x2='302.18' y2='152.11' />
						<line x1='285.49' y1='154' x2='302.18' y2='154' />
						<line x1='285.49' y1='155.89' x2='302.18' y2='155.89' />
						<line x1='285.49' y1='157.78' x2='302.18' y2='157.78' />
						<line x1='293.83' y1='140.77' x2='293.83' y2='157.78' />
						<line x1='577.81' y1='96.2' x2='594.19' y2='96.2' />
						<line x1='577.81' y1='98.09' x2='594.19' y2='98.09' />
						<line x1='577.81' y1='99.98' x2='594.19' y2='99.98' />
						<line x1='577.81' y1='101.87' x2='594.19' y2='101.87' />
						<line x1='577.81' y1='103.76' x2='594.19' y2='103.76' />
						<line x1='577.81' y1='105.65' x2='594.19' y2='105.65' />
						<line x1='577.81' y1='107.54' x2='594.19' y2='107.54' />
						<line x1='577.81' y1='109.43' x2='594.19' y2='109.43' />
						<line x1='577.81' y1='111.32' x2='594.19' y2='111.32' />
						<line x1='577.81' y1='113.21' x2='594.19' y2='113.21' />
						<line x1='586' y1='96.2' x2='586' y2='113.21' />
						<polygon
							points='677.97,95.24 677.97,200.58 617.72,200.58 617.72,189.33 650.51,189.33 650.51,106.49 617.72,106.49
			617.72,95.24 		'
						/>
						<rect x='281.71' y='172.48' width='3.78' height='16.85' />
					</g>
				</g>
				<g id='UNITS'>
					<g id='LEVEL-4-LABEL'>
						<text
							transform='matrix(1 0 0 1 85.4619 393.0516)'
							className={SCSS.LevelLabel}>
							LEVEL 4
						</text>
					</g>
					<BuildingLabel />
					<g id='LEVEL-4-ZARA-2_2_'>
						<g
							id='UINIT-401-A_2_'
							onClick={() =>
								openFloorplan('401', 'A', 'ZARA 2', '4', 'location-2-e')
							}
							className={highlight('A')}>
							<g id='LWPOLYLINE_108_'>
								<rect
									x='306.28'
									y='64.59'
									className={SCSS.Hot}
									width='38.06'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 314.0521 90.5999)'
									className={SCSS.Number}>
									401
								</text>
								<text
									transform='matrix(1 0 0 1 319.3051 109.2431)'
									className={SCSS.PlanName}>
									A
								</text>
							</g>
						</g>
						<g
							id='UINIT-402-B2_2_'
							onClick={() =>
								openFloorplan('402', 'B2', 'ZARA 2', '4', 'location-2-e')
							}
							className={highlight('B2')}>
							<g id='LWPOLYLINE_107_'>
								<rect
									x='344.33'
									y='64.59'
									className={SCSS.Hot}
									width='68.99'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 366.083 90.5999)'
									className={SCSS.Number}>
									402
								</text>
								<text
									transform='matrix(1 0 0 1 367.9497 109.2431)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							id='UINIT-403-B1_2_'
							onClick={() =>
								openFloorplan('403', 'B1', 'ZARA 2', '4', 'location-2-e')
							}
							className={highlight('B1')}>
							<g id='LWPOLYLINE_106_'>
								<rect
									x='413.32'
									y='64.59'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 441.6116 90.5999)'
									className={SCSS.Number}>
									403
								</text>
								<text
									transform='matrix(1 0 0 1 445.2483 109.2431)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							id='UINIT-404-B1_2_'
							onClick={() =>
								openFloorplan('404', 'B1', 'ZARA 2', '4', 'location-2-e')
							}
							className={highlight('B1')}>
							<g id='LWPOLYLINE_105_'>
								<rect
									x='495.53'
									y='64.59'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 523.8479 90.5999)'
									className={SCSS.Number}>
									404
								</text>
								<text
									transform='matrix(1 0 0 1 527.4636 109.2431)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							id='UINIT-405-B1_4_'
							onClick={() =>
								openFloorplan('405', 'B1', 'ZARA 2', '4', 'location-1')
							}
							className={highlight('B1')}>
							<g id='LWPOLYLINE_104_'>
								<rect
									x='594.13'
									y='106.49'
									className={SCSS.Hot}
									width='56.38'
									height='82.85'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 609.4441 143.6406)'
									className={SCSS.Number}>
									405
								</text>
								<text
									transform='matrix(1 0 0 1 613.1438 162.2838)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							id='UINIT-406-B1_2_'
							onClick={() =>
								openFloorplan('406', 'B1', 'ZARA 2', '4', 'location-2-w')
							}
							className={highlight('B1')}>
							<g id='LWPOLYLINE_103_'>
								<rect
									x='511.91'
									y='132.63'
									className={SCSS.Hot}
									width='82.22'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 540.0814 156.4257)'
									className={SCSS.Number}>
									406
								</text>
								<text
									transform='matrix(1 0 0 1 543.8583 175.0689)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							id='UINIT-407-B1_2_'
							onClick={() =>
								openFloorplan('407', 'B1', 'ZARA 2', '4', 'location-2-w')
							}
							className={highlight('B1')}>
							<g id='LWPOLYLINE_102_'>
								<rect
									x='429.7'
									y='132.63'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 458.3423 156.4257)'
									className={SCSS.Number}>
									407
								</text>
								<text
									transform='matrix(1 0 0 1 461.6431 175.0689)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							id='UINIT-408-B1_2_'
							onClick={() =>
								openFloorplan('408', 'B1', 'ZARA 2', '4', 'location-2-w')
							}
							className={highlight('B1')}>
							<g id='LWPOLYLINE_101_'>
								<rect
									x='347.48'
									y='132.63'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 377.3889 156.4257)'
									className={SCSS.Number}>
									408
								</text>
								<text
									transform='matrix(1 0 0 1 380.9558 175.0689)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							id='UINIT-409-A2_2_'
							onClick={() =>
								openFloorplan('409', 'A2', 'ZARA 2', '4', 'location-2-w')
							}
							className={highlight('A2')}>
							<g id='LWPOLYLINE_100_'>
								<polygon
									className={SCSS.Hot}
									points='347.54,189.33 285.49,189.33 285.49,166.81 302.18,166.81 302.18,132.63 347.48,132.63 				'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 311.8784 156.4257)'
									className={SCSS.Number}>
									409
								</text>
								<text
									transform='matrix(1 0 0 1 313.5586 175.0689)'
									className={SCSS.PlanName}>
									A2
								</text>
							</g>
						</g>
					</g>
					<g id='LEVEL-4-ZARA-1_2_'>
						<g
							id='UINIT-401-B2_2_'
							onClick={() =>
								openFloorplan('401', 'B2', 'ZARA 1', '4', 'location-3-e')
							}
							className={highlight('B2')}>
							<g id='LWPOLYLINE_99_'>
								<rect
									x='153.5'
									y='76.25'
									className={SCSS.Hot}
									width='56.7'
									height='69.3'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 170.5995 105.6932)'
									className={SCSS.Number}>
									401
								</text>
								<text
									transform='matrix(1 0 0 1 170.9471 124.3364)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							id='UINIT-402-A4_2_'
							onClick={() =>
								openFloorplan('402', 'A4', 'ZARA 1', '4', 'location-3-s')
							}
							className={highlight('A4')}>
							<g id='LWPOLYLINE_98_'>
								<rect
									x='153.5'
									y='145.55'
									className={SCSS.Hot}
									width='56.7'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 169.0804 168.6725)'
									className={SCSS.Number}>
									402
								</text>
								<text
									transform='matrix(1 0 0 1 170.3265 187.3157)'
									className={SCSS.PlanName}>
									A4
								</text>
							</g>
						</g>
						<g
							id='UINIT-403-A2_2_'
							onClick={() =>
								openFloorplan('403', 'A2', 'ZARA 1', '4', 'location-3-s')
							}
							className={highlight('A2')}>
							<g id='LWPOLYLINE_97_'>
								<polygon
									className={SCSS.Hot}
									points='153.5,202.25 153.5,246.98 188.31,246.98 188.31,263.99 210.2,263.99 210.2,202.25 				'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 169.0385 219.9387)'
									className={SCSS.Number}>
									403
								</text>
								<text
									transform='matrix(1 0 0 1 170.5785 238.5818)'
									className={SCSS.PlanName}>
									A2
								</text>
							</g>
						</g>
						<g
							id='UINIT-404-C1_2_'
							onClick={() =>
								openFloorplan('404', 'C1', 'ZARA 1', '4', 'location-4')
							}
							className={highlight('C1')}>
							<g id='LWPOLYLINE_96_'>
								<polygon
									className={SCSS.Hot}
									points='81.68,280.05 81.68,297.46 85.46,297.46 85.5,329.19 210.2,329.19 210.2,263.99 142.16,263.99
					142.16,280.05 				'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 133.1492 300.5052)'
									className={SCSS.Number}>
									404
								</text>
								<text
									transform='matrix(1 0 0 1 135.802 319.1484)'
									className={SCSS.PlanName}>
									C1
								</text>
							</g>
						</g>
						<g
							id='UINIT-405-B1_1_'
							onClick={() =>
								openFloorplan('405', 'B1', 'ZARA 1', '4', 'location-3-n')
							}
							className={highlight('B1')}>
							<g id='LWPOLYLINE_95_'>
								<rect
									x='85.46'
									y='197.84'
									className={SCSS.Hot}
									width='56.7'
									height='82.21'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 100.9358 235.2782)'
									className={SCSS.Number}>
									405
								</text>
								<text
									transform='matrix(1 0 0 1 104.6355 253.9214)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							id='UINIT-406-B2_2_'
							onClick={() =>
								openFloorplan('406', 'B2', 'ZARA 1', '4', 'location-3-n')
							}
							className={highlight('B2')}>
							<g id='LWPOLYLINE_94_'>
								<rect
									x='85.46'
									y='128.85'
									className={SCSS.Hot}
									width='56.7'
									height='68.98'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 100.8586 158.9545)'
									className={SCSS.Number}>
									406
								</text>
								<text
									transform='matrix(1 0 0 1 102.9075 177.5977)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							id='UINIT-407-A2_2_'
							onClick={() =>
								openFloorplan('407', 'A2', 'ZARA 1', '4', 'location-3-e')
							}
							className={highlight('A2')}>
							<g id='LWPOLYLINE_93_'>
								<polygon
									className={SCSS.Hot}
									points='142.16,128.85 142.16,83.49 107.98,83.49 107.98,66.8 85.46,66.8 85.46,128.85 				'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 101.3347 101.9907)'
									className={SCSS.Number}>
									407
								</text>
								<text
									transform='matrix(1 0 0 1 102.5388 120.6339)'
									className={SCSS.PlanName}>
									A2
								</text>
							</g>
						</g>
					</g>
				</g>
				<LakeLabel />
			</svg>
		</div>
	);
};

export default Level4;
