import React from 'react';
import SCSS from './Keys.module.scss';

type Props = {
	openFloorplan: (
		room: string,
		plan: string,
		building: string,
		level: string,
		location: string
	) => void;
	highlight: (r: string, p: string, b: string) => string;
};

const Level2 = (props: Props) => {
	const {openFloorplan, highlight} = props;
	return (
		<div className={SCSS.Keyplate}>
			<svg viewBox='0 0 650 400'>
				<g id='POOL' className={SCSS.Lineworks}>
					<g>
						<linearGradient
							id='SVGID_1_'
							gradientUnits='userSpaceOnUse'
							x1='328.6905'
							y1='359.7091'
							x2='328.6905'
							y2='255.4724'>
							<stop offset='0' style={{stopColor: '#FFFFFF'}} />
							<stop offset='1' style={{stopColor: '#999999'}} />
						</linearGradient>
						<path
							className={SCSS.Pool}
							d='M408.06,273.28h-27.78v-17.8H234.6v104.24c45.02,0.02,86.12-7.06,122.3-17.35v-86.88h23.39v79.59
			c15.25-5.19,29.47-10.86,42.5-16.67L408.06,273.28z'
						/>
						<polygon
							points='226.74,255.47 226.74,247.62 196.58,247.62 196.58,397.62 234.6,397.62 234.6,359.71 234.6,255.47
			234.6,255.47 		'
						/>
						<rect x='356.89' y='255.47' width='23.39' height='100.84' />
						<line x1='234.6' y1='255.47' x2='356.89' y2='255.47' />
						<path d='M356.89,342.36c-36.17,10.29-77.28,17.37-122.3,17.35' />
						<path d='M380.28,273.28h27.78l14.72,45.12c-13.03,5.82-27.25,11.48-42.5,16.67' />
						<rect x='202.23' y='372.22' width='26.72' height='20.84' />
						<rect x='207.48' y='353.13' width='16.22' height='11.93' />
						<rect x='361.91' y='260.88' width='13.36' height='11.77' />
					</g>
				</g>

				<g id='LINEWORKS' className={SCSS.Lineworks}>
					<g>
						<polyline points='65.3,66.73 99.48,66.73 99.48,83.43 		' />
						<polyline points='98.54,66.73 98.54,56.5 129.88,56.5 129.88,76.18 		' />
						<line x1='113.03' y1='76.18' x2='113.03' y2='56.5' />
						<line x1='110.82' y1='246.91' x2='110.82' y2='263.92' />
						<polyline
							points='167.54,306.77 178.65,306.74 178.27,354.3 105.35,351.72 32.29,354.66 31.67,299.53 42.78,299.51
			'
						/>
						<line x1='91.1' y1='66.73' x2='91.1' y2='83.43' />
						<line x1='89.21' y1='66.73' x2='89.21' y2='83.43' />
						<line x1='87.32' y1='66.73' x2='87.32' y2='83.43' />
						<line x1='85.43' y1='66.73' x2='85.43' y2='83.43' />
						<line x1='83.54' y1='66.73' x2='83.54' y2='83.43' />
						<line x1='81.65' y1='66.73' x2='81.65' y2='83.43' />
						<line x1='79.76' y1='66.73' x2='79.76' y2='83.43' />
						<line x1='77.87' y1='66.73' x2='77.87' y2='83.43' />
						<line x1='75.98' y1='66.73' x2='75.98' y2='83.43' />
						<line x1='74.09' y1='66.73' x2='74.09' y2='83.43' />
						<line x1='91.1' y1='75.08' x2='74.09' y2='75.08' />
						<line x1='137.49' y1='246.91' x2='137.49' y2='263.92' />
						<line x1='135.6' y1='246.91' x2='135.6' y2='263.92' />
						<line x1='133.71' y1='246.91' x2='133.71' y2='263.92' />
						<line x1='131.82' y1='246.91' x2='131.82' y2='263.92' />
						<line x1='129.93' y1='246.91' x2='129.93' y2='263.92' />
						<line x1='128.04' y1='246.91' x2='128.04' y2='263.92' />
						<line x1='126.15' y1='246.91' x2='126.15' y2='263.92' />
						<line x1='124.26' y1='246.91' x2='124.26' y2='263.92' />
						<line x1='122.37' y1='246.91' x2='122.37' y2='263.92' />
						<line x1='120.48' y1='246.91' x2='120.48' y2='263.92' />
						<line x1='137.49' y1='255.42' x2='120.48' y2='255.42' />
						<rect x='27.03' y='128.79' width='15.75' height='68.99' />
						<polygon points='42.78,128.79 42.78,66.73 77.87,66.73 77.87,52.16 23.28,52.16 27.03,128.79 		' />
						<polygon points='185.15,56.5 183.27,145.48 167.52,145.48 167.52,76.18 129.88,76.18 129.88,56.5 		' />
						<polygon points='185.14,270.07 183.27,202.18 167.52,202.18 167.52,270.07 		' />
						<polygon points='42.79,197.77 42.78,276.04 23.28,276.04 27.02,197.77 		' />
						<rect x='167.52' y='145.48' width='15.75' height='56.7' />
					</g>
					<g>
						<polyline points='535.07,86.74 551.45,86.74 551.45,106.42 		' />
						<line x1='551.45' y1='121.23' x2='535.07' y2='121.23' />
						<rect x='304.77' y='189.27' width='82.24' height='19.5' />
						<rect x='387.02' y='189.27' width='82.24' height='19.5' />
						<rect x='469.23' y='189.27' width='82.24' height='19.5' />
						<rect x='378.14' y='48.78' width='74.74' height='15.75' />
						<polygon points='452.88,48.78 547.41,48.78 547.41,86.74 535.07,86.74 535.07,64.53 452.88,64.53 		' />
						<rect x='301.71' y='48.78' width='54.9' height='15.75' />
						<polyline points='263.6,102.17 243.75,102.17 243.75,119.02 263.6,119.02 		' />
						<polyline points='243.75,119.02 239.03,119.02 239.03,102.17 243.75,102.17 		' />
						<line x1='242.81' y1='166.75' x2='242.81' y2='119.02' />
						<line x1='242.81' y1='132.57' x2='259.5' y2='132.57' />
						<rect x='239.03' y='189.27' width='65.84' height='19.5' />
						<polyline points='301.71,64.53 301.71,48.78 243.75,48.78 243.75,102.17 263.6,102.17 		' />
						<line x1='242.81' y1='140.7' x2='259.5' y2='140.7' />
						<line x1='242.81' y1='142.59' x2='259.5' y2='142.59' />
						<line x1='242.81' y1='144.48' x2='259.5' y2='144.48' />
						<line x1='242.81' y1='146.37' x2='259.5' y2='146.37' />
						<line x1='242.81' y1='148.26' x2='259.5' y2='148.26' />
						<line x1='242.81' y1='150.15' x2='259.5' y2='150.15' />
						<line x1='242.81' y1='152.04' x2='259.5' y2='152.04' />
						<line x1='242.81' y1='153.93' x2='259.5' y2='153.93' />
						<line x1='242.81' y1='155.82' x2='259.5' y2='155.82' />
						<line x1='242.81' y1='157.71' x2='259.5' y2='157.71' />
						<line x1='251.15' y1='140.7' x2='251.15' y2='157.71' />
						<line x1='535.13' y1='96.14' x2='551.51' y2='96.14' />
						<line x1='535.13' y1='98.03' x2='551.51' y2='98.03' />
						<line x1='535.13' y1='99.92' x2='551.51' y2='99.92' />
						<line x1='535.13' y1='101.81' x2='551.51' y2='101.81' />
						<line x1='535.13' y1='103.7' x2='551.51' y2='103.7' />
						<line x1='535.13' y1='105.59' x2='551.51' y2='105.59' />
						<line x1='535.13' y1='107.48' x2='551.51' y2='107.48' />
						<line x1='535.13' y1='109.37' x2='551.51' y2='109.37' />
						<line x1='535.13' y1='111.26' x2='551.51' y2='111.26' />
						<line x1='535.13' y1='113.15' x2='551.51' y2='113.15' />
						<line x1='543.32' y1='96.14' x2='543.32' y2='113.15' />
						<polygon
							points='635.29,95.17 635.29,200.52 575.04,200.52 575.04,189.27 607.83,189.27 607.83,106.42 575.04,106.42
			575.04,95.17 		'
						/>
						<rect x='239.03' y='172.42' width='3.78' height='16.85' />
					</g>
				</g>
				<g id='UNITS'>
					<g id='LEVEL-2-LABEL'>
						<text
							transform='matrix(1 0 0 1 472.0443 329.0294)'
							className={SCSS.LevelLabel}>
							LEVEL 2
						</text>
					</g>
					<g id='LEVEL-2-BUILDING-LABEL'>
						<text
							transform='matrix(1 0 0 1 80.4454 16.9003)'
							className={SCSS.BuildingLabel}>
							ZARA 1
						</text>
						<text
							transform='matrix(1 0 0 1 386.6978 16.9003)'
							className={SCSS.BuildingLabel}>
							ZARA 2
						</text>
					</g>
					<g id='LEVEL-2-ZARA-2'>
						<g
							className={highlight('201', 'A', 'ZARA 2')}
							onClick={() =>
								openFloorplan('201', 'A', 'ZARA 2', '2', 'location-2-e')
							}
							id='UINIT-201-A'>
							<g id='LWPOLYLINE_556_'>
								<rect
									x='263.6'
									y='64.53'
									className={SCSS.Hot}
									width='38.06'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.75)'>
								<text
									transform='matrix(1 0 0 1 271.3051 93.2431)'
									className={SCSS.Number}>
									201
								</text>
								<text
									transform='matrix(1 0 0 1 276.3051 113.2431)'
									className={SCSS.PlanName}>
									A
								</text>
							</g>
						</g>
						<g
							className={highlight('202', 'B2', 'ZARA 2')}
							onClick={() =>
								openFloorplan('202', 'B2', 'ZARA 2', '2', 'location-2-e')
							}
							id='UINIT-202-B2'>
							<g id='LWPOLYLINE_557_'>
								<rect
									x='301.65'
									y='64.53'
									className={SCSS.Hot}
									width='68.99'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.75)'>
								<text
									transform='matrix(1 0 0 1 321.9497 93.2431)'
									className={SCSS.Number}>
									202
								</text>
								<text
									transform='matrix(1 0 0 1 324.9497 113.2431)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							className={highlight('203', 'B1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('203', 'B1', 'ZARA 2', '2', 'location-2-e')
							}
							id='UINIT-203-B1'>
							<g id='LWPOLYLINE_558_'>
								<rect
									x='370.64'
									y='64.53'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.75)'>
								<text
									transform='matrix(1 0 0 1 399.2483 93.2431)'
									className={SCSS.Number}>
									203
								</text>
								<text
									transform='matrix(1 0 0 1 402.2483 113.2431)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							className={highlight('204', 'B1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('204', 'B1', 'ZARA 2', '2', 'location-2-e')
							}
							id='UINIT-204-B1'>
							<g id='LWPOLYLINE_559_'>
								<rect
									x='452.85'
									y='64.53'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.75)'>
								<text
									transform='matrix(1 0 0 1 481.4636 93.2431)'
									className={SCSS.Number}>
									204
								</text>
								<text
									transform='matrix(1 0 0 1 484.4636 113.2431)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							className={highlight('205', 'B1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('205', 'B1', 'ZARA 2', '2', 'location-1')
							}
							id='UINIT-205-B1'>
							<g id='LWPOLYLINE_560_'>
								<rect
									x='551.45'
									y='106.42'
									className={SCSS.Hot}
									width='56.38'
									height='82.85'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.75)'>
								<text
									transform='matrix(1 0 0 1 567.1438 146.2838)'
									className={SCSS.Number}>
									205
								</text>
								<text
									transform='matrix(1 0 0 1 570.1438 166.2838)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							className={highlight('206', 'B1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('206', 'B1', 'ZARA 2', '2', 'location-2-w')
							}
							id='UINIT-206-B1'>
							<g id='LWPOLYLINE_561_'>
								<rect
									x='469.23'
									y='132.57'
									className={SCSS.Hot}
									width='82.22'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 497.8583 158.0689)'
									className={SCSS.Number}>
									206
								</text>
								<text
									transform='matrix(1 0 0 1 500.8583 178.0689)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							className={highlight('207', 'B1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('207', 'B1', 'ZARA 2', '2', 'location-2-w')
							}
							id='UINIT-207-B1'>
							<g id='LWPOLYLINE_562_'>
								<rect
									x='387.02'
									y='132.57'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 415.6431 158.0689)'
									className={SCSS.Number}>
									207
								</text>
								<text
									transform='matrix(1 0 0 1 418.6431 178.0689)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							className={highlight('208', 'B1', 'ZARA 2')}
							onClick={() =>
								openFloorplan('208', 'B1', 'ZARA 2', '2', 'location-2-w')
							}
							id='UINIT-208-B1'>
							<g id='LWPOLYLINE_563_'>
								<rect
									x='304.8'
									y='132.57'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 334.9558 158.0689)'
									className={SCSS.Number}>
									208
								</text>
								<text
									transform='matrix(1 0 0 1 337.9558 178.0689)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							className={highlight('209', 'A2', 'ZARA 2')}
							onClick={() =>
								openFloorplan('209', 'A2', 'ZARA 2', '2', 'location-2-w')
							}
							id='UINIT-209-A2'>
							<g id='LWPOLYLINE_555_'>
								<polygon
									className={SCSS.Hot}
									points='304.86,189.27 242.81,189.27 242.81,166.75 259.5,166.75 259.5,132.57 304.8,132.57 				'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 267.5586 158.0689)'
									className={SCSS.Number}>
									209
								</text>
								<text
									transform='matrix(1 0 0 1 270.5586 178.0689)'
									className={SCSS.PlanName}>
									A2
								</text>
							</g>
						</g>
					</g>
					<g id='LEVEL-2-ZARA-1'>
						<g
							className={highlight('201', 'B2', 'ZARA 1')}
							onClick={() =>
								openFloorplan('201', 'B2', 'ZARA 1', '2', 'location-3-e')
							}
							id='UINIT-201-B2'>
							<g id='LWPOLYLINE_569_'>
								<rect
									x='110.82'
									y='76.18'
									className={SCSS.Hot}
									width='56.7'
									height='69.3'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 126.9471 108.3364)'
									className={SCSS.Number}>
									201
								</text>
								<text
									transform='matrix(1 0 0 1 127.9471 128.3364)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							className={highlight('202', 'A4', 'ZARA 1')}
							onClick={() =>
								openFloorplan('202', 'A4', 'ZARA 1', '2', 'location-3-s')
							}
							id='UINIT-202-A4'>
							<g id='LWPOLYLINE_570_'>
								<rect
									x='110.82'
									y='145.48'
									className={SCSS.Hot}
									width='56.7'
									height='56.7'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 126.3265 171.3157)'
									className={SCSS.Number}>
									202
								</text>
								<text
									transform='matrix(1 0 0 1 127.3265 191.3157)'
									className={SCSS.PlanName}>
									A4
								</text>
							</g>
						</g>
						<g
							className={highlight('203', 'A2', 'ZARA 1')}
							onClick={() =>
								openFloorplan('203', 'A2', 'ZARA 1', '2', 'location-3-s')
							}
							id='UINIT-203-A2'>
							<g id='LWPOLYLINE_565_'>
								<polygon
									className={SCSS.Hot}
									points='110.82,202.18 110.82,246.91 145.63,246.91 145.63,263.92 167.52,263.92 167.52,202.18 				'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 126.5785 222.5819)'
									className={SCSS.Number}>
									203
								</text>
								<text
									transform='matrix(1 0 0 1 127.5785 242.5819)'
									className={SCSS.PlanName}>
									A2
								</text>
							</g>
						</g>
						<g
							className={highlight('204', 'C1', 'ZARA 1')}
							onClick={() =>
								openFloorplan('204', 'C1', 'ZARA 1', '2', 'location-4')
							}
							id='UINIT-204-C1'>
							<g id='LWPOLYLINE_566_'>
								<polygon
									className={SCSS.Hot}
									points='39,279.99 39,297.39 42.78,297.39 42.82,329.13 167.52,329.13 167.52,263.92 99.48,263.92
					99.48,279.99 				'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 90.802 303.1484)'
									className={SCSS.Number}>
									204
								</text>
								<text
									transform='matrix(1 0 0 1 92.802 323.1484)'
									className={SCSS.PlanName}>
									C1
								</text>
							</g>
						</g>
						<g
							className={highlight('205', 'B1', 'ZARA 1')}
							onClick={() =>
								openFloorplan('205', 'B1', 'ZARA 1', '2', 'location-3-n')
							}
							id='UINIT-205-B1_1_'>
							<g id='LWPOLYLINE_567_'>
								<rect
									x='42.78'
									y='197.77'
									className={SCSS.Hot}
									width='56.7'
									height='82.21'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 58.6355 237.9214)'
									className={SCSS.Number}>
									205
								</text>
								<text
									transform='matrix(1 0 0 1 61.6355 257.9214)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							className={highlight('206', 'B2', 'ZARA 1')}
							onClick={() =>
								openFloorplan('206', 'B2', 'ZARA 1', '2', 'location-3-n')
							}
							id='UINIT-206-B2'>
							<g id='LWPOLYLINE_568_'>
								<rect
									x='42.78'
									y='128.79'
									className={SCSS.Hot}
									width='56.7'
									height='68.98'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 57.9075 161.5977)'
									className={SCSS.Number}>
									206
								</text>
								<text
									transform='matrix(1 0 0 1 59.9075 181.5977)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							className={highlight('207', 'A2', 'ZARA 1')}
							onClick={() =>
								openFloorplan('207', 'A2', 'ZARA 1', '2', 'location-3-e')
							}
							id='UINIT-207-A2'>
							<g id='LWPOLYLINE_564_'>
								<polygon
									className={SCSS.Hot}
									points='99.48,128.79 99.48,83.43 65.3,83.43 65.3,66.73 42.78,66.73 42.78,128.79 				'
								/>
							</g>
							<g transform='matrix(1 0 0 1 0 -2.5)'>
								<text
									transform='matrix(1 0 0 1 57.5388 104.6339)'
									className={SCSS.Number}>
									207
								</text>
								<text
									transform='matrix(1 0 0 1 59.5388 124.6339)'
									className={SCSS.PlanName}>
									A2
								</text>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</div>
	);
};

export default Level2;
