import React from 'react';
import SCSS from './Keys.module.scss';

const BuildingLabel = () => {
	return (
		<g>
			<text
				transform='matrix(1 0 0 1 80.4454 16.9003)'
				className={SCSS.BuildingLabel}>
				ZARA 1
			</text>
			<text
				transform='matrix(1 0 0 1 386.6978 16.9003)'
				className={SCSS.BuildingLabel}>
				ZARA 2
			</text>
		</g>
	);
};

export default BuildingLabel;
