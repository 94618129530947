import SCSS from './App.module.scss';
import PageFloorplans from './PageFloorplans';

function App() {
	return (
		<div className={SCSS.App}>
			<PageFloorplans />
		</div>
	);
}

export default App;
