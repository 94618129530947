import planA from '../floorplans/plan_A.svg';
import planA1 from '../floorplans/plan_A1.svg';
import planA2 from '../floorplans/plan_A2.svg';
import planA3 from '../floorplans/plan_A3.svg';
import planA4 from '../floorplans/plan_A4.svg';
import planB1 from '../floorplans/plan_B1.svg';
import planB2 from '../floorplans/plan_B2.svg';
import planC1 from '../floorplans/plan_C1.svg';
import planP1 from '../floorplans/plan_P1.svg';
import planP2 from '../floorplans/plan_P2.svg';

import A2Image1 from '../gallery/interiors/a2-living.jpg';
import A2Image2 from '../gallery/interiors/a2-kitchen-sky.jpg';
import A2Image3 from '../gallery/interiors/a2-kitchen-beach.jpg';
import A2Image4 from '../gallery/interiors/a2-kitchen-stone.jpg';
import A2Image5 from '../gallery/interiors/a2-ensuite.jpg';

import B1Image1 from '../gallery/interiors/b1-living.jpg';
import B1Image2 from '../gallery/interiors/b1-kitchen-sky.jpg';
import B1Image3 from '../gallery/interiors/b1-kitchen-beach.jpg';
import B1Image4 from '../gallery/interiors/b1-kitchen-stone.jpg';

import C1Image1 from '../gallery/interiors/c1-living.jpg';
import C1Image2 from '../gallery/interiors/c1-kitchen-sky.jpg';
import C1Image3 from '../gallery/interiors/c1-kitchen-beach.jpg';
import C1Image4 from '../gallery/interiors/c1-kitchen-stone.jpg';
import C1Image5 from '../gallery/balcony.jpg';

export interface IFloorplan {
	name: string;
	interior: string;
	exterior: string;
	total: string;
	bed: string | number;
	bath: string | number;
	jr: boolean;
	den?: boolean;
	gallery?: Array<{
		title: string;
		src: string;
	}>;
	virtual?: boolean;
	src: string;
	draft?: boolean;
	levels: {
		zara1: Array<number>;
		zara2: Array<number>;
	};
}

const Floorplans: Array<IFloorplan> = [
	{
		name: 'A',
		interior: '600 sf',
		exterior: '372-479 sf',
		total: '972-1079 sf',
		bed: 1,
		bath: 1,
		jr: false,
		virtual: false,
		src: planA,
		draft: true,
		levels: {
			zara1: [],
			zara2: [2, 3, 4, 5, 6],
		},
	},
	{
		name: 'A1',
		interior: '600 sf',
		exterior: '136-170 sf',
		total: '736-770 sf',
		bed: 1,
		bath: 1,
		jr: false,
		virtual: false,
		src: planA1,
		draft: true,
		levels: {
			zara1: [5],
			zara2: [5],
		},
	},
	{
		name: 'A2',
		interior: '814 sf',
		exterior: '270-499 sf',
		total: '1084-1313 sf',
		bed: 2,
		bath: 1,
		jr: true,
		gallery: [
			{title: 'Plan A2 Living Room', src: A2Image1},
			{title: 'Plan A2 Kitchen (Sky)', src: A2Image2},
			{title: 'Plan A2 Kitchen (Beach)', src: A2Image3},
			{title: 'Plan A2 Kitchen (Stone)', src: A2Image4},
			{title: 'Plan A2 Ensuite', src: A2Image5},
		],
		virtual: false,
		src: planA2,
		draft: true,
		levels: {
			zara1: [2, 3, 4, 5, 6],
			zara2: [2, 3, 4, 5, 6],
		},
	},
	{
		name: 'A3',
		interior: '710 sf',
		exterior: '214 sf',
		total: '924 sf',
		bed: 1,
		bath: 1,
		jr: false,
		virtual: false,
		src: planA3,
		draft: true,
		levels: {
			zara1: [],
			zara2: [1],
		},
	},
	{
		name: 'A4',
		interior: '900 sf',
		exterior: '210-269 sf',
		total: '1110-1169 sf',
		bed: 2,
		bath: 1.5,
		jr: true,
		virtual: false,
		src: planA4,
		draft: true,
		levels: {
			zara1: [2, 3, 4, 6],
			zara2: [],
		},
	},
	{
		name: 'B1',
		interior: '1305 sf',
		exterior: '269-793 sf',
		total: '1574-2098 sf',
		bed: 3,
		bath: 2.5,
		jr: true,
		gallery: [
			{title: 'Plan B1 Living Room', src: B1Image1},
			{title: 'Plan B1 Kitchen (Sky)', src: B1Image2},
			{title: 'Plan B1 Kitchen (Beach)', src: B1Image3},
			{title: 'Plan B1 Kitchen (Stone)', src: B1Image4},
		],
		virtual: false,
		src: planB1,
		draft: true,
		levels: {
			zara1: [2, 3, 4],
			zara2: [1, 2, 3, 4, 5],
		},
	},
	{
		name: 'B2',
		interior: '1095 sf',
		exterior: '197-570 sf',
		total: '1292-1665 sf',
		bed: 2,
		bath: 2,
		jr: false,
		virtual: false,
		src: planB2,
		draft: true,
		levels: {
			zara1: [2, 3, 4, 5, 6],
			zara2: [1, 2, 3, 4, 5, 6],
		},
	},
	{
		name: 'C1',
		interior: '2006 sf',
		exterior: '708-936 sf',
		total: '2714-2942 sf',
		bed: 3,
		bath: 2.5,
		jr: false,
		gallery: [
			{title: 'Plan C1 Living Room', src: C1Image1},
			{title: 'Plan C1 Kitchen (Sky)', src: C1Image2},
			{title: 'Plan C1 Kitchen (Beach)', src: C1Image3},
			{title: 'Plan C1 Kitchen (Stone)', src: C1Image4},
			{title: 'Plan C1 Balcony', src: C1Image5},
		],
		src: planC1,
		draft: true,
		levels: {
			zara1: [2, 3, 4, 5, 6],
			zara2: [],
		},
	},
	{
		name: 'P1',
		interior: '2010 sf',
		exterior: '423-656 sf',
		total: '2433-2666 sf',
		bed: 3,
		bath: 2.5,
		jr: false,
		den: true,
		virtual: false,
		src: planP1,
		draft: true,
		levels: {
			zara1: [5, 6],
			zara2: [5, 6],
		},
	},
	{
		name: 'P2',
		interior: '2610 sf',
		exterior: '1556 sf',
		total: '4166 sf',
		bed: 3,
		bath: 3.5,
		jr: false,
		den: true,
		virtual: false,
		src: planP2,
		draft: true,
		levels: {
			zara1: [5, 6],
			zara2: [5, 6],
		},
	},
];

export default Floorplans;
