import IFloor from './IFloor';

import Floor_1_Image_1 from '../../views/location-2-w/floor-1/images/view_01.jpg';
import Floor_1_Image_2 from '../../views/location-2-w/floor-1/images/view_02.jpg';
import Floor_1_Image_3 from '../../views/location-2-w/floor-1/images/view_03.jpg';
import Floor_1_Image_4 from '../../views/location-2-w/floor-1/images/view_04.jpg';

import Floor_2_Image_1 from '../../views/location-2-w/floor-2/images/view_01.jpg';
import Floor_2_Image_2 from '../../views/location-2-w/floor-2/images/view_02.jpg';
import Floor_2_Image_3 from '../../views/location-2-w/floor-2/images/view_03.jpg';
import Floor_2_Image_4 from '../../views/location-2-w/floor-2/images/view_04.jpg';

import Floor_3_Image_1 from '../../views/location-2-w/floor-3/images/view_01.jpg';
import Floor_3_Image_2 from '../../views/location-2-w/floor-3/images/view_02.jpg';
import Floor_3_Image_3 from '../../views/location-2-w/floor-3/images/view_03.jpg';
import Floor_3_Image_4 from '../../views/location-2-w/floor-3/images/view_04.jpg';

import Floor_4_Image_1 from '../../views/location-2-w/floor-4/images/view_01.jpg';
import Floor_4_Image_2 from '../../views/location-2-w/floor-4/images/view_02.jpg';
import Floor_4_Image_3 from '../../views/location-2-w/floor-4/images/view_03.jpg';
import Floor_4_Image_4 from '../../views/location-2-w/floor-4/images/view_04.jpg';

import Floor_5_Image_1 from '../../views/location-2-w/floor-5/images/view_01.jpg';
import Floor_5_Image_2 from '../../views/location-2-w/floor-5/images/view_02.jpg';
import Floor_5_Image_3 from '../../views/location-2-w/floor-5/images/view_03.jpg';
import Floor_5_Image_4 from '../../views/location-2-w/floor-5/images/view_04.jpg';
import Floor_5_Image_5 from '../../views/location-2-w/floor-5/images/view_05.jpg';
import Floor_5_Image_6 from '../../views/location-2-w/floor-5/images/view_06.jpg';

import Floor_6_Image_1 from '../../views/location-2-w/floor-6/images/view_01.jpg';
import Floor_6_Image_2 from '../../views/location-2-w/floor-6/images/view_02.jpg';
import Floor_6_Image_3 from '../../views/location-2-w/floor-6/images/view_03.jpg';

const floor1: IFloor = {
	location: 'location-2-w',
	width: 768,
	loop: false,
	direction: 'west',
	view: [
		{
			title: '105',
			order: '05',
			plan: 'B1',
			src: Floor_1_Image_1,
		},
		{
			title: '106',
			order: '06',
			plan: 'B1',
			src: Floor_1_Image_2,
		},
		{
			title: '107',
			order: '07',
			plan: 'B1',
			src: Floor_1_Image_3,
		},
		{
			title: '108',
			order: '08',
			plan: 'A3',
			src: Floor_1_Image_4,
		},
	],
};

const floor2: IFloor = {
	location: 'location-2-w',
	width: 767,
	loop: false,
	direction: 'west',
	view: [
		{
			title: '206',
			order: '06',
			plan: 'B1',
			src: Floor_2_Image_1,
		},
		{
			title: '207',
			order: '07',
			plan: 'B1',
			src: Floor_2_Image_2,
		},
		{
			title: '208',
			order: '08',
			plan: 'B1',
			src: Floor_2_Image_3,
		},
		{
			title: '209',
			order: '09',
			plan: 'A2',
			src: Floor_2_Image_4,
		},
	],
};

const floor3: IFloor = {
	location: 'location-2-w-w',
	width: 767,
	loop: false,
	direction: 'west',
	view: [
		{
			title: '306',
			order: '06',
			plan: 'B1',
			src: Floor_3_Image_1,
		},
		{
			title: '307',
			order: '07',
			plan: 'B1',
			src: Floor_3_Image_2,
		},
		{
			title: '308',
			order: '08',
			plan: 'B1',
			src: Floor_3_Image_3,
		},
		{
			title: '309',
			order: '09',
			plan: 'A2',
			src: Floor_3_Image_4,
		},
	],
};

const floor4: IFloor = {
	location: 'location-2-w-w',
	width: 767,
	loop: false,
	direction: 'west',
	view: [
		{
			title: '406',
			order: '06',
			plan: 'B1',
			src: Floor_4_Image_1,
		},
		{
			title: '407',
			order: '07',
			plan: 'B1',
			src: Floor_4_Image_2,
		},
		{
			title: '408',
			order: '08',
			plan: 'B1',
			src: Floor_4_Image_3,
		},
		{
			title: '409',
			order: '09',
			plan: 'A2',
			src: Floor_4_Image_4,
		},
	],
};

const floor5: IFloor = {
	location: 'location-2-w',
	width: 507,
	loop: false,
	direction: 'west',
	view: [
		{
			title: '605',
			order: '05',
			plan: 'P2',
			src: Floor_5_Image_1,
		},
		{
			title: '606',
			order: '06',
			plan: 'P1',
			src: Floor_5_Image_2,
		},
		{
			title: '506',
			order: '06',
			plan: 'A1',
			src: Floor_5_Image_3,
		},
		{
			title: '607',
			order: '07',
			plan: 'P1',
			src: Floor_5_Image_4,
		},
		{
			title: '507',
			order: '07',
			plan: 'A1',
			src: Floor_5_Image_5,
		},
		{
			title: '508',
			order: '08',
			plan: 'A2',
			src: Floor_5_Image_6,
		},
	],
};

const floor6: IFloor = {
	location: 'location-2-w',
	width: 1144,
	loop: false,
	direction: 'west',
	view: [
		{
			title: '606',
			order: '06',
			plan: 'P1',
			src: Floor_6_Image_1,
		},
		{
			title: '607',
			order: '07',
			plan: 'P1',
			src: Floor_6_Image_2,
		},
		{
			title: '608',
			order: '08',
			plan: 'A2',
			src: Floor_6_Image_3,
		},
	],
};

const location2_W = {
	floor1,
	floor2,
	floor3,
	floor4,
	floor5,
	floor6,
};

export default location2_W;
