import React from 'react';
import {useSelector} from 'react-redux';
import {useAppDispatch} from '../../../../store';
import SCSS from './List.module.scss';
import Floorplans, {IFloorplan} from '../../../../constants/floorplans';
import {
	selectCurrentPlan,
	updateCurrentPlan,
} from '../../../../redux/moduleSlice';

const List = () => {
	const dispatch = useAppDispatch();
	const currentPlan = useSelector(selectCurrentPlan);

	const items = (jr: boolean, bed: string | number) => {
		let title = jr ? 'JR. ' : '';
		title += `${bed} Bedroom`;
		const Plans = Floorplans.filter(
			(p: IFloorplan) => jr === p.jr && bed === p.bed
		);

		const click = (p: string) => {
			dispatch(updateCurrentPlan({plan: p}));
		};

		return (
			<div className={SCSS.Box}>
				<div className={SCSS.Item}>
					<h1>{title}</h1>
					<ul>
						{Plans.map((plan, index) => (
							<li
								key={index}
								className={currentPlan === plan.name ? SCSS.Active : ''}
								onClick={() => click(plan.name)}>
								<h2 className={SCSS.Name}>{plan.name}</h2>
								<div className={SCSS.Desc}>
									<div>
										{plan.bed} Bedroom {plan.den && `+ Den`}
									</div>
									<div>{plan.interior}</div>
								</div>
								<div className={SCSS.Icon} />
							</li>
						))}
					</ul>
				</div>
			</div>
		);
	};

	return (
		<div className={SCSS.List}>
			{items(false, 1)}
			{items(true, 2)}
			{items(false, 2)}
			{items(true, 3)}
			{items(false, 3)}
		</div>
	);
};

export default List;
