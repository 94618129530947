import React from 'react';
import List from './List';
import Keys from './Keys';
import SCSS from './PlanSelector.module.scss';

type Props = {
	openFloorplan: (
		room: string,
		plan: string,
		building: string,
		level: string,
		viewLocation: string
	) => void;
};

const PlanSelector = (props: Props) => {
	const {openFloorplan} = props;
	return (
		<div className={SCSS.PlanSelector}>
			<div className={SCSS.Left}>
				<List />
			</div>
			<div className={SCSS.Right}>
				<Keys openFloorplan={openFloorplan} />
			</div>
		</div>
	);
};

export default PlanSelector;
