import React from 'react';
import {useSelector} from 'react-redux';
import Slideshow from './Slideshow';
import SCSS from './Views.module.scss';
import {
	selectCurrentBuilding,
	selectCurrentLevel,
} from '../../../redux/moduleSlice';

type Props = {
	gotoFloorplan: () => void;
};

const Views = (props: Props) => {
	const {gotoFloorplan} = props;
	const building = useSelector(selectCurrentBuilding);
	const level = useSelector(selectCurrentLevel);
	return (
		<div className={SCSS.Views}>
			<Slideshow />
			<div className={SCSS.Footer}>
				<div className={SCSS.ButtonContainer}>
					<div className={SCSS.BackButton} onClick={gotoFloorplan}>
						Back to
						<br />
						Floorplan
					</div>
				</div>
				<div className={SCSS.DisclaimerContainer} />
				<div className={SCSS.LabelContainer}>
					<div className={SCSS.Label}>
						<strong>{building}</strong> <span>Level {level}</span>
					</div>
					<div className={SCSS.Desclaimer}>
						Views shown are approximate and
						<br />
						actual views from each suite may vary.
					</div>
				</div>
			</div>
		</div>
	);
};

export default Views;
