import React from 'react';
import Pool from './Pool';
import BuildingLabel from './BuildingLabel';
import LakeLabel from './LakeLabel';
import SCSS from './Keys.module.scss';

type Props = {
	highlight: (plan: string) => string;
	openFloorplan: (
		room: string,
		plan: string,
		building: string,
		level: string,
		viewLocation: string
	) => void;
};

const Level3 = (props: Props) => {
	const {highlight, openFloorplan} = props;
	return (
		<div className={SCSS.Keyplate}>
			<svg viewBox='0 0 750 400'>
				<Pool />
				<g id='LINEWORKS' className={SCSS.Lineworks}>
					<g>
						<polyline points='108.16,64.92 142.33,64.92 142.33,81.61 		' />
						<polyline points='141.39,64.92 141.39,54.68 172.73,54.68 172.73,74.37 		' />
						<line x1='155.88' y1='74.37' x2='155.88' y2='54.68' />
						<line x1='153.67' y1='245.1' x2='153.67' y2='262.11' />
						<polyline
							points='210.4,304.95 221.5,304.92 221.13,352.49 148.2,349.91 75.15,352.84 74.52,297.72 85.63,297.69
			'
						/>
						<line x1='133.95' y1='64.92' x2='133.95' y2='81.61' />
						<line x1='132.06' y1='64.92' x2='132.06' y2='81.61' />
						<line x1='130.17' y1='64.92' x2='130.17' y2='81.61' />
						<line x1='128.28' y1='64.92' x2='128.28' y2='81.61' />
						<line x1='126.39' y1='64.92' x2='126.39' y2='81.61' />
						<line x1='124.5' y1='64.92' x2='124.5' y2='81.61' />
						<line x1='122.61' y1='64.92' x2='122.61' y2='81.61' />
						<line x1='120.72' y1='64.92' x2='120.72' y2='81.61' />
						<line x1='118.83' y1='64.92' x2='118.83' y2='81.61' />
						<line x1='116.94' y1='64.92' x2='116.94' y2='81.61' />
						<line x1='133.95' y1='73.27' x2='116.94' y2='73.27' />
						<line x1='180.34' y1='245.1' x2='180.34' y2='262.11' />
						<line x1='178.45' y1='245.1' x2='178.45' y2='262.11' />
						<line x1='176.56' y1='245.1' x2='176.56' y2='262.11' />
						<line x1='174.67' y1='245.1' x2='174.67' y2='262.11' />
						<line x1='172.78' y1='245.1' x2='172.78' y2='262.11' />
						<line x1='170.89' y1='245.1' x2='170.89' y2='262.11' />
						<line x1='169' y1='245.1' x2='169' y2='262.11' />
						<line x1='167.11' y1='245.1' x2='167.11' y2='262.11' />
						<line x1='165.22' y1='245.1' x2='165.22' y2='262.11' />
						<line x1='163.33' y1='245.1' x2='163.33' y2='262.11' />
						<line x1='180.34' y1='253.6' x2='163.33' y2='253.6' />
						<rect x='69.88' y='126.97' width='15.75' height='68.99' />
						<polygon points='85.63,126.97 85.63,64.92 120.72,64.92 120.72,50.35 66.13,50.35 69.88,126.97 		' />
						<polygon points='228,54.68 226.12,143.67 210.37,143.67 210.37,74.37 172.73,74.37 172.73,54.68 		' />
						<polygon points='228,268.26 226.12,200.37 210.37,200.37 210.37,268.26 		' />
						<polygon points='85.64,195.96 85.63,274.23 66.13,274.23 69.88,195.96 		' />
						<rect x='210.37' y='143.67' width='15.75' height='56.7' />
					</g>
					<g>
						<polyline points='577.92,84.92 594.3,84.92 594.3,104.61 		' />
						<line x1='594.3' y1='119.41' x2='577.92' y2='119.41' />
						<rect x='347.62' y='187.45' width='82.24' height='19.5' />
						<rect x='429.87' y='187.45' width='82.24' height='19.5' />
						<rect x='512.08' y='187.45' width='82.24' height='19.5' />
						<rect x='420.99' y='46.96' width='74.74' height='15.75' />
						<polygon points='495.73,46.96 590.26,46.96 590.26,84.92 577.92,84.92 577.92,62.71 495.73,62.71 		' />
						<rect x='344.56' y='46.96' width='54.9' height='15.75' />
						<polyline points='306.45,100.36 286.6,100.36 286.6,117.21 306.45,117.21 		' />
						<polyline points='286.6,117.21 281.88,117.21 281.88,100.36 286.6,100.36 		' />
						<line x1='285.66' y1='164.93' x2='285.66' y2='117.21' />
						<line x1='285.66' y1='130.75' x2='302.35' y2='130.75' />
						<rect x='281.88' y='187.45' width='65.84' height='19.5' />
						<polyline points='344.56,62.71 344.56,46.96 286.6,46.96 286.6,100.36 306.45,100.36 		' />
						<line x1='285.66' y1='138.89' x2='302.35' y2='138.89' />
						<line x1='285.66' y1='140.78' x2='302.35' y2='140.78' />
						<line x1='285.66' y1='142.67' x2='302.35' y2='142.67' />
						<line x1='285.66' y1='144.56' x2='302.35' y2='144.56' />
						<line x1='285.66' y1='146.45' x2='302.35' y2='146.45' />
						<line x1='285.66' y1='148.34' x2='302.35' y2='148.34' />
						<line x1='285.66' y1='150.23' x2='302.35' y2='150.23' />
						<line x1='285.66' y1='152.12' x2='302.35' y2='152.12' />
						<line x1='285.66' y1='154.01' x2='302.35' y2='154.01' />
						<line x1='285.66' y1='155.9' x2='302.35' y2='155.9' />
						<line x1='294.01' y1='138.89' x2='294.01' y2='155.9' />
						<line x1='577.98' y1='94.33' x2='594.36' y2='94.33' />
						<line x1='577.98' y1='96.22' x2='594.36' y2='96.22' />
						<line x1='577.98' y1='98.11' x2='594.36' y2='98.11' />
						<line x1='577.98' y1='100' x2='594.36' y2='100' />
						<line x1='577.98' y1='101.89' x2='594.36' y2='101.89' />
						<line x1='577.98' y1='103.78' x2='594.36' y2='103.78' />
						<line x1='577.98' y1='105.67' x2='594.36' y2='105.67' />
						<line x1='577.98' y1='107.56' x2='594.36' y2='107.56' />
						<line x1='577.98' y1='109.45' x2='594.36' y2='109.45' />
						<line x1='577.98' y1='111.34' x2='594.36' y2='111.34' />
						<line x1='586.17' y1='94.33' x2='586.17' y2='111.34' />
						<polygon
							points='678.14,93.36 678.14,198.7 617.9,198.7 617.9,187.45 650.68,187.45 650.68,104.61 617.9,104.61
			617.9,93.36 		'
						/>
						<rect x='281.88' y='170.6' width='3.78' height='16.85' />
					</g>
				</g>
				<g id='UNITS'>
					<g id='LEVEL-3-LABEL'>
						<text
							transform='matrix(1 0 0 1 85.4619 393.0516)'
							className={SCSS.LevelLabel}>
							LEVEL 3
						</text>
					</g>
					<BuildingLabel />
					<g id='LEVEL-3-ZARA-2_2_'>
						<g
							id='UINIT-301-A_2_'
							onClick={() =>
								openFloorplan('301', 'A', 'ZARA 2', '3', 'location-2-e')
							}
							className={highlight('A')}>
							<g id='LWPOLYLINE_71_'>
								<rect
									x='306.45'
									y='62.71'
									className={SCSS.Hot}
									width='38.06'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 314.0312 88.4907)'
									className={SCSS.Number}>
									301
								</text>
								<text
									transform='matrix(1 0 0 1 319.3052 107.1339)'
									className={SCSS.PlanName}>
									A
								</text>
							</g>
						</g>
						<g
							id='UINIT-302-B2_2_'
							onClick={() =>
								openFloorplan('302', 'B2', 'ZARA 2', '3', 'location-2-e')
							}
							className={highlight('B2')}>
							<g id='LWPOLYLINE_70_'>
								<rect
									x='344.5'
									y='62.71'
									className={SCSS.Hot}
									width='68.99'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 366.062 88.4907)'
									className={SCSS.Number}>
									302
								</text>
								<text
									transform='matrix(1 0 0 1 367.9497 107.1339)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							id='UINIT-303-B1_2_'
							onClick={() =>
								openFloorplan('303', 'B1', 'ZARA 2', '3', 'location-2-e')
							}
							className={highlight('B1')}>
							<g id='LWPOLYLINE_69_'>
								<rect
									x='413.49'
									y='62.71'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 441.5906 88.4907)'
									className={SCSS.Number}>
									303
								</text>
								<text
									transform='matrix(1 0 0 1 445.2483 107.1339)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							id='UINIT-304-B1_2_'
							onClick={() =>
								openFloorplan('304', 'B1', 'ZARA 2', '3', 'location-2-e')
							}
							className={highlight('B1')}>
							<g id='LWPOLYLINE_68_'>
								<rect
									x='495.7'
									y='62.71'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 523.8269 88.4907)'
									className={SCSS.Number}>
									304
								</text>
								<text
									transform='matrix(1 0 0 1 527.4636 107.1339)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							id='UINIT-305-B1_4_'
							onClick={() =>
								openFloorplan('305', 'B1', 'ZARA 2', '3', 'location-1')
							}
							className={highlight('B1')}>
							<g id='LWPOLYLINE_67_'>
								<rect
									x='594.3'
									y='104.61'
									className={SCSS.Hot}
									width='56.38'
									height='82.85'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 609.4231 141.5314)'
									className={SCSS.Number}>
									305
								</text>
								<text
									transform='matrix(1 0 0 1 613.1438 160.1746)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							id='UINIT-306-B1_2_'
							onClick={() =>
								openFloorplan('306', 'B1', 'ZARA 2', '3', 'location-2-w')
							}
							className={highlight('B1')}>
							<g id='LWPOLYLINE_66_'>
								<rect
									x='512.08'
									y='130.75'
									className={SCSS.Hot}
									width='82.22'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 540.0605 154.3165)'
									className={SCSS.Number}>
									306
								</text>
								<text
									transform='matrix(1 0 0 1 543.8584 172.9597)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							id='UINIT-307-B1_2_'
							onClick={() =>
								openFloorplan('307', 'B1', 'ZARA 2', '3', 'location-2-w')
							}
							className={highlight('B1')}>
							<g id='LWPOLYLINE_65_'>
								<rect
									x='429.87'
									y='130.75'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 458.3213 154.3165)'
									className={SCSS.Number}>
									307
								</text>
								<text
									transform='matrix(1 0 0 1 461.6431 172.9597)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							id='UINIT-308-B1_2_'
							onClick={() =>
								openFloorplan('308', 'B1', 'ZARA 2', '3', 'location-2-w')
							}
							className={highlight('B1')}>
							<g id='LWPOLYLINE_64_'>
								<rect
									x='347.65'
									y='130.75'
									className={SCSS.Hot}
									width='82.21'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 377.3679 154.3165)'
									className={SCSS.Number}>
									308
								</text>
								<text
									transform='matrix(1 0 0 1 380.9558 172.9597)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							id='UINIT-309-A2_2_'
							onClick={() =>
								openFloorplan('309', 'A2', 'ZARA 2', '3', 'location-2-w')
							}
							className={highlight('A2')}>
							<g id='LWPOLYLINE_63_'>
								<polygon
									className={SCSS.Hot}
									points='347.71,187.45 285.66,187.45 285.66,164.93 302.35,164.93 302.35,130.75 347.65,130.75 				'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 311.8574 154.3165)'
									className={SCSS.Number}>
									309
								</text>
								<text
									transform='matrix(1 0 0 1 313.5586 172.9597)'
									className={SCSS.PlanName}>
									A2
								</text>
							</g>
						</g>
					</g>
					<g id='LEVEL-3-ZARA-1_2_'>
						<g
							id='UINIT-301-B2_2_'
							onClick={() =>
								openFloorplan('301', 'B2', 'ZARA 1', '3', 'location-3-e')
							}
							className={highlight('B2')}>
							<g id='LWPOLYLINE_62_'>
								<rect
									x='153.67'
									y='74.37'
									className={SCSS.Hot}
									width='56.7'
									height='69.3'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 170.5786 103.584)'
									className={SCSS.Number}>
									301
								</text>
								<text
									transform='matrix(1 0 0 1 170.9473 122.2271)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							id='UINIT-302-A4_2_'
							onClick={() =>
								openFloorplan('302', 'A4', 'ZARA 1', '3', 'location-3-s')
							}
							className={highlight('A4')}>
							<g id='LWPOLYLINE_61_'>
								<rect
									x='153.67'
									y='143.67'
									className={SCSS.Hot}
									width='56.7'
									height='56.7'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 169.0596 166.5633)'
									className={SCSS.Number}>
									302
								</text>
								<text
									transform='matrix(1 0 0 1 170.3267 185.2064)'
									className={SCSS.PlanName}>
									A4
								</text>
							</g>
						</g>
						<g
							id='UINIT-303-A2_2_'
							onClick={() =>
								openFloorplan('303', 'A2', 'ZARA 1', '3', 'location-3-s')
							}
							className={highlight('A2')}>
							<g id='LWPOLYLINE_60_'>
								<polygon
									className={SCSS.Hot}
									points='153.67,200.37 153.67,245.1 188.48,245.1 188.48,262.11 210.37,262.11 210.37,200.37 				'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 169.0176 217.8294)'
									className={SCSS.Number}>
									303
								</text>
								<text
									transform='matrix(1 0 0 1 170.5786 236.4726)'
									className={SCSS.PlanName}>
									A2
								</text>
							</g>
						</g>
						<g
							id='UINIT-304-C1_2_'
							onClick={() =>
								openFloorplan('304', 'C1', 'ZARA 1', '3', 'location-4')
							}
							className={highlight('C1')}>
							<g id='LWPOLYLINE_59_'>
								<polygon
									className={SCSS.Hot}
									points='81.85,278.17 81.85,295.58 85.63,295.58 85.67,327.31 210.37,327.31 210.37,262.11 142.33,262.11
					142.33,278.17 				'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 133.1282 298.396)'
									className={SCSS.Number}>
									304
								</text>
								<text
									transform='matrix(1 0 0 1 135.802 317.0392)'
									className={SCSS.PlanName}>
									C1
								</text>
							</g>
						</g>
						<g
							id='UINIT-305-B1_1_'
							onClick={() =>
								openFloorplan('305', 'B1', 'ZARA 1', '3', 'location-3-n')
							}
							className={highlight('B1')}>
							<g id='LWPOLYLINE_58_'>
								<rect
									x='85.63'
									y='195.96'
									className={SCSS.Hot}
									width='56.7'
									height='82.21'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 100.9148 233.169)'
									className={SCSS.Number}>
									305
								</text>
								<text
									transform='matrix(1 0 0 1 104.6355 251.8121)'
									className={SCSS.PlanName}>
									B1
								</text>
							</g>
						</g>
						<g
							id='UINIT-306-B2_2_'
							onClick={() =>
								openFloorplan('306', 'B2', 'ZARA 1', '3', 'location-3-n')
							}
							className={highlight('B2')}>
							<g id='LWPOLYLINE_57_'>
								<rect
									x='85.63'
									y='126.97'
									className={SCSS.Hot}
									width='56.7'
									height='68.98'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 100.8376 156.8453)'
									className={SCSS.Number}>
									306
								</text>
								<text
									transform='matrix(1 0 0 1 102.9075 175.4884)'
									className={SCSS.PlanName}>
									B2
								</text>
							</g>
						</g>
						<g
							id='UINIT-307-A2_2_'
							onClick={() =>
								openFloorplan('307', 'A2', 'ZARA 1', '3', 'location-3-e')
							}
							className={highlight('A2')}>
							<g id='LWPOLYLINE_56_'>
								<polygon
									className={SCSS.Hot}
									points='142.33,126.97 142.33,81.61 108.16,81.61 108.16,64.92 85.63,64.92 85.63,126.97 				'
								/>
							</g>
							<g>
								<text
									transform='matrix(1 0 0 1 101.3137 99.8814)'
									className={SCSS.Number}>
									307
								</text>
								<text
									transform='matrix(1 0 0 1 102.5388 118.5246)'
									className={SCSS.PlanName}>
									A2
								</text>
							</g>
						</g>
					</g>
				</g>
				<LakeLabel />
			</svg>
		</div>
	);
};

export default Level3;
