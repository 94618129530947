import React from 'react';
import SCSS from './Keys.module.scss';

const LakeLabel = () => {
	return (
		<g className={SCSS.LakeLabel}>
			<g>
				<g>
					<text
						transform='matrix(1 0 0 1 16.4084 197.8189)'
						className={SCSS.LakeText}>
						LAKE
					</text>
					<g className={SCSS.LakeCompass}>
						<polygon points='8.33,196.73 12.03,204.4 19.7,208.1 6.61,209.82 				' />
						<path
							d='M8.61,198.46l2.9,6l0.15,0.31l0.31,0.15l6,2.9l-10.78,1.41L8.61,198.46 M8.05,195.01L6.03,210.4
					l15.39-2.02l-9.02-4.35L8.05,195.01L8.05,195.01z'
						/>
					</g>
				</g>
				<g>
					<text
						transform='matrix(1 0 0 1 500.0813 356.0328)'
						className={SCSS.LakeText}>
						LAKE
					</text>
					<g className={SCSS.LakeCompass}>
						<polygon points='516.03,364.61 524.07,367.41 532.11,364.61 524.07,375.07 				' />
						<path
							d='M530.69,365.63l-6.62,8.62l-6.62-8.62l6.29,2.2l0.33,0.11l0.33-0.11L530.69,365.63 M533.53,363.58
					l-9.46,3.3l-9.46-3.3l9.46,12.31L533.53,363.58L533.53,363.58z'
						/>
					</g>
				</g>
				<g>
					<text
						transform='matrix(1 0 0 1 678.4954 271.44)'
						className={SCSS.LakeText}>
						LAKE
					</text>
					<g className={SCSS.LakeCompass}>
						<polygon points='736.69,264.55 733.88,256.51 744.35,264.55 733.88,272.59 				' />
						<path
							d='M734.91,257.93l8.62,6.62l-8.62,6.62l2.2-6.29l0.11-0.33l-0.11-0.33L734.91,257.93 M732.86,255.1l3.3,9.46
					l-3.3,9.46l12.31-9.46L732.86,255.1L732.86,255.1z'
						/>
					</g>
				</g>
			</g>
			<g className={SCSS.FirePit}>
				<rect x='500.01' y='267.15' width='150.51' height='36.6' />
				<text
					transform='matrix(1 0 0 1 516.2278 290.8099)'
					className='st20 st8 st21'>
					FIREPIT AREA
				</text>
			</g>
		</g>
	);
};

export default LakeLabel;
