import IFloor from './IFloor';

import Floor_2_Image_1 from '../../views/location-4/floor-2/images/view_01.jpg';
import Floor_2_Image_2 from '../../views/location-4/floor-2/images/view_02.jpg';
import Floor_2_Image_3 from '../../views/location-4/floor-2/images/view_03.jpg';

import Floor_3_Image_1 from '../../views/location-4/floor-3/images/view_01.jpg';
import Floor_3_Image_2 from '../../views/location-4/floor-3/images/view_02.jpg';
import Floor_3_Image_3 from '../../views/location-4/floor-3/images/view_03.jpg';

import Floor_4_Image_1 from '../../views/location-4/floor-4/images/view_01.jpg';
import Floor_4_Image_2 from '../../views/location-4/floor-4/images/view_02.jpg';
import Floor_4_Image_3 from '../../views/location-4/floor-4/images/view_03.jpg';

import Floor_5_Image_1 from '../../views/location-4/floor-5/images/view_01.jpg';
import Floor_5_Image_2 from '../../views/location-4/floor-5/images/view_02.jpg';
import Floor_5_Image_3 from '../../views/location-4/floor-5/images/view_03.jpg';

import Floor_6_Image_1 from '../../views/location-4/floor-6/images/view_01.jpg';
import Floor_6_Image_2 from '../../views/location-4/floor-6/images/view_02.jpg';
import Floor_6_Image_3 from '../../views/location-4/floor-6/images/view_03.jpg';

const floor2: IFloor = {
	location: 'location-4',
	width: 1383,
	loop: false,
	direction: 'west',
	view: [
		{
			title: '',
			order: '',
			plan: '',
			src: Floor_2_Image_1,
		},
		{
			title: '204',
			order: '04',
			plan: 'C1',
			src: Floor_2_Image_2,
		},
		{
			title: '',
			order: '',
			plan: '',
			src: Floor_2_Image_3,
		},
	],
};

const floor3: IFloor = {
	location: 'location-4',
	width: 1383,
	loop: false,
	direction: 'west',
	view: [
		{
			title: '',
			order: '',
			plan: '',
			src: Floor_3_Image_1,
		},
		{
			title: '304',
			order: '04',
			plan: 'C1',
			src: Floor_3_Image_2,
		},
		{
			title: '',
			order: '',
			plan: '',
			src: Floor_3_Image_3,
		},
	],
};

const floor4: IFloor = {
	location: 'location-4',
	width: 1383,
	loop: false,
	direction: 'west',
	view: [
		{
			title: '',
			order: '',
			plan: '',
			src: Floor_4_Image_1,
		},
		{
			title: '404',
			order: '04',
			plan: 'C1',
			src: Floor_4_Image_2,
		},
		{
			title: '',
			order: '',
			plan: '',
			src: Floor_4_Image_3,
		},
	],
};

const floor5: IFloor = {
	location: 'location-4',
	width: 1383,
	loop: false,
	direction: 'west',
	view: [
		{
			title: '',
			order: '',
			plan: '',
			src: Floor_5_Image_1,
		},
		{
			title: '504',
			order: '04',
			plan: 'C1',
			src: Floor_5_Image_2,
		},
		{
			title: '',
			order: '',
			plan: '',
			src: Floor_5_Image_3,
		},
	],
};

const floor6: IFloor = {
	location: 'location-4',
	width: 1383,
	loop: false,
	direction: 'west',
	view: [
		{
			title: '',
			order: '',
			plan: '',
			src: Floor_6_Image_1,
		},
		{
			title: '604',
			order: '04',
			plan: 'C1',
			src: Floor_6_Image_2,
		},
		{
			title: '',
			order: '',
			plan: '',
			src: Floor_6_Image_3,
		},
	],
};

const location4 = {
	floor2,
	floor3,
	floor4,
	floor5,
	floor6,
};

export default location4;
